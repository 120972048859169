import React, { useState, useCallback } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import Alert from '@mui/material/Alert';

import { ButtonAdd, IconAdd } from './DocumentStyle';

import SaveIcon from '@mui/icons-material/Save';
import FlexFooter from 'components/UI/FlexFooter';
import ProgressButton from 'components/UI/LoadingButtonModal';

import ModalInfosDocument from './ModalInfosDocument/ModalInfosDocument';
import ModalEditInfosDocument from './ModalInfosDocument/ModalEditInfosDocument';
import Header from 'components/UI/Header/Header';
import TableDocument from './TableDocument';

const Document = ({ filesDocument, addDocument, modalClosed }) => {
    const [files, setFiles] = useState(filesDocument);
    const [waitFiles, setWaitFiles] = useState([]);
    const [dataFiles, setDataFiles] = useState(filesDocument);
    const [openDataFile, setOpenDataFile] = useState(false);
    const [openEditDataFile, setOpenEditDataFile] = useState(false);
    const [editFile, setEditFile] = useState([]);
    const [alert, setAlert] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [loading, setLoading] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        let sizeOk = true;
        acceptedFiles.forEach(file => {
            if (file.size > 6000000) {
                sizeOk = false;
            }
        });
        if (sizeOk) {
            const newWaitFiles = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setWaitFiles(prevWaitFiles => [...prevWaitFiles, ...newWaitFiles]);
            setOpenDataFile(true);
            setAlert(null);
            setHasChanges(true);
        } else {
            setAlert(
                <Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: 'fit-content' }} severity="warning">
                    Limite de taille de fichier atteinte! Taille maximale 5Mo. Aucun des fichiers n'a été ajouté.
                </Alert>
            );
        }
    }, []);

    const handleAddDocument = useCallback(() => {
        addDocument(files);
        modalClosed();
        setHasChanges(false);
    }, [addDocument, files, modalClosed]);

    const removeFile = useCallback((_, file) => {
        const filteredFiles = files.filter(item => item !== file);
        setFiles(filteredFiles);
        setDataFiles(filteredFiles);
        setHasChanges(true);
    }, [files]);

    const saveOrCancel = useCallback((action, data) => {
        if (action === "save") {
            setFiles(prevFiles => [...prevFiles, ...data]);
            setWaitFiles([]);
            setDataFiles(prevDataFiles => [...prevDataFiles, ...data]);
            setOpenDataFile(false);
            setHasChanges(true);
        } else if (action === "cancel") {
            setWaitFiles([]);
            setOpenDataFile(false);
        }
    }, []);

    const editFileHandler = useCallback((_, file) => {
        setEditFile([file]);
        setOpenEditDataFile(true);
    }, []);

    const saveOrCancelEditFile = useCallback((action, newFile, oldFile) => {
        if (action === "save") {
            const index = files.indexOf(oldFile);
            const updatedFiles = [...files];
            updatedFiles[index] = newFile[0];
            setFiles(updatedFiles);
            setEditFile([]);
            setDataFiles(updatedFiles);
            setOpenEditDataFile(false);
            setHasChanges(true);
        } else if (action === "cancel") {
            setEditFile([]);
            setOpenEditDataFile(false);
        }
    }, [files]);

    const b64toBlob = useCallback((b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: `${contentType};base64` });
        return blob;
    }, []);

    const openBase64InNewTab = useCallback((data, mimeType) => {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], { type: mimeType });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    }, []);

    const showDocument = useCallback((event, row) => {
        let blob;
        if (row.name.includes(".pdf") || row.name.includes(".PDF")) {
            blob = b64toBlob(row.file, "application/pdf");
        } else if (row.name.includes(".csv")) {
            blob = b64toBlob(row.file, "text/csv");
        } else if (row.name.includes(".xlsx") || row.name.includes(".xls")) {
            blob = b64toBlob(row.file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        } else if (row.name.includes(".docx")) {
            blob = b64toBlob(row.file, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
        } else {
            blob = b64toBlob(row.file, 'image/png;base64');
        }
        const url = URL.createObjectURL(blob);
        window.open(url);
    }, [b64toBlob]);

    return (
        <Box sx={{ width: { md: "70vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
            <Header>Documents</Header>
            <Stack sx={{ px: 3, maxHeight: "550px", overflow: 'auto' }} spacing={1}>
                {openDataFile && (
                    <ModalInfosDocument
                        openDataFile={openDataFile}
                        dataFiles={waitFiles}
                        saveOrCancel={saveOrCancel}
                    />
                )}
                {openEditDataFile && (
                    <ModalEditInfosDocument
                        openDataFile={openEditDataFile}
                        dataFiles={editFile}
                        saveOrCancel={saveOrCancelEditFile}
                        type="Photos"
                    />
                )}
                <Dropzone
                    accept=".csv, .xlsx, .xls, .docx, image/*, application/pdf"
                    onDrop={onDrop}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section className="container" style={{ paddingBottom: "10px" }}>
                            <div style={{ width: "fit-content" }} {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <ButtonAdd variant="outlined" size='small' startIcon={<IconAdd />}>
                                    Ajouter document(s)
                                </ButtonAdd>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {dataFiles.length > 0 ? (
                        <Stack style={{ display: "flex", flexWrap: "wrap" }}>
                            {alert}
                            <TableDocument
                                dataFiles={dataFiles}
                                editFile={editFileHandler}
                                showDocument={showDocument}
                                removeFile={removeFile}
                            />
                        </Stack>
                    ) : (
                        <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
                            Aucun document
                        </Typography>
                    )}
                </Stack>
            </Stack>
            <FlexFooter custompadding={3}>
                <ProgressButton
                    icon={<SaveIcon />}
                    name="Sauvegarder"
                    clickHandler={handleAddDocument}
                    update={hasChanges}
                    loading={loading}
                />
            </FlexFooter>
        </Box>
    );
};

export default Document;

 
