// constants.js

// new logout root
export const LOGOUT = () => `/auth/logout`;

// Intervention
export const GET_REQUEST_INTERVENTION_CLIENT = (attachedPilote) => '/legacy/getDemandeInterventionClient?attachedPilote=' + attachedPilote;
export const GET_NB_REQUEST_INTERVENTION = (attachedPilote) => '/legacy/getNbDemandeInterventionClient?attachedPilote=' + attachedPilote;
export const GET_DATA_FOR_FICHE_INTERVENTION = (IDMastore) => '/unsecured/legacy/getDataForFicheIntervention?id_mastore=' + IDMastore;
export const GET_REQUEST_INTERVENTION_CLIENT_IN_CORBEILLE = (attachedPilote) => '/legacy/getDemandeInterventionInCorbeille?attachedPilote=' + attachedPilote;
export const POST_INTERVENTION_CLIENT = () => '/legacy/postDemandeInterventionClient';
export const PUT_REQUEST_INTERVENTION = () => '/legacy/putDemandeIntervention';
export const POST_INTERVENTION = () => '/legacy/intervention';
export const GET_SAV_INTERVENTION = (year) => '/legacy/interventions/sav?year=' + year;
export const GET_COUNT_INTERVENTION = (year) => '/legacy/statistics/count_interventions?year=' + year;

//
export const GET_LINK_FICHE_INTERVENTION = (IDMastore) => '/unsecured/legacy/ficheInterventionLink?id_mastore=' + IDMastore
//Intervenant
export const GET_INTERVENANT = () => '/legacy/GetIntervenants'

//user
export const GET_PILOTE = () => '/legacy/pilote'
export const GET_USERS = () => '/legacy/allUser'
export const GET_USERS_ACTIVE = (entreprise) => '/legacy/allUserActive?entreprise=' + entreprise
export const POST_USER = () => '/legacy/user'
export const PUT_USER = () => '/legacy/user'
export const GET_USER_ENTREPRISE_BY_ID = (id) => '/legacy/getUserEntreprise?id=' + id
export const PUT_USER_IS_ACTIVE = () => '/legacy/changeUserState'

//Relance / Mention
export const GET_RELANCE_BY_USER = () => '/legacy/getMentionsByUser'

//parameters
export const GET_SPECIALITE = () => '/legacy/parametre?attribut=Spécialité'
export const SAVE_PARAMETRES = () => '/legacy/saveParametres'
export const DELETE_PARAMETRES = (parametres) => '/legacy/parametres?header=' + parametres
export const GET_ZONE = () => '/legacy/zoneName'
export const GET_ALL_ADRESSE_FACTURATION = () => '/legacy/allAdresseDeFacturation'
export const GET_ADRESSE_FACTURATION_BY_LOCATION = (location) => '/legacy/adresseDeFacturation/' + encodeURIComponent(location)

//site
export const GET_ALL_ENSEIGNE = () => '/legacy/allEnseigne'
export const GET_MAGASIN_BY_ENSEIGNE = (enseigne) => '/legacy/siteByEnseigne?enseigne=' + encodeURIComponent(enseigne);
export const GET_CONTACT_BY_CLIENT = (client) => '/legacy/contact?client=' + client;
export const GET_SITE_WITH_PREVENTIVE = (year, enseigne) => '/legacy/sitesWithPreventive?year=' + year + '&enseigne=' + enseigne;
export const GET_SITE_WITH_PREVENTIVE_SPECIALITE = (year, enseigne, specialite) => '/legacy/sitesWithPreventive?year=' + year + '&enseigne=' + enseigne + '&specialite=' + specialite;
export const GET_PREVENTIVE_INTERVENTIONS = (year, enseigne, specialite) => 'legacy/interventions/tableau/preventives?year=' + year + '&enseigne=' + enseigne + '&specialite=' + specialite;
export const GET_PREVENTIVE_INTERVENTIONS_HISTORIQUE = (year, numero_magasin, mois) => '/legacy/interventions/preventives/historique?year=' + year + '&numero_magasin=' + numero_magasin + '&mois=' + mois;
export const GET_ENSEIGNE_WITH_PREVENTIVE = (year) => '/legacy/enseigneWithPreventive?year=' + year;
export const GET_SITE_BY_CLIENT = (client) => '/legacy/siteByClient?client=' + client;

//client
export const GET_ALL_CLIENT = () => '/legacy/allClient'
export const GET_ENSEIGNES_BY_PILOTE = (userID, year) => 
    `/legacy/getEnseignesByPilote?userID=${userID}&year=${year}`;

export const GET_CLIENTS_BY_PILOTE = (userID, year) => 
    `/legacy/getClientsByPilote?userID=${userID}&year=${year}`;

//task
export const GET_TASK_BY_USER = () => '/legacy/getTaskByUser'
export const POST_TASK = () => '/legacy/postTask'
export const DELETE_TASK = (id) => `/legacy/deleteTask?id=${id}`

//file
export const GET_FILE_BY_ID = (file_id) => '/legacy/getFile?file_id=' + file_id

//Message
export const POST_MESSAGE = () => `/legacy/sendMessage`
export const GET_MESSAGE_BY_ID_MASTORE = (id_mastore) => `/legacy/getMessage?id_mastore=` + id_mastore;
export const GET_ALL_LAST_MESSAGE_BY_PILOTE = (pilote) => `/legacy/getLastMessageByPilote?pilote=` + pilote;
export const GET_ALL_MESSAGES = (pilote) => `/legacy/getAllMessagesByPilote?pilote=` + pilote;
export const GET_ALL_LAST_MESSAGE_BY_CLIENT = (pilote) => `/legacy/getLastMessageByClient?pilote=` + pilote;
export const PUT_MESSAGE_READ_BY_ID = () => `/legacy/messageRead`
export const PUT_MESSAGE_ANSWERED_BY_ID = () => `/legacy/messageAnswered`
export const GET_LAST_MESSAGE_BY_ID_MASTORE = (id_mastore) => `/legacy/getLastMessage?id_mastore=` + id_mastore;
export const PUT_CLOSED_DISCUSSION = (id_mastore, pilote) => `/legacy/closedDiscussion?id_mastore=` + id_mastore + `&pilote=` + pilote;
export const PUT_REOPEN_DISCUSSION = (id_mastore, pilote) => `/legacy/reOpenDiscussion?id_mastore=` + id_mastore + `&pilote=` + pilote;

//Statistique
export const GET_STATS_CLIENTS = (year, client) => `/legacy/statistics/clients?year=` + year + `&client=` + client;;
export const GET_COUT_REEL_SAV = (year) => `/legacy/statistics/sav/real?year=` + year;
export const GET_COUT_FORECAST_SAV = (year) => `/legacy/statistics/sav/forecast?year=` + year;
export const GET_REVENUES_PILOTES_BY_YEAR = (year) => `legacy/revenuesPilotes?year=` + year;
export const GET_GROUPED_PILOTE = () => `legacy/pilote/grouped`;
export const GET_GROUPED_INTERVENTION = () =>`/legacy/nbrinterventionbygroups`;
export const GET_NBR_INTERVENTION_BY_SPECIALITE = (params) => `/legacy/nbrInterventionBySpecialite?` + params;
export const GET_REVENUES_CLIENTS_BY_SPECIALITE = (params) => `/legacy/revenuesClientsSpecialite?` + params;
export const GET_TOP_CLIENTS = (year, type = [], userID) => {
    const params = new URLSearchParams();

    // 🔹 Vérification et ajout des années
    if (Array.isArray(year)) {
        year.forEach(y => params.append("year", y.toString()));
    } else {
        params.append("year", year.toString());
    }

    // 🔹 Vérification et ajout du type si présent
    if (Array.isArray(type) && type.length > 0) {
        params.append("type", type.join(","));
    } else if (typeof type === "string" && type.trim() !== "") {
        params.append("type", type);
    }

    // 🔹 Ajout correct de `userID`
    if (userID) {
        params.append("userID", userID.toString());
    }

    return `/legacy/statistics/top/clients?${params.toString()}`;
};

export const GET_TOP10_INTERVENANTS = (years, specialite, userID) => {
    const params = new URLSearchParams();

    // 🔹 Ajouter chaque année séparément pour éviter `?year=2022,2023`
    if (Array.isArray(years)) {
        years.forEach(year => params.append("year", year.toString()));
    } else {
        params.append("year", years.toString());
    }

    if (specialite) {
        params.append("specialite", specialite);
    }

    if (userID) {
        params.append("userID", userID.toString());
    }

    return `/legacy/statistics/top10/intervenants?${params.toString()}`;
};

// Fonction générique pour générer les URLs des Statistics
const createStatistiqueURL = (path, client, date, enseigne, type) =>
    `/legacy/statistics/${path}?client=${encodeURIComponent(client)}&year=${encodeURIComponent(date)}&enseigne=${encodeURIComponent(enseigne)}&type=${encodeURIComponent(type)} `;

// Statistique
export const GET_STATISTIQUE_REVENUS_PER_SPECIALITY = (
    client,
    date,
    enseigne,
    type,
) => createStatistiqueURL("perSpecialty", client, date, enseigne, type);
export const GET_STATISTIQUE_SPECIALITY_COUNT = (
    client,
    date,
    enseigne,
    type,
) => createStatistiqueURL("specialty/count", client, date, enseigne, type);
export const GET_STATISTIQUE_INTERVENTION_COUNT = (
    client,
    date,
    enseigne,
    type,
) =>
    createStatistiqueURL("intervention/count", client, date, enseigne, type);
export const GET_STATISTIQUE_REVENUS_TOTAL = (
    client,
    date,
    enseigne,
    type,
) => createStatistiqueURL("totals", client, date, enseigne, type);
export const GET_STATISTIQUE_REVENUS_PER_MONTH = (
    client,
    date,
    enseigne,
    type,
) => createStatistiqueURL("perMonth", client, date, enseigne, type);
export const GET_10_SPENDINGS_STORE = (client, date, enseigne, type) =>
    createStatistiqueURL(
        "top10/most_spending_stores",
        client,
        date,
        enseigne,
        type,
    );
export const GET_10_INTERVENTION_STORE = (client, date, enseigne, type) =>
    createStatistiqueURL(
        "top10/most_intervention_per_stores",
        client,
        date,
        enseigne,
        type,
    );
export const GET_5_SPECIALITY = (client, date, enseigne, magasin, type) =>
    createStatistiqueURL("top5/specialty", client, date, enseigne, magasin, type);
export const GET_AVERAGE_COST_PER_INTERVENTION = (client, date, enseigne, magasin, type) =>
  createStatistiqueURL("average_cost_per_intervention",client,date, enseigne, magasin, type);
export const GET_STATISTIQUE_AVERAGE_COST_PER_MONTH = (client, date, enseigne, magasin, type ) =>
  createStatistiqueURL( "average_cost_per_month", client, date, enseigne, magasin, type);

export const GET_STATS_MONTHS_CLIENTS = (year, enseigne, type) => `/legacy/statistics/clients-monthly?year=` + year + `&enseigne=` + enseigne + `&type=` + type;

//user evictum
export const POST_USER_EVICTUM = () => `/legacy/postEvictumUser`;
export const DELETE_USER_EVICTUM_BY_ID = (id) => `/legacy/deleteEvictumUser?ID=${id}`;
export const GET_ALL_EVICTUM_USERS = () => `/legacy/getAllEvictumUsers`;
export const PUT_EVICTUM_USER = () => `/legacy/putEvictumUser`;
export const GET_UNCREATED_ACCOUNT = (selectedClient) => `/legacy/uncreatedAccount?client=${selectedClient}`;
export const GET_GROUPE_CLIENT = () => `/legacy/groupeClient`;

export const GET_LOGO = () => `/legacy/allLogo`

//commande Stocks

//stocks
export const GET_ALL_STOCKS = () => `/legacy/stocks`;
export const GET_STOCKS_BY_ID = (id) => `/legacy/stocks?id=${id}`;
export const GET_STOCKS_BY_CLIENT = (client) =>
    `/legacy/stockByClient?client=${client}`;
export const GET_STOCKS_BY_PRODUCT = (product) =>
    `/legacy/stockByProduct?product=${product}`;
export const POST_STOCK = () => `/legacy/stocks`;
export const PUT_STOCK = () => `/legacy/stocks`;

//commande
export const GET_ALL_COMMANDES = () => `/legacy/commandeStocks`;
export const GET_COMMANDE_BY_IDMASTORE = (id_mastore) =>
    `/legacy/commandeStockIdmastore?id_mastore=${id_mastore}`;
export const GET_COMMANDE_BY_CLIENT = (client) =>
    `/legacy/commandeStockByClient?client=${client}`;
export const GET_COMMANDE_BY_ID = (id) => `/legacy/commandeStockById?id=${id}`;
export const POST_COMMANDE = () => `/legacy/commandeStock`;
export const PUT_COMMANDE = () => `/legacy/commandeStock`;

export const GET_ENSEIGNE_BY_CLIENT = (client) =>
    `/legacy/enseigneByClient?client=${client}`;



