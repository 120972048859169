import axios from 'axios'
import {
    POST_MESSAGE,
    GET_MESSAGE_BY_ID_MASTORE,
    GET_ALL_LAST_MESSAGE_BY_PILOTE,
    GET_ALL_LAST_MESSAGE_BY_CLIENT,
    PUT_MESSAGE_READ_BY_ID,
    PUT_MESSAGE_ANSWERED_BY_ID,
    GET_LAST_MESSAGE_BY_ID_MASTORE,
    PUT_CLOSED_DISCUSSION,
    PUT_REOPEN_DISCUSSION,
    GET_ALL_MESSAGES,
} from "./CONSTANTS";

export const sendMessage = (message) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(POST_MESSAGE(), message)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in send message axios!");
                });
        } catch (error) {
            console.error("in messageService > sendMessage, Err===", error);
        }
    });
}

export const getAllMessages = (pilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_MESSAGES(pilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in getFiles axios!");
                });
        } catch (error) {
            console.error("in messageService > getAllMessages, Err===", error);
        }
    });
}

export const getMessageByIdMastore = (id_mastore) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_MESSAGE_BY_ID_MASTORE(id_mastore))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in getFiles axios!");
                });
        } catch (error) {
            console.error("in messageService > getMessageByIdMastore, Err===", error);
        }
    });
};

export const getLastMessageByPilote = (pilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_LAST_MESSAGE_BY_PILOTE(pilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("getFiles > axios err=", err);
                    reject("Error in getFiles axios!");
                });
        } catch (error) {
            console.error("in messageService > getLastMessageByPilote, Err===", error);
        }
    });
};

export const getLastMessageByClient = (pilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_LAST_MESSAGE_BY_CLIENT(pilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("getFiles > axios err=", err);
                    reject("Error in getFiles axios!");
                });
        } catch (error) {
            console.error("in messageService > getLastMessageByClient, Err===", error);
        }
    });
}

export const getLastMessage = (id_mastore) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_LAST_MESSAGE_BY_ID_MASTORE(id_mastore))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("getFiles > axios err=", err);
                    reject("Error in getFiles axios!");
                });
        } catch (error) {
            console.error("in messageService > getLastMessage, Err===", error);
        }
    });
}

export const putMessageRead = (message) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_MESSAGE_READ_BY_ID(), message)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in send message axios!");
                });
        } catch (error) {
            console.error("in messageService > putMessage, Err===", error);
        }
    });
}

export const putMessageAnswered = (message) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_MESSAGE_ANSWERED_BY_ID(), message)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in send message axios!");
                });
        } catch (error) {
            console.error("in messageService > putMessage, Err===", error);
        }
    });
}

export const putClosedDiscussion = (id_mastore, pilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_CLOSED_DISCUSSION(id_mastore, pilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in send message axios!");
                });
        } catch (error) {
            console.error("in messageService > putMessage, Err===", error);
        }
    });
}

export const reOpenDiscussion = (id_mastore, pilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_REOPEN_DISCUSSION(id_mastore, pilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("message > axios err=", err);
                    reject("Error in send message axios!");
                });
        } catch (error) {
            console.error("in messageService > putMessage, Err===", error);
        }
    });
}
