import React, { useState } from 'react';
import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NAVBAR } from 'config';
import { AppBar, Stack, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircle from '@mui/icons-material/CheckCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreIcon from '@mui/icons-material/Restore';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import Chat from 'components/UI/Chat/Chat';
import { useSnackbar } from 'notistack';

/* ====================================================
   Styled Components
==================================================== */

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'update',
})(({ theme, update }) => ({
    background: 'white',
    width: '220px',
    variant: update ? 'none' : 'outlined',
    '&:hover': {
        variant: update ? 'none' : 'outlined',
    },
    ...(update && {
        background: theme.palette.warning.main,
        color: 'white',
        border: 'none',
        '&:hover': {
            background: alpha(theme.palette.warning.main, 0.8),
            border: 'none',
        },
    }),
}));

const ButtonValider = styled(Button)(({ theme }) => ({
    width: '120px',
    '&:hover': {
        borderColor: alpha('#bf8c00', 0.8),
        background: alpha('#886402', 0.8),
        color: 'white',
    },
}));

const ButtonArchiver = styled(Button)(({ theme }) => ({
    width: '120px',
    background: 'white',
    color: '#4B5563',
    borderColor: '#4B5563',
    '&:hover': {
        borderColor: '#4B5563',
        background: alpha('#4B5563', 0.8),
        color: 'white',
    },
}));

const ButtonCorbeille = styled(Button)(({ theme }) => ({
    width: '120px',
    background: 'white',
    color: '#EB8E90',
    borderColor: '#EB8E90',
    '&:hover': {
        borderColor: '#EB8E90',
        background: alpha('#EB8E90', 0.8),
        color: 'white',
    },
}));

const ButtonRestaurer = styled(Button)(({ theme }) => ({
    width: '120px',
    background: 'white',
    color: '#53B9AB',
    borderColor: '#53B9AB',
    '&:hover': {
        borderColor: '#53B9AB',
        background: alpha('#53B9AB', 0.8),
        color: 'white',
    },
}));

const ButtonFinish = styled(Button)(({ theme }) => ({
    width: '120px',
    '&:hover': {
        background: alpha('rgb(76, 175, 80)', 0.8),
        borderColor: 'rgb(76, 175, 80)',
        color: 'white',
    },
}));

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ theme, collapseClick }) => ({
    top: 'auto',
    bottom: 0,
    padding: theme.spacing(0, 2),
    background: '#fcfcfc',
    height: '5vh',
    borderTop: '1px dashed rgba(145, 158, 171, 0.24)',
    width: `calc(100% - ${collapseClick ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH
        }px)`,
}));

/* ====================================================
   Composant ButtonBar
==================================================== */

const ButtonBar = ({
    update,
    archiveIntervention,
    moveToCorbeilleIntervention,
    restaureIntervention,
    OnchangeIntervention,
    saveIntervention,
    infoIntervention,
    isSaveDisabled,
    isArchiveDisabled,
    isCorbeilleDisabled,
    archiveErrorMessage, // Message d'erreur personnalisé pour l'archivage
    openDialogFinish,
    openDialogValider,
    user,
}) => {
    const { collapseClick } = useCollapseDrawer();
    const { enqueueSnackbar } = useSnackbar();
    const [disable, setDisable] = useState(false);

    const disableChat = infoIntervention?.chat_client;

    /* ----------------------------------------------------------------
       Handlers
    ---------------------------------------------------------------- */

    const onClickSave = () => {
        setDisable(true);
        saveIntervention();
    };

    const handleDialogFinish = () => {
        const { contact_client, status } = infoIntervention;
        if ((contact_client === '' || contact_client === 'select') && status !== 'TERMINEE') {
            enqueueSnackbar(
                "Veuillez choisir un contact client dans les informations générales de l'intervention",
                { variant: 'warning' }
            );
            return;
        }
        if (status === 'TERMINEE') {
            enqueueSnackbar("L'intervention est déjà TERMINÉE", { variant: 'info' });
            return;
        }
        if (update) {
            enqueueSnackbar("Veuillez sauvegarder avant de passer l'intervention en TERMINÉE", {
                variant: 'warning',
            });
            return;
        }
        openDialogFinish();
    };

    const handleDialogValider = () => {
        const { contact_client, status } = infoIntervention;
        if ((contact_client === '' || contact_client === 'select') && status !== 'VALIDEE') {
            enqueueSnackbar(
                "Veuillez choisir un contact client dans les informations générales de l'intervention",
                { variant: 'warning' }
            );
            return;
        }
        if (status === 'VALIDEE') {
            enqueueSnackbar("L'intervention est déjà VALIDEE", { variant: 'info' });
            return;
        }
        if (update) {
            enqueueSnackbar("Veuillez sauvegarder avant de passer l'intervention en VALIDEE", {
                variant: 'warning',
            });
            return;
        }
        openDialogValider();
    };

    const handleArchiveDisabled = () => {
        if (isArchiveDisabled) {
            let fullErrorMessage = archiveErrorMessage;
            if (archiveErrorMessage.includes('Aucun devis')) {
                fullErrorMessage += ' Veuillez ajouter un devis et réessayer.';
            } else if (archiveErrorMessage.includes('Certains devis')) {
                fullErrorMessage += ' Veuillez archiver ou refuser tous les devis en attente et réessayer.';
            } else if (archiveErrorMessage.includes('facture intervenant')) {
                fullErrorMessage += ' Veuillez compléter la facture intervenant avant d\'archiver.';
            }
            enqueueSnackbar(fullErrorMessage, { variant: 'warning' });
        } else {
            archiveIntervention();
        }
    };

    const handleMoveToCorbeilleDisabled = () => {
        if (isCorbeilleDisabled) {
            restaureIntervention();
        } else {
            moveToCorbeilleIntervention();
        }
    };

    const handleRestaure = () => {
        restaureIntervention();
    };

    /* ----------------------------------------------------------------
       Rendu
    ---------------------------------------------------------------- */

    return (
        <StyledAppBar position="fixed" collapseClick={collapseClick}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" height={1}>
                <Chat intervention={infoIntervention} user={user} disableChat={disableChat} />
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ width: '100%' }}>
                    {isCorbeilleDisabled ? (
                        <ButtonRestaurer variant="outlined" onClick={handleMoveToCorbeilleDisabled} startIcon={<RestoreIcon />}>
                            Restaurer
                        </ButtonRestaurer>
                    ) : infoIntervention.status === 'TERMINEE' ||
                        infoIntervention.status === 'Validée' ||
                        infoIntervention.status === 'ARCHIVEE' ? null : (
                        <ButtonCorbeille variant="outlined" onClick={handleMoveToCorbeilleDisabled} startIcon={<DeleteForeverIcon />}>
                            Corbeille
                        </ButtonCorbeille>
                    )}

                    {isCorbeilleDisabled ||
                        infoIntervention.status === 'TERMINEE' ||
                        infoIntervention.status === 'Validée' ||
                        infoIntervention.status === 'ARCHIVEE' ? null : (
                        <ButtonArchiver
                            variant="outlined"
                            onClick={handleArchiveDisabled}
                            disabled={isCorbeilleDisabled}
                            startIcon={<ArchiveIcon />}
                        >
                            Archiver
                        </ButtonArchiver>
                    )}

                    {user.role === "Assistant(e)" ? null : (
                        user.pilote && infoIntervention.status === 'EN COURS' ? (
                            <ButtonFinish variant="outlined" color="success" onClick={handleDialogFinish} startIcon={<DoneIcon />}>
                                Terminer
                            </ButtonFinish>
                        ) : infoIntervention.status === 'TERMINEE' ? null : (
                            <ButtonRestaurer variant="outlined" onClick={handleRestaure} startIcon={<RestoreIcon />}>
                                Restaurer
                            </ButtonRestaurer>
                        )
                    )}


                    {user.role === "Assistant(e)" ? null : (isCorbeilleDisabled ||
                        infoIntervention.status === 'Validée' ||
                        infoIntervention.status === 'ARCHIVEE' ? null : (
                        <ButtonValider
                            variant="outlined"
                            color="warning"
                            onClick={handleDialogValider}
                            startIcon={<CheckCircle />}
                        >
                            Valider
                        </ButtonValider>
                    ))}

                    {OnchangeIntervention !== '' && OnchangeIntervention !== user.userID ? (
                        <Button onClick={saveIntervention} disabled variant="contained" startIcon={<SaveIcon />}>
                            En cours de modification par {OnchangeIntervention}
                        </Button>
                    ) : (
                        <StyledButton
                            onClick={onClickSave}
                            update={update}
                            disabled={disable || isSaveDisabled || isCorbeilleDisabled}
                            variant="outlined"
                            startIcon={<SaveIcon />}
                        >
                            {infoIntervention.last_save ? infoIntervention.last_save : 'Sauvegarder'}
                        </StyledButton>
                    )}
                </Stack>
            </Stack>
        </StyledAppBar>
    );
};

export default ButtonBar;
