import React from 'react';
import { Typography, Avatar, Stack } from "@mui/material";
import { DisplayMessageProps } from "../types";

const logo = require("../../../../assets/img/LogoMastore.png") as string;

export const DisplayMessage: React.FC<DisplayMessageProps> = ({ message, endRef }) => {
    return (
        <React.Fragment>
            <Stack spacing={0.8}>
                {message && message.length > 0 ?
                    message.map((item, index) => {
                        if (item.send_by === "mastore") {
                            return (
                                <React.Fragment key={`mastore-${index}`}>
                                    <Typography variant="caption" textAlign="right" sx={{ color: "#65676b" }}>
                                        {item.sender}
                                    </Typography>
                                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                                        <Typography
                                            style={{
                                                backgroundColor: "#0084FF",
                                                borderRadius: 10,
                                                padding: "6px 10px",
                                                color: "white"
                                            }}
                                            variant="body1"
                                        >
                                            {item.message}
                                        </Typography>
                                        <Avatar sx={{ width: 24, height: 24, backgroundColor: "white" }}>
                                            <img src={logo} alt="logo" />
                                        </Avatar>
                                    </Stack>
                                </React.Fragment>
                            );
                        } else if (item.send_by === "client") {
                            return (
                                <React.Fragment key={`client-${index}`}>
                                    <Typography variant="caption" sx={{ color: "#65676b" }}></Typography>
                                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="flex-start">
                                        <Avatar sx={{ width: 24, height: 24 }} />
                                        <Typography
                                            style={{
                                                backgroundColor: "#E3E6EA",
                                                borderRadius: 10,
                                                padding: "6px 10px"
                                            }}
                                            variant="body1"
                                        >
                                            {item.message}
                                        </Typography>
                                    </Stack>
                                </React.Fragment>
                            );
                        }
                        return null;
                    })
                    : null
                }
                <div ref={endRef}></div>
            </Stack>
        </React.Fragment>
    );
};
