import React, { useState } from "react";
import { Card, Stack, TextField, Typography, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const schema = yup.object({
  newPassword: yup
    .string()
    .required("Le mot de passe est requis")
    .matches(
      /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
      "Votre mot de passe doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
    ),
  confirmPwd: yup
    .string()
    .required("Le mot de passe est requis")
    .oneOf([yup.ref("newPassword"), null], "Les mots de passe doivent correspondre"),
}).required();

export default function PasswordForm({ userInformation }) {
  const [openForm, setOpenForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitForm = (inputUser) => {
    // Exemple d'appel d'API pour changer le mot de passe :
    // changePassword(inputUser)
    //   .then((response) => {
    //     enqueueSnackbar('Vos informations ont bien été enregistrées', { variant: 'success' });
    //     reset();
    //   })
    //   .catch((err) => {
    //     enqueueSnackbar('Votre ancien mot de passe est incorrect', { variant: 'error' });
    //     console.error(err);
    //   });
  };

  return (
    <Card sx={{ p: 3, width: 500, height: "auto" }}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={1} alignItems="center">
          <AccountCircleIcon color="disabled" sx={{ height: 60, width: 60 }} />
          <Stack textAlign={"center"} >
            <Typography variant="h5">
                {userInformation.firstname} {userInformation.lastname}
            </Typography>
            <Typography variant="subtitle1">
                {userInformation.role}
            </Typography>
          </Stack>
          {!openForm && (
            <Button variant="contained" size="small" onClick={() => setOpenForm(true)}>
              Modifier mon mot de passe
            </Button>
          )}
          {openForm && (
            <>
            <Stack spacing={2} width={1}>
              <TextField
                size="small"
                fullWidth
                type="password"
                label="Ancien mot de passe"
                InputLabelProps={{ shrink: true }}
                {...register("oldpassword")}
              />
              <TextField
                size="small"
                fullWidth
                type="password"
                label="Nouveau mot de passe"
                InputLabelProps={{ shrink: true }}
                helperText={errors.newPassword?.message}
                error={Boolean(errors.newPassword)}
                {...register("newPassword")}
              />
              <TextField
                size="small"
                fullWidth
                type="password"
                label="Confirmer nouveau mot de passe"
                InputLabelProps={{ shrink: true }}
                helperText={errors.confirmPwd?.message}
                error={Boolean(errors.confirmPwd)}
                {...register("confirmPwd")}
              />
            </Stack>
              <LoadingButton type="submit" size="small" variant="contained">
                Envoyer
              </LoadingButton>
            </>
          )}
        </Stack>
      </form>
    </Card>
  );
}
