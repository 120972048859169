import React, { useState } from 'react';

// Hooks
import { useSnackbar } from 'notistack';

// Styles
import { CardPagination } from './ModuleDevisStyle';

// MUI
import Modal from "components/UI/Modal";
import Grid from '@mui/material/Unstable_Grid2';
import ModalAddModule from './components/ModalAddModule';
import { Alert, alpha, Box, CircularProgress, Typography, useTheme } from '@mui/material';

// Services
import ModuleDevisService from 'services/modulesDevisService';

// Tanstack
import { useQuery, useQueryClient, } from '@tanstack/react-query';

// Types
import { Module, ModulesDevisProps, searchFilters } from './types';

// Components
import NavBar from './components/NavBar'
import ModuleDevisTable from './components/ModuleDevisTable';
import CardModule from '../../components/UI/ModuleDevis/CardModule';
import { BasicPagination } from 'components/UI/ModuleDevis/BasicPagination';

const ModuleDevisContainer: React.FC<ModulesDevisProps> = () => {
    const theme = useTheme();

    const modulesPerPage = 18;
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const [currentPage, setCurrentPage] = useState(1);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [viewMode, setViewMode] = useState<'card' | 'list'>(() => {
        return localStorage.getItem('viewMode') as 'card' | 'list' || 'card';
    });
    const [filters, setFilters] = useState<searchFilters>({ name: '', description: '', specialite: [] });

    const moduleDevisService = new ModuleDevisService();

    const { data: modulesData, error: modulesError, isLoading: modulesLoading, refetch } = useQuery({
        queryKey: ['getModules', modulesPerPage, currentPage],
        queryFn: () => moduleDevisService.getModules(
            currentPage,
            modulesPerPage,
            filters.name ?? "",
            filters.description ?? "",
            filters.specialite
        ),
        staleTime: Infinity,
    });

    const addModule = async (newModule: Module) => {
        try {
            await moduleDevisService.createModule(newModule);
            enqueueSnackbar("Module et modules devis sites ajoutés avec succès", { variant: "success" });
            setOpenCreateModal(false);
            queryClient.invalidateQueries({ queryKey: ['getModules'] });
        } catch (error) {
            enqueueSnackbar("Erreur lors de l'ajout du module et des modules devis sites", { variant: "error" });
            console.error(error);
        }
    };

    const handleOpenCreateModal = () => {
        setOpenCreateModal(!openCreateModal);
    }

    const handleSearch = () => {
        refetch();
        setCurrentPage(1);
    };

    const handleToggleViewMode = (event: React.MouseEvent<HTMLElement>, newViewMode: 'card' | 'list') => {
        if (newViewMode !== null && newViewMode !== viewMode) {
            setViewMode(newViewMode);
            localStorage.setItem('viewMode', newViewMode);
        }
    };

    if (modulesError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Alert severity="error">Erreur lors du chargement des modules devis</Alert>
            </Box>
        );
    }

    if (modulesLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    } else {
        return (
            <React.Fragment>
                <Box style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                    <NavBar
                        filters={filters}
                        viewMode={viewMode}
                        onSearch={handleSearch}
                        setFilters={setFilters}
                        handleOpenCreateModal={handleOpenCreateModal}
                        handleToggleViewMode={handleToggleViewMode}
                    />
                    <Box flexGrow={1} sx={{ overflowY: 'auto' }}>
                        {modulesData.modules && modulesData.modules.length > 0 ? (
                            viewMode === 'card' ? (
                                <Grid container padding={1}>
                                    {modulesData.modules.map((moduleDevis: Module) => (
                                        <Grid xs={6} sm={4} md={4} xl={3} padding={1} key={moduleDevis.ID}>
                                            <CardModule
                                                mode="redirect"
                                                moduleDevis={{ ...moduleDevis }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <ModuleDevisTable modules={modulesData.modules} mode="redirect" tableHeight="calc(100vh - 146px)" />
                            )
                        ) : (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%" width={"100%"}>
                                <Typography variant="h1" align="center" color={alpha(theme.palette.text.secondary, 0.30)}>
                                    Aucun module
                                </Typography>
                            </Box>
                        )}
                        <Modal open={openCreateModal} close={handleOpenCreateModal} sx={{ width: { xl: "50vw", md: "50vw", sm: "70vw", xs: "95vw" }, height: "60vh" }}>
                            <ModalAddModule
                                addModule={addModule}
                            />
                        </Modal>
                    </Box>
                    <CardPagination>
                        <Grid container justifyContent="center" sx={{ width: "100%" }}>
                            <BasicPagination
                                currentPage={currentPage}
                                total={modulesData.totalModules}
                                itemsPerPage={modulesPerPage}
                                onPageChange={(_event, page) => setCurrentPage(page)}
                            />
                        </Grid>
                    </CardPagination>
                </Box>
            </React.Fragment>
        );
    }
};

export default ModuleDevisContainer;