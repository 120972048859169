import axios from 'axios'
import {
    GET_ALL_ENSEIGNE,
    GET_MAGASIN_BY_ENSEIGNE,
    GET_CONTACT_BY_CLIENT,
    GET_ALL_CLIENT,
    GET_SITE_WITH_PREVENTIVE,
    GET_SITE_WITH_PREVENTIVE_SPECIALITE,
    GET_ENSEIGNE_WITH_PREVENTIVE,
    GET_SITE_BY_CLIENT,
    GET_ENSEIGNES_BY_PILOTE,
    GET_CLIENTS_BY_PILOTE
} from "./CONSTANTS";


export const GetAllEnseigne = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_ENSEIGNE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllEnseigne > axios err=", err);
                    reject("Error in GetAllEnseigne axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetAllEnseigne, Err===", error);
        }
    });
};

export const GetMagasinByEnseigne = (enseigne) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_MAGASIN_BY_ENSEIGNE(enseigne))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in get magasin by enseigne axios!");
                });
        } catch (error) {
            console.error("Error in get magasin by enseigne axios!", error);
        }
    });
};

export const GetMagasinByClient = (client) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_SITE_BY_CLIENT(client))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in get magasin by enseigne axios!");
                });
        } catch (error) {
            console.error("Error in get magasin by enseigne axios!", error);
        }
    });
}

export const GetContactByClient = (client) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_CONTACT_BY_CLIENT(client))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in get contact by enseigne axios!");
                });
        } catch (error) {
            console.error("Error in get contact by enseigne axios!", error);
        }
    });
};


export const GetAllClient = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_CLIENT())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllClient > axios err=", err);
                    reject("Error in GetAllClient axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetAllClient, Err===", error);
        }
    });
};

export const GetSiteWithPreventive = (year, enseigne) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_SITE_WITH_PREVENTIVE(year, enseigne))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetSiteWithPReventive > axios err=", err);
                    reject("Error in GetSiteWithPReventive axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetSiteWithPReventive, Err===", error);
        }
    });
};

export const GetSiteWithPreventiveSpecialite = (year, enseigne, specialite) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_SITE_WITH_PREVENTIVE_SPECIALITE(year, enseigne, specialite))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetSiteWithPReventiveSpecialite > axios err=", err);
                    reject("Error in GetSiteWithPReventiveSpecialite axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetSiteWithPReventiveSpecialite, Err===", error);
        }
    });
};

export const GetEnseigneWithPreventive = (year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ENSEIGNE_WITH_PREVENTIVE(year))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetEnseigneWithPreventive > axios err=", err);
                    reject("Error in GetEnseigneWithPreventive axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetEnseigneWithPreventive, Err===", error);
        }
    });
};

export const GetEnseignesByPilote = async (userID, year) => {
   return new Promise((resolve, reject) => {
    try {
        axios.defaults.withCredentials = true
        axios.get(GET_ENSEIGNES_BY_PILOTE(userID, year))
            .then((res) => {
                resolve(res.data);
            }).catch((err) => {
                console.log("fetchEnseignesByPilote > axios err=", err);
                reject("Error in fetchEnseignesByPilote axios!");
            });
    } catch (error) {
        console.error("in siteServices > fetchEnseignesByPilote, Err===", error);
    }
    });
};

export const GetClientsByPilote = async (userID, year) => {
    return new Promise((resolve, reject) => {
     try {
         axios.defaults.withCredentials = true
         axios.get(GET_CLIENTS_BY_PILOTE(userID, year))
             .then((res) => {
                 resolve(res.data);
             }).catch((err) => {
                 console.log("fetchClientssByPilote > axios err=", err);
                 reject("Error in fetchClientsByPilote axios!");
             });
     } catch (error) {
         console.error("in siteServices > fetchClientsByPilote, Err===", error);
     }
     });
 };
