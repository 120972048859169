import React, { useState, useEffect, useContext, MouseEvent, ReactNode } from "react";
import { connect } from "react-redux";
import moment from "moment";
// MUI
import { styled, alpha, useTheme } from "@mui/material/styles";
import {
  Popover,
  Typography,
  IconButton,
  Stack,
  Badge,
  Box,
  Tooltip,
  Card,
  CardContent,
  Avatar,
  Tabs,
  Tab,
  CircularProgress,
  Fade,
} from "@mui/material";
import Chip from "@mui/material/Chip";
// Icônes
import MessageIcon from "@mui/icons-material/Message";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// Services
import { putMessageRead, getAllMessages } from "services";
// Context
import ChatContext from "contexts/ChatContext";
// Lodash
import { filter, uniqBy } from "lodash";
// Types
import { Message, AllChatsProps } from "./types";
import { dateConfig } from "config";

// Interface pour les props de TabPanel
interface TabPanelProps {
  children?: ReactNode;
  value: number;
  index: number;
  [key: string]: any;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const MessageAvatar = styled(Avatar)(({ theme }) => ({
  width: 38,
  height: 38,
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.2s, box-shadow 0.2s",
  "&:hover": {
    cursor: "pointer",
    transform: "translateY(-2px)",
    boxShadow: theme.shadows[4],
  },
}));

const MessagePopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: 500,
    maxHeight: '80vh',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
    overflow: 'hidden',
  },
}));

const MessageHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  padding: 4,
  backgroundColor: alpha(theme.palette.grey[500], 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[500], 0.2),
  },
}));

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`conversation-tabpanel-${index}`}
        aria-labelledby={`conversation-tab-${index}`}
        {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
);

const AllChats: React.FC<AllChatsProps> = ({ attached_to }) => {
  const ctx = useContext(ChatContext);
  const [read, setRead] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState<number>(0);
  const [nbLastMessagesForBadge, setNbLastMessagesForBadge] = useState<number>(0);
  const [nbLastMessagesNotAnswered, setNbLastMessagesNotAnswered] = useState<number>(0);

  const theme = useTheme();
  const attachedToString = "" + attached_to;

  const routeChange = (message: Message) => {
    if (!message.read) {
      putMessageRead({ id_mastore: message.id_mastore, read: true });
    }
    setRead(!read);
    window.open(`/intervention?IDMastore=${message.id_mastore}`, '_blank');
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setLoading(false);
    Promise.all([getAllMessages(attachedToString)])
        .then(([response]) => {
          const sortedMessages = response.message.sort(
              (a: Message, b: Message) =>
                  new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
          );
          const uniqueDiscussions = uniqBy<Message>(sortedMessages, (msg) => msg.id_mastore);
          setMessages(uniqueDiscussions);
          setNbLastMessagesForBadge(
              filter(uniqueDiscussions, { send_by: "client", read: false, closed: false }).length
          );
          setNbLastMessagesNotAnswered(
              filter(uniqueDiscussions, { send_by: "client", answered: false, closed: false }).length
          );
          setLoading(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(true);
        });
  }, [ctx.isNewMessage, read, attachedToString, ctx.isOpenChat]);

  const formatDate = (date: string): string => {
    const momentDate = moment(date);
    if (!momentDate.isValid()) return "Date invalide";

    const now = moment();
    const today = now.startOf("day");
    const yesterday = moment().subtract(1, "days").startOf("day");

    if (momentDate.isSame(today, "day")) {
      return `Aujourd'hui à ${momentDate.format(dateConfig.format7)}`;
    } else if (momentDate.isSame(yesterday, "day")) {
      return `Hier à ${momentDate.format(dateConfig.format7)}`;
    } else {
      return momentDate.format(dateConfig.format4);
    }
  };

  const formatStatus = (status: string | undefined): string => {
    if (!status) return "Inconnu";

    const statusMap: Record<string, string> = {
      "A TRAITER": "À traiter",
      "EN COURS": "En cours",
      "PROGRAMMEE": "Programmée",
      "ARCHIVEE": "Archivée",
      "TERMINEE": "Terminée",
    };

    return statusMap[status] || status;
  };

  const getMessageStatus = (item: Message): string => {
    if (item.send_by === "client") {
      if (!item.read) return "Non lu";
      if (item.read && !item.answered) return "Non répondu";
    } else if (item.send_by === "mastore" && !item.read && !item.answered) {
      return "Attente client";
    }
    return "";
  };

  const getStatusChip = (item: Message) => {
    const status = getMessageStatus(item);
    if (!status) return null;

    let chipColor:
        | "default"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning" = "default";

    switch (status) {
      case "Non lu":
        chipColor = "error";
        break;
      case "Non répondu":
        chipColor = "warning";
        break;
      case "Attente client":
        chipColor = "success";
        break;
      default:
        chipColor = "default";
    }

    return (
        <Chip
            label={status}
            color={chipColor}
            size="small"
            sx={{
              height: "22px",
              "& .MuiChip-label": {
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "0.7rem",
                fontWeight: "600",
                color: "white"
              },
            }}
        />
    );
  };

  const getStatusColor = (status?: string, closed?: boolean): string => {
    if (closed) return "#757575";

    const formattedStatus = status ? formatStatus(status) : "Inconnu";
    const statusColors: Record<string, string> = {
      "À traiter": "#d32f2f",
      "En cours": "#ed6c02",
      "Programmée": "#0288d1",
      "Archivée": "#9e9e9e",
      "Terminée": "#2e7d32",
      "Inconnu": "#2065d1"
    };

    return statusColors[formattedStatus] || "#2065d1";
  };

  const handleStatusAvatar = (status?: string, closed?: boolean) => {
    const statusText = status ? formatStatus(status) : "Inconnu";
    const statusColor = closed
        ? "#212B36"
        : ((
            {
              "À traiter": "#f44336",
              "En cours": "#ff9800",
              "Programmée": "#2196f3",
              "Archivée": "#9e9e9e",
              "Terminée": "#4caf50",
            } as Record<string, string>
        )[statusText] || "#2065d1");
    return (
        <Typography variant="caption" fontWeight={"bold"} color={statusColor}>
          {statusText}
        </Typography>
    );
  };

  const getInitials = (enseigne: string): string => {
    return enseigne
        .split(" ")
        .map(word => word.charAt(0))
        .join("")
        .toUpperCase()
        .slice(0, 2);
  };

  const renderMessageCards = (messages: Message[]) => {
    if (!loading) {
      return (
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <CircularProgress size={28} color="primary" />
          </Box>
      );
    }

    if (messages.length === 0) {
      return (
          <Box sx={{ py: 4, textAlign: "center" }}>
            <Typography
                variant="body1"
                color={alpha(theme.palette.text.secondary, 0.5)}
            >
              Aucune conversation disponible
            </Typography>
          </Box>
      );
    }

    return messages.map((item) => (
        <Tooltip
            key={item.id_mastore}
            title={`${item.send_by === "client" ? item.emited_by : "Vous"} ${
                item.send_by === "client" ? "a envoyé :" : "avez envoyé :"
            } ${item.message}`}
            placement="left"
            arrow
        >
          <StyledCard
              variant={item.send_by === "client" && !item.read ? "elevation" : "outlined"}
              elevation={item.send_by === "client" && !item.read ? 2 : 0}
              sx={{
                ...(item.send_by === "client" && !item.read
                    ? {
                      background: alpha(theme.palette.primary.main, 0.04),
                      borderLeft: `3px solid ${theme.palette.primary.main}`
                    }
                    : {}),
              }}
              onClick={() => routeChange(item)}
          >
            <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
              <Stack
                  direction="row"
                  spacing={1.5}
                  alignItems="flex-start"
              >
                <MessageAvatar style={{ backgroundColor: getStatusColor(item.status, item.closed) }}>
                  {getInitials(item.enseigne)}
                </MessageAvatar>

                <Box sx={{ width: "100%", overflow: "hidden" }}>
                  <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography variant="h6" noWrap sx={{ fontWeight: 600 }}>
                        {item.enseigne} - {item.id_mastore}
                      </Typography>
                    </Stack>
                    {getStatusChip(item)}
                  </Stack>

                  <Typography
                      variant="h6"
                      color="text.secondary"
                      sx={{ display: "block", mb: 0.5, fontWeight: "bold" }}
                  >
                    {item.ville || "N/A"} | N°{item.numero_magasin || "N/A"} | {handleStatusAvatar(item.status)}
                  </Typography>

                  <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        color: item.send_by === "client" && !item.read
                            ? "text.primary"
                            : "text.secondary",
                        fontWeight: item.send_by === "client" && !item.read ? 500 : 400,
                      }}
                  >
                    {item.message}
                  </Typography>

                  <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ mt: 1 }}
                  >
                    <Chip
                        size="small"
                        label={item.send_by === "client" ? "Client" : item.sender}
                        sx={{
                          height: "20px",
                          backgroundColor: item.send_by === "client"
                              ? alpha(theme.palette.warning.main, 0.1)
                              : alpha(theme.palette.info.main, 0.1),
                          color: item.send_by === "client"
                              ? theme.palette.warning.dark
                              : theme.palette.info.dark,
                          "& .MuiChip-label": {
                            px: 1,
                            fontSize: "0.65rem",
                            fontWeight: 600
                          }
                        }}
                        icon={
                          item.send_by === "client"
                              ? <PersonOutlineOutlinedIcon fontSize="small" />
                              : <EmailOutlinedIcon fontSize="small" />
                        }
                    />
                    <Typography variant="h6" color="text.secondary">
                      {formatDate(item.CreatedAt)}
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </StyledCard>
        </Tooltip>
    ));
  };

  const open = Boolean(anchorEl);
  const id = open ? "messages-popover" : undefined;

  return (
      <div>
        <Badge badgeContent={nbLastMessagesNotAnswered} color="error">
          <Badge badgeContent={nbLastMessagesForBadge} color="primary">
            <IconButton onClick={handleClick} sx={{ transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.1)' }}}>
              <MessageIcon />
            </IconButton>
          </Badge>
        </Badge>

        <MessagePopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            disableScrollLock={true}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
        >
          <Box sx={{ position: "relative" }}>
            <MessageHeader>
              <Typography variant="subtitle1" fontWeight="bold">
                Messagerie
              </Typography>
              <CloseButton size="small" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </CloseButton>
            </MessageHeader>

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.6)}`,
                  "& .MuiTab-root": {
                    py: 1.5,
                    minHeight: "auto",
                    textTransform: "none",
                    fontWeight: 600,
                  }
                }}
            >
              <Tab
                  label={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>En cours</Typography>
                      {nbLastMessagesForBadge > 0 && (
                          <Chip
                              label={nbLastMessagesForBadge}
                              size="small"
                              color="primary"
                              sx={{ height: 20, "& .MuiChip-label": { px: 0.8, fontSize: "0.7rem" } }}
                          />
                      )}
                    </Stack>
                  }
              />
              <Tab
                  label={
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      <Typography>Clôturées</Typography>
                      <ArchiveOutlinedIcon fontSize="small" />
                    </Stack>
                  }
              />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              <Fade in={true}>
                <Box sx={{ maxHeight: 420, overflowY: "auto", px: 2, py: 1 }}>
                  {renderMessageCards(messages.filter(item => !item.closed))}
                </Box>
              </Fade>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Fade in={true}>
                <Box sx={{ maxHeight: 420, overflowY: "auto", px: 2, py: 1 }}>
                  {renderMessageCards(messages.filter(item => item.closed))}
                </Box>
              </Fade>
            </TabPanel>
          </Box>
        </MessagePopover>
      </div>
  );
};

const mapStateToProps = (state: { attached_to: string }) => ({
  attached_to: state.attached_to,
});

export default connect(mapStateToProps)(AllChats);
