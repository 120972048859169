import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from 'config';

//redux
import { connect } from 'react-redux';
import * as actions from 'store/actions'
//Service
import { GetNbResquestInterventionClient, GetRelanceByUser } from 'services';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
// context
import RelanceContext from 'contexts/RelanceContext';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
    flexGrow: 1,
    paddingTop: HEADER.MOBILE_HEIGHT,
    paddingBottom: HEADER.MOBILE_HEIGHT,
    [theme.breakpoints.up('md')]: {
        paddingTop: 0,
        paddingBottom: 0,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),
        ...(collapseClick && {
            marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
        }),
    },
}));

// ----------------------------------------------------------------------

function DashboardLayout(props) {
    const { collapseClick, isCollapse } = useCollapseDrawer();
    const isDesktop = useResponsive('up', 'md');
    const [open, setOpen] = useState(false);
    const [nbInterventionsClient, setNbInterventionsClient] = useState(0)
    const [totalDemandeSite, setTotalDemandeSite] = useState(0)
    const [nbOfRelance, setnbOfRelance] = useState(0)
    const [relanceByUser, setRelanceByUser] = useState({ TabToday: [], TabYesterday: [], TabBeforeYesterday: [], TabRest: [] })
    const [loading, setLoading] = useState(false)
    const ctx = useContext(RelanceContext)

    function sortTabRelance(tabRelance) {
        const today = new Date()
        const Tab = { TabToday: [], TabYesterday: [], TabBeforeYesterday: [], TabRest: [] }
        tabRelance.map((item) => {
            if (new Date(item.time).getDate() === today.getDate() && new Date(item.time).getMonth() === today.getMonth() && new Date(item.time).getFullYear() === today.getFullYear()) {
                Tab.TabToday.push(item)
            } else if (new Date(item.time).getDate() === today.getDate() - 1 && new Date(item.time).getMonth() === today.getMonth() && new Date(item.time).getFullYear() === today.getFullYear()) {
                Tab.TabYesterday.push(item)
            } else if (new Date(item.time).getDate() === today.getDate() - 2 && new Date(item.time).getMonth() === today.getMonth() && new Date(item.time).getFullYear() === today.getFullYear()) {
                Tab.TabBeforeYesterday.push(item)
            } else {
                Tab.TabRest.push(item)
            }
        })
        setnbOfRelance(tabRelance.length)
        setLoading(true)
        setRelanceByUser(Tab)
    }

    useEffect(() => {
        Promise.all([
          GetNbResquestInterventionClient(props.attached_to),
          GetRelanceByUser(),
          axios.get('/legacy/allDemandeSite', { withCredentials: true })
        ])
          .then(([
            responseGetNbInterventionClient,
            responseGetRelanceByUser,
            responseAllDemandeSite
          ]) => {
            sortTabRelance(responseGetRelanceByUser.comments);
            setNbInterventionsClient(responseGetNbInterventionClient.interventions);
            
            const demandesNonValidees = responseAllDemandeSite.data.demande_sites.filter(demande => demande.statut !== "VALIDÉ");
            setTotalDemandeSite(demandesNonValidees.length);
          })
          .catch(err => {
            console.log(err);
          });
      }, [ctx.isAddRelance, ctx.isTreatRelance]);

    return (
        <Box
            sx={{
                display: { md: 'flex' },
                minHeight: { md: 1 },
            }}
        >
            {!isDesktop && (
                <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
            )}

            <NavbarVertical userInformation={props} sortTabRelance={sortTabRelance} onAccountInfortion={props} nbIntervention={nbInterventionsClient} totalDemandeSite={totalDemandeSite} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} relanceInfo={{ relanceByUser, nbOfRelance, loading }} />
            <MainStyle collapseClick={collapseClick}>
                {props.children}
            </MainStyle>
        </Box>
    );
}

const mapStateToProps = state => {
    return {
        firstname: state.firstname,
        lastname: state.lastname,
        pilote: state.pilote,
        id: state.id,
        administrateur: state.administrateur,
        role: state.role,
        attached_to: state.attached_to,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
