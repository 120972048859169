import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TextField, Button } from "@mui/material";
import moment from "moment";

import FlexFooter from "components/UI/FlexFooter";
import Header from "components/UI/Header/Header.tsx";
import SelectStatusFiche from "components/UI/SelectStatusFiche";

export default function ModalEditFicheCommande({
  row,
  close,
  handleUpdateFicheCommande,
}) {
  // États locaux pour stocker les valeurs du formulaire
  const [name, setName] = useState(row?.name || "");
  const [contact, setContact] = useState(row?.contact || "");
  const [numberFiche, setNumberFiche] = useState(row?.id_fiche || "");
  const [status, setStatus] = useState(row?.etat || "");
  // Affichage au format "YYYY-MM-DD" dans le TextField
  const [dateReception, setDateReception] = useState(
    row?.date_reception ? moment(row.date_reception).format("YYYY-MM-DD") : ""
  );

  // Fonction pour valider et préparer les données avant l'envoi
  const handleUpdate = () => {
    const mDate = moment(dateReception, "YYYY-MM-DD");
    if (!mDate.isValid()) {
      console.error("Date invalide :", dateReception);
      return;
    }
    // Format complet attendu par le backend, par exemple : "2025-02-26T16:56:59.930+01:00"
    const fullISODate = mDate.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    const updatedFicheCommande = {
      ...row,
      name,
      etat: status,
      date_reception: fullISODate,
    };

    handleUpdateFicheCommande(updatedFicheCommande)
      .then(() => {
        close();
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour :", error);
      });
  };

  useEffect(() => {
    setName(row?.name || "");
    setContact(row?.contact || "");
    setNumberFiche(row?.id_fiche || "");
    setStatus(row?.etat || "");
    setDateReception(
      row?.date_reception ? moment(row.date_reception).format("YYYY-MM-DD") : ""
    );
  }, [row]);

  return (
    <>
      <Header>Édition fiche commande</Header>
      <Grid container spacing={2} padding={2}>
        <Grid xs={6}>
          <TextField
            value={dateReception}
            size="small"
            label="Date de réception"
            disabled
            onChange={(e) => setDateReception(e.target.value)}
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField 
            size="small" 
            label="Numéro de la fiche commande" 
            value={numberFiche} 
            onChange={(e) => setNumberFiche(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField 
            size="small" 
            label="Nom de la fiche commande" 
            value={name} 
            onChange={(e) => setName(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField 
            size="small" 
            label="Contact client" 
            value={contact} 
            onChange={(e) => setContact(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <SelectStatusFiche
            value={status}
            clickHandler={(newStatus) => setStatus(newStatus)}
          />
        </Grid>
      </Grid>
      <FlexFooter custompadding={3} justifyContent="center">
        <Button variant="outlined" color="error" size="small" onClick={close}>
          Annuler
        </Button>
        <Button variant="outlined" color="primary" size="small" onClick={handleUpdate}>
          Modifier
        </Button>
      </FlexFooter>
    </>
  );
}
