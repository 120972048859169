import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';

export const HeaderTitle = styled(Typography)(() => ({
    textDecoration: "underline",
    textAlign: 'center',
    flex: '0 1 auto',
}));

interface HeaderProps {
    children: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
    return (
        <Stack sx={{ p: 3 }}>
            <HeaderTitle variant="h2" color="primary">{children}</HeaderTitle>
        </Stack>
    );
}

export default Header;
