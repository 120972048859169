import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Stack,
  Checkbox,
  Typography
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from '@mui/icons-material/Save';
import Header from "components/UI/Header/Header";
import { b64toBlob } from "utils/handleFiles";
import { withSnackbar } from "notistack";
import { useSnackbar } from "notistack";
import QuantityInput from "../../components/QuantityInput";
import StockService from "services/stocksService";
import Autocomplete from "@mui/material/Autocomplete";
import ProgressButton from 'components/UI/LoadingButtonModal';
import FlexFooter from "components/UI/FlexFooter";

const stockService = new StockService();

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  zIndex: 1300,
  maxWidth: "800px",
  width: "100%",
  maxHeight: "90vh",
  overflowY: "auto",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "35px !important",
  height: "35px !important",
  textAlign: "center",
}));

const CreateFicheCommandeStock = ({ infoIntervention, user, filteredIntervenant, filter, onChangeFilter }) => {
  const [rows, setRows] = useState([{ produit: "", reference: "", quantite: 0 }]);
  const [stockOptions, setStockOptions] = useState([]);
  const [selectedSociete] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [lieu_de_stockage, setLieu_de_stockage] = useState(null);
  const [selectedStocks, setSelectedStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);

  useEffect(() => {
    stockService
      .getStockByIntervenantAndClient(lieu_de_stockage, infoIntervention.client)
      .then((response) => {
        setStockOptions(response);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des stocks :", error);
      });
  }, [lieu_de_stockage, infoIntervention.client]);

  const handleAddRow = () => {
    setRows([...rows, { produit: "", reference: "", quantite: 0 }]);
  };

  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handlePreview = async () => {
    setPreviewLoading(true);
    await handleCreateFiche(true);
    setPreviewLoading(false);
  };
  
  const handleCheckboxChange = (stock, isChecked) => {
    if (isChecked) {
      setSelectedStocks([...selectedStocks, { ...stock, quantite: 1 }]);
      setIsUpdate(true)
    } else {
      setSelectedStocks(selectedStocks.filter((item) => item.id_stock !== stock.id_stock));
    }
  };

  const handleQuantityChange = (index, value) => {
    const stockInOptions = stockOptions[index];
    const selectedStockIndex = selectedStocks.findIndex(item => item.id_stock === stockInOptions.id_stock);
    if (selectedStockIndex === -1) {
      enqueueSnackbar("Veuillez d'abord cocher l'élément avant de modifier la quantité.", {
        variant: "error",
      });
      return;
    }
    const updatedStocks = [...selectedStocks];
    updatedStocks[selectedStockIndex].quantite = value;
    setSelectedStocks(updatedStocks);
  };

  // Fonction modifiée pour être asynchrone
  const handleCreateFiche = async (preview) => {
    const produitRows = selectedStocks.map((row) => row.product);
    const referenceRows = selectedStocks.map((row) => row.reference);
    const quantiteRows = selectedStocks.map((row) => row.quantite);
    const marqueRows = selectedStocks.map((row) => row.marque);
    const typeRows = selectedStocks.map((row) => row.type);

    const ficheCommandeRequestBody = {
      pilote: user.firstname + " " + user.lastname,
      id_mastore: infoIntervention.id_mastore,
      ObjetMail: "Commande de Stock",
      infos_commande: {
        produit_rows: produitRows,
        reference_rows: referenceRows,
        quantite_rows: quantiteRows,
        marque_rows: marqueRows,
        type_rows: typeRows,
        id_stocks: selectedStocks.map((row) => row.id_stock),
        lieu_de_stockage: selectedStocks.map((row) => row.lieu_de_stockage),
      },
      SendTo: [""],
      Contact: lieu_de_stockage,
    };

    try {
      const data = await stockService.createFicheCommande(preview, ficheCommandeRequestBody);
      if (preview) {
        const blob = b64toBlob(data, "application/pdf");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        enqueueSnackbar("Fiche Commande créée avec succès", { variant: "success" });
        window.location.reload();
      }
    } catch (error) {
      console.error("Erreur lors de la création de la fiche commande :", error);
      enqueueSnackbar("Erreur lors de la création de la fiche commande", { variant: "error" });
    }
  };

  // Nouvelle fonction pour le ProgressButton
  const handleSave = async () => {
    setLoading(true);
    await handleCreateFiche(false);
    setLoading(false);
    setIsUpdate(false)
  };

  const handleMaxValueReached = () => {
    enqueueSnackbar("La quantité maximale pour ce produit est atteinte.", { variant: "warning" });
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      setLieu_de_stockage(newValue.societe);
      setIsUpdate(true)
    }
  };

  const uniqueIntervenants = Array.from(
    new Set(filteredIntervenant.map((item) => item.societe))
  )
    .filter((societe) => societe && societe.trim() !== "")
    .map((societe) => ({ societe }));

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Box>
      <Header>Fiche de commande</Header>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container padding={2} spacing={2} justifyContent={"center"}>
          <Grid item xs={6}>
            <Autocomplete
              id="lieu_de_stockage"
              value={lieu_de_stockage ? { societe: lieu_de_stockage } : null}
              onChange={handleAutocompleteChange}
              options={uniqueIntervenants}
              getOptionLabel={(option) => option.societe ?? ""}
              isOptionEqualToValue={(option, value) => option.societe === value?.societe}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Lieu de stockage"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
          {lieu_de_stockage != null ? (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Ajouter</StyledTableCell>
                    <StyledTableCell>Produit</StyledTableCell>
                    <StyledTableCell>Référence</StyledTableCell>
                    <StyledTableCell>Marque</StyledTableCell>
                    <StyledTableCell>Type</StyledTableCell>
                    <StyledTableCell sx={{ width: "15%", textAlign: "center" }}>
                      Quantité
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockOptions.map((stock, index) => (
                    <TableRow key={stock.id_stock}>
                      <StyledTableCell>
                        <Checkbox
                          checked={!!selectedStocks.find(item => item.id_stock === stock.id_stock)}
                          onChange={(event) => handleCheckboxChange(stock, event.target.checked)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{stock.product}</StyledTableCell>
                      <StyledTableCell>{stock.reference}</StyledTableCell>
                      <StyledTableCell>{stock.marque}</StyledTableCell>
                      <StyledTableCell>{stock.type}</StyledTableCell>
                      <StyledTableCell sx={{ width: "15%", textAlign: "center" }}>
                        <QuantityInput
                          value={
                            selectedStocks.find(item => item.id_stock === stock.id_stock)?.quantite || 0
                          }
                          onChange={(newValue) => handleQuantityChange(index, newValue)}
                          max={stock.quantity}
                          onMaxValueReached={handleMaxValueReached}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Stack style={{ paddingTop: "3%" }}>
              <Typography
                variant="h1"
                align="center"
                textAlign={"center"}
                color="rgba(99, 115, 129, 0.3)"
              >
                Aucun produits disponibles
              </Typography>
            </Stack>
          )}
          </Grid>
        </Grid>

        <FlexFooter custompadding={3} justifyContent="center" gap={3}>
          <LoadingButton loading={previewLoading} variant="outlined" size="small" onClick={handlePreview}>
            Prévisualiser
          </LoadingButton>
          <ProgressButton
            update={isUpdate}
            loading={loading}
            name="Créer"
            icon={<SaveIcon />}
            size="small"
            clickHandler={handleSave}
          />
        </FlexFooter>
      </form>
    </Box>
  );
};

export default withSnackbar(CreateFicheCommandeStock);
