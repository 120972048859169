import React, { Component, Suspense } from "react";
// MUI Theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

// ROUTER
import { Route, Routes } from "react-router-dom";

// NOTISTACK
import { SnackbarProvider } from "notistack";
import Collapse from "@mui/material/Collapse";

//REDUX
import { connect } from "react-redux";

//VIEWS
import Layout from "./components/Layout/Layout";
import Spinner from "./components/UI/Spinner/Spinner";
import * as actions from "./store/actions/index";
import BddUsers from "./views/BddUsers/BddUsers";
import BddIntervenant from "./views/BddIntervenant/BddIntervenant";
import InterventionContainer from "./views/Intervention/InterventionContainer";
import ModuleDevisContainer from "./views/ModuleDevis/ModuleDevisContainer";
import InterventionRequestContainer from "./views/InterventionRequest/InterventionRequestContainer";
import FicheIntevervention from "views/FicheIntervention/FicheIntevervention";
import ValidationFicheIntervention from "views/FicheIntervention/ValidationFicheIntervention";
import { ConfirmDialogProvider } from "hooks/DialogProvider";
// DATE
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TodoListContextProvider } from "contexts/TodoListContext";
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");


const Auth = React.lazy(() => import("./views/Auth/Auth"));
const FactureContainer = React.lazy(() =>
  import("./views/Facture/FactureContainer")
);
const Archives = React.lazy(() => import("./views/Archives/Archives"));

const BddSites = React.lazy(() => import("./views/BddSites/BddSites"));
const BddClients = React.lazy(() => import("./views/BddClients/BddClients"));
const ParametreView = React.lazy(() => import("./views/Parametres"));
const EvictumManagement = React.lazy(() =>
  import("./views/EvictumManagement/EvictumUsers")
);
const DevisContainer = React.lazy(() => import('./views/Devis/DevisContainer'));
const ModulesDevisSettingsContainer = React.lazy(() =>
  import(
    "./views/ModuleDevis/ModuleDevisSettings/ModulesDevisSettingsContainer"
  )
);
const Stocks = React.lazy(() => import("./views/Stocks/StockContainer"));
const Performance = React.lazy(() => import("./views/Performance/PerformanceContainer"));
//const Stats = React.lazy(() => import("./views/Statistique/StatsContainer"));
const Statistique = React.lazy(() => import("./views/Statistique/StatistiqueContainer"));
const Preventive = React.lazy(() => import("./views/Preventive/PreventiveContainer"));
const Corbeille = React.lazy(() => import("./views/Corbeille/CorbeilleContainer"));
const Corrective = React.lazy(() => import("./views/TableauInterventions/CorrectiveContainer"));

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = <Spinner />;

    if (this.props.loading === false) {
      if (this.props.isAuthentificated === false) {
        const authPaths = [
          "/",
          "/evictum-users",
          "/bdd-sites",
          "/facture",
          "/statistique",
          "/bdd-utilisateurs",
          "/bdd-clients",
          "/intervention",
          "/preventive",
          "/bdd-artisans",
          "/archives",
          "/mon-compte",
          "/module-devis",
          "/parametres",
          "/stocks",
          "/devis",
          "/protected-admin-performance",
          "/tableau-preventive",
          "/corbeille",
        ];

        const authRoutes = authPaths.map((path) => (
          <Route key={path} path={path} element={<Auth />} />
        ));

        routes = (
          <React.Fragment>
            <Routes>
              {authRoutes}
              <Route
                path="/ficheIntervention"
                element={<FicheIntevervention />}
              />
              <Route
                path="/validation_fiche_intervention"
                element={<ValidationFicheIntervention />}
              />
            </Routes>
          </React.Fragment>
        );
      }
      if (this.props.isAuthentificated === true) {
        routes = (
          <Layout>
            <Routes>
              <Route path="/evictum-users" element={<EvictumManagement />} />
              <Route path="/" element={<Corrective />} />
              <Route path="/bdd-sites" element={<BddSites />} />
              <Route path="/bdd-utilisateurs" element={<BddUsers />} />
              <Route path="/bdd-clients" element={<BddClients />} />
              <Route path="/intervention" element={<InterventionContainer />} />
              <Route path="/facture" element={<FactureContainer />} />
             
              <Route path="/bdd-artisans" element={<BddIntervenant />} />
              <Route path="/archives" element={<Archives />} />
              <Route path="/parametres" element={<ParametreView />} />
              <Route path="/module-devis" element={<ModuleDevisContainer />} />
              <Route path="/devis" element={<DevisContainer />} />
              <Route
                path="/demande-intervention-client"
                element={<InterventionRequestContainer />}
              />
              <Route
                path="/module-devis/module-details/:id"
                element={<ModulesDevisSettingsContainer />}
              />

              <Route path="/stocks" element={<Stocks />} />
              <Route path="/protected-admin-performance" element={<Performance />} />
              <Route path="/statistique" element={<Statistique />} />
              <Route path="/tableau-preventive" element={<Preventive />} />
              <Route path="/corbeille" element={<Corbeille />} />
            </Routes>
          </Layout>
        );
      }
    }

    return (
      <ThemeConfig>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            TransitionComponent={Collapse}
          >
            <TodoListContextProvider>
              <GlobalStyles />
              <ConfirmDialogProvider>
                <Suspense fallback={<Spinner />}>{routes}</Suspense>
              </ConfirmDialogProvider>
            </TodoListContextProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeConfig>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthentificated: state.isAuthentificated,
    loading: state.loading,
    error: state.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
