import * as React from 'react';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import {
    Typography,
    Button,
    IconButton,
    Stack,
    List,
    ListItem,
    ListItemIcon,
    Grid,
    ListItemText,
    Box,
    Divider,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import Iconify from "components/UI/Iconify";
import SlideImage from "components/UI/SlideImage"
import { filter } from "lodash";
import { openFile } from "utils/handleFiles";

export default function DetailModal({ intervention }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const title = `${intervention.enseigne} ${intervention.ville} ${intervention.code_postal} [N˚${intervention.numero_magasin}]`
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const pdf = filter(intervention.pdf, { "type": "pdf" })

    return (
        <Box>
            <IconButton onClick={() => handleClickOpen()} style={{ color: "#1890FF" }}>
                <Iconify icon="ant-design:file-search-outlined" />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "700px",
                        },
                    },
                }}
            >
                <DialogTitle style={{ backgroundColor: "#d1e9fc" }} id="alert-dialog-title">
                <Stack direction="row" spacing={1} justifyContent={"center"} alignItems="center">
                    <Iconify icon="gis:location-poi-o" sx={{ width: 25, height: 25 }} />
                    <Stack direction="column">
                        <Typography textAlign={"center"} variant="h6">{title}</Typography>
                        <Typography textAlign={"center"} variant="body2">
                        {intervention.adresse} - {intervention.ville} - {intervention.code_postal}
                        </Typography>
                    </Stack>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Stack xs={12} spacing={2}>
                        <Stack spacing={2}>
                            <Typography variant="h6">Objet :</Typography>
                            <Box style={{ whiteSpace: "pre-line" }}>
                                {intervention.objet}
                            </Box>
                        </Stack>
                        <Typography variant="h6">Prestation à réaliser :</Typography>
                        <Box style={{ whiteSpace: "pre-line" }}>
                            {intervention.initial_request}
                        </Box>
                        <Typography variant="h6">Photo(s) / Vidéo(s)  :</Typography>
                        <Stack justifyContent="center" alignItems="center">
                            {intervention.photo.length > 0 || intervention.video.length > 0 ? (
                                <SlideImage media={[...filter(intervention.photo, { type: "image" }), ...filter(intervention.video, { type: "video" })]} />
                            ) : (
                                <Typography align="center" variant="h4" color={alpha(theme.palette.text.secondary, 0.20)}>
                                    Aucun média disponible
                                </Typography>
                            )}
                        </Stack>
                        {intervention.pdf.length > 0 ?
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle1">Document(s):</Typography>
                                <Stack style={{ overflow: "scroll", maxHeight: "180px", overflowX: "hidden" }}>
                                    <List>
                                        {pdf.map((item, index) => (
                                            <div key={index}>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Button variant="text" fullWidth onClick={() => openFile(item.file, 'application/pdf')}>
                                                            <Iconify icon={'ant-design:folder-open-twotone'} sx={{ fontSize: "25px" }} />
                                                            {item.name}
                                                        </Button>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                    />
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))}
                                    </List>
                                </Stack>
                            </Grid>
                            : null
                        }
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}
