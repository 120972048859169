import axios from 'axios'
import {
    GET_GROUPED_PILOTE,
    GET_REVENUES_PILOTES_BY_YEAR,
    GET_GROUPED_INTERVENTION,
    GET_NBR_INTERVENTION_BY_SPECIALITE,
    GET_REVENUES_CLIENTS_BY_SPECIALITE,
    GET_STATISTIQUE_INTERVENTION_COUNT,
    GET_STATISTIQUE_REVENUS_PER_SPECIALITY,
    GET_STATISTIQUE_SPECIALITY_COUNT,
    GET_STATISTIQUE_REVENUS_TOTAL,
    GET_STATISTIQUE_REVENUS_PER_MONTH,
    GET_10_SPENDINGS_STORE,
    GET_10_INTERVENTION_STORE,
    GET_5_SPECIALITY,
    GET_AVERAGE_COST_PER_INTERVENTION,
    GET_STATISTIQUE_AVERAGE_COST_PER_MONTH,
    GET_TOP10_INTERVENANTS,
    GET_TOP_CLIENTS,
    GET_COUNT_INTERVENTION,
    GET_COUT_FORECAST_SAV,
    GET_COUT_REEL_SAV,
    GET_STATS_CLIENTS,
    GET_ENSEIGNE_BY_CLIENT,
    GET_STATS_MONTHS_CLIENTS
} from "./CONSTANTS";

export const GetRevenusPilotesByYear = (year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_REVENUES_PILOTES_BY_YEAR(year))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetRevenusPilotesByYear > axios err=", err);
                    reject("Error in GetRevenusPilotesByYear axios!");
                });
        } catch (error) {
            console.error("in statisticService > GetRevenusPilotesByYear, Err===", error);
        }
    });
};

export const GetPiloteGroupe = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_GROUPED_PILOTE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                console.log("GetRevenusPilotesByYear > axios err=", err);
                reject("Error in GetRevenusPilotesByYear axios!");
            });
        } catch (error) {
            console.error("in statisticService > GetRevenusPilotesByYear, Err===", error);
        }
    });
};

export const GetNombreInterventionGroupes = (params) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios.get(GET_GROUPED_INTERVENTION(), { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("GetNombreInterventionGroupes > axios error:", err);
                    reject("Error in GetNombreInterventionGroupes axios!");
                });
        } catch (error) {
            console.error("Error in GetNombreInterventionGroupes, Err===", error);
            reject("Error in GetNombreInterventionGroupes!");
        }
    });
};

export const GetNombreInterventionBySpec = (params) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios.get(GET_NBR_INTERVENTION_BY_SPECIALITE(), { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("GetNombreInterventionByClients > axios error:", err);
                    reject("Error in GetNombreInterventionByClients axios!");
                });
        } catch (error) {
            console.error("Error in GetNombreInterventionByClients, Err===", error);
            reject("Error in GetNombreInterventionByClients!");
        }
    });

}

export const GetRevenuesClientsBySpecialite = (params) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios.get(GET_REVENUES_CLIENTS_BY_SPECIALITE(), { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    console.log("GetRevenuesClientsBySpecialite > axios error:", err);
                    reject("Error in GetRevenuesClientsBySpecialite axios!");
                });
        } catch (error) {
            console.error("Error in GetRevenuesClientsBySpecialite, Err===", error);
            reject("Error in GetRevenuesClientsBySpecialite!");
        }
    });
}


export const GetStatistiqueInterventionCount = (
    client,
    date,
    enseigne,
     type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(
            GET_STATISTIQUE_INTERVENTION_COUNT(client, date, enseigne, type)
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get statistique intervention count axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get statistique intervention count axios!",
          error
        );
      }
    });
  };
  
  export const GetStatistiqueRevenuesPerSpeciality = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(
            GET_STATISTIQUE_REVENUS_PER_SPECIALITY(
              client,
              date,
              enseigne,
              type
            )
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get statistique revenues per speciality axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get statistique revenues per speciality axios!",
          error
        );
      }
    });
  };
  
  export const GetStatistiqueSpecialityCount = (
    client,
    date,
    enseigne,
    type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_STATISTIQUE_SPECIALITY_COUNT(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get statistique speciality count axios!");
            console.error(err);
          });
      } catch (error) {
        console.error("Error in get statistique speciality count axios!", error);
      }
    });
  };
  
  export const GetStatistiqueTotal = (client, date, enseigne, type) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_STATISTIQUE_REVENUS_TOTAL(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite revenus total axios!");
            console.error(err);
          });
      } catch (error) {
        console.error("Error in get specialite revenus total axios!", error);
      }
    });
  };
  
  export const GetStatistiqueRevenuesPerMonth = (
    client,
    date,
    enseigne,
    type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_STATISTIQUE_REVENUS_PER_MONTH(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite revenus per month axios!");
            console.error(err);
          });
      } catch (error) {
        console.error("Error in get specialite revenus per month axios!", error);
      }
    });
  };
  
  export const GetStatistiqueTop10SpendingStore = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_10_SPENDINGS_STORE(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite top 10 spending store axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get specialite top 10 spending store axios!",
          error
        );
      }
    });
  };
  
  export const GetStatistiqueTop10InterventionStore = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_10_INTERVENTION_STORE(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite top 10 intervention store axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get specialite top 10 intervention store axios!",
          error
        );
      }
    });
  };
  
  export const GetStatistiqueTop5Speciality = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_5_SPECIALITY(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite top 5 speciality axios!");
            console.error(err);
          });
      } catch (error) {
        console.error("Error in get specialite top 5 speciality axios!", error);
      }
    });
  };
  
  export const GetStatistiqueAverageCostPerIntervention = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_AVERAGE_COST_PER_INTERVENTION(client, date, enseigne, type))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(
              "Error in get specialite average cost per intervention axios!"
            );
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get specialite average cost per intervention axios!",
          error
        );
      }
    });
  };
  
  export const GetStatistiqueAverageCostPerMonth = (
    client,
    date,
    enseigne, type
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(
            GET_STATISTIQUE_AVERAGE_COST_PER_MONTH(
              client,
              date,
              enseigne, type
            )
          )
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite average cost per month axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get specialite average cost per month axios!",
          error
        );
      }
    });
  };
  
  export const GetTop10Intervenants = (
    date,
    specialite,
    userID
  ) => {
    return new Promise((resolve, reject) => {
      try {
        axios.defaults.withCredentials = true;
        axios
          .get(GET_TOP10_INTERVENANTS(date, specialite, userID))
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject("Error in get specialite top 10 intervenants axios!");
            console.error(err);
          });
      } catch (error) {
        console.error(
          "Error in get specialite top 10 intervenants axios!",
          error
        );
      }
    });
  }

export const GetTopClients = (year, type, userID) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_TOP_CLIENTS(year, type, userID))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get top clients axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get top clients axios!", error);
        }
    });
};

export const GetCountInterventions = (year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_COUNT_INTERVENTION(year))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get count interventions axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get count interventions axios!", error);
        }
    });
}

export const GetCoutForecastSav = (year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_COUT_FORECAST_SAV(year))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get count interventions axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get count interventions axios!", error);
        }
    });
}

export const GetCoutReelSav = (year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_COUT_REEL_SAV(year))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get count interventions axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get count interventions axios!", error);
        }
    });
}

export const GetStatsClients = (client, year) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_STATS_CLIENTS(client, year))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get count interventions axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get count interventions axios!", error);
        }
    });
}

export const GetEnseigneByClient = (client) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true;
            axios
                .get(GET_ENSEIGNE_BY_CLIENT(client))
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject("Error in get count interventions axios!");
                    console.error(err);
                });
        } catch (error) {
            console.error("Error in get count interventions axios!", error);
        }
    });
}

export const GetStatsMonthsClients = (year, enseigne, tye) => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .get(GET_STATS_MONTHS_CLIENTS(year, enseigne, tye))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject("Error in get count interventions axios!");
          console.error(err);
        });
    } catch (error) {
      console.error("Error in get count interventions axios!", error);
    }
  }
  );
}

