import { connect } from 'react-redux';
import React, { useState, useEffect, FC } from 'react';

// mui
import Stack from '@mui/material/Stack';

// components
import Page from 'components/UI/Page';
import TableUsers from 'components/BddUsers/TableUsers/TableUsers';

// services 
import { GetAllUsers } from 'services';

// lib
import { useSnackbar } from 'notistack';

interface BddUsersProps {
  firstname: string;
  lastname: string;
  administrateur: boolean;
}

interface GetAllUsersResponse {
  users: any[];
}

const BddUsers: FC<BddUsersProps> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any[]>([]);
  const [dateUpdate, setDataUpdate] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([GetAllUsers()])
      .then(([responseGetUsers]) => {
        const res = responseGetUsers as GetAllUsersResponse;
        setData(res.users);
      })
      .catch((err) => {
        enqueueSnackbar("Erreur lors de la récuperation des utilisateurs", { variant: 'error' });
        console.error(err);
      });
    setDataUpdate(false);
  }, [dateUpdate, enqueueSnackbar]);

  return (
    <Page title="Compte Utilisateurs">
      <Stack sx={{ p: 0.5 }} spacing={0.5}>
        <TableUsers 
            data={data}
            setDataUpdate={setDataUpdate}
            administrateur={props.administrateur} 
            change={undefined}
        />
      </Stack>
    </Page>
  );
};

const mapStateToProps = (state: any) => ({
  firstname: state.firstname,
  lastname: state.lastname,
  administrateur: state.administrateur,
});

export default connect(mapStateToProps)(BddUsers);
