import React, { useState } from 'react';
import {
    Button,
    Dialog,
    Stack,
    TablePagination,
    TableContainer,
    TableBody,
    Table,
    Paper
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { StyledTableRow, StyledTableCell } from './TableUsersStyle';
//components
import EnhancedTableHead from './TableUsersHead'
import FormUser from 'views/BddUsers/components/CreateUserForm';
import EditUserForm from 'views/BddUsers/components/EditUserForm';
import CSVextraction from 'components/UI/CSVextraction';


const TABLE_HEADER = [
    { label: 'Prénom', id: 'firstname', width: 100, filter: "" },
    { label: 'Nom', id: 'lastname', width: 100, filter: "" },
    { label: 'ID utilisateur', id: 'userID', width: 100, filter: "" },
    { label: 'Nom d\'utilisateur', id: 'username', width: 100, filter: "" },
    { label: 'Mail', id: 'mail', width: 100, filter: "" },
    { label: 'Telephone', id: 'telephone', width: 100, filter: "" },
    { label: 'Rôle', id: 'role', width: 50, filter: "" },
    { label: 'Pilote', id: 'pilote', width: 50, filter: "" },
    { label: 'Administrateur', id: 'administrateur', width: 40, filter: "" },
    { label: 'BDD' , id: 'validation_bdd', width: 50, filter: "" },
    { label: 'Assigné à', id: 'attached_to', width: 50, filter: "" },
    { label: 'Société', id: 'entreprise', width: 50, filter: "" },
    { label: 'Actif', id: 'is_active', width: 50, filter: "" },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function Pilote(column) {
    const state = column.value;
    if (state === true) {
        return (
            <StyledTableCell key={column.id} align={column.columns.align} style={{ width: column.columns.width }}>
                <CheckIcon style={{ color: "green" }} />
            </StyledTableCell>
        )
    }
    return (
        <StyledTableCell key={column.id} align={column.columns.align} style={{ width: column.columns.width }}>
            <CloseIcon style={{ color: "red" }} />
        </StyledTableCell>
    )
}

const Tables = ({ administrateur, data, setDataUpdate, change }) => {
    const [rows, setRows] = useState([...data]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [orderBy, setOrderBy] = useState('ID');
    const [order, setOrder] = useState('asc');
    const [open, setOpen] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [tableCells, setTableCells] = useState([...TABLE_HEADER])

    

    React.useEffect(() => {
        let currentList = [...data]
        tableCells.map((filter) => {
            if (filter.filter !== "") {
                currentList = currentList.filter(item => {
                    if (filter.id === "pilote" || filter.id === "administrateur" || filter.id === "is_active" || filter.id === "validation_bdd") {
                        if (filter.filter === "oui") {
                            return item[filter.id] === true
                        }
                        if (filter.filter === "non") {
                            return item[filter.id] === false
                        }
                    } else {
                        return item[filter.id].toLowerCase().includes(filter.filter.toLowerCase());
                    }
                    return null
                });
            }
            return null
        })
        setRows(currentList)
    }, [tableCells, data])



    const handleClickOpen = (row) => {
        if (administrateur) {
            setUserInfo(row)
            setOpen(true);
        }
    };

    const changeInputFilter = (event) => {
        let newTableCells = [...tableCells]
        const index = tableCells.map(item => item.id).indexOf(event.target.name)
        newTableCells[index] = { ...tableCells[index] }
        newTableCells[index].filter = event.target.value
        setTableCells(newTableCells)
    }

    const handleClose = (row) => {
        setOpen(false);
        setUserInfo(row);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const LinkMember = (object) => {
        let index = rows.map(row => row.ID).indexOf(object.attached_to);
        if (index === -1) {
            return (null)
        } else {
            return (
                rows[index].firstname + " " + rows[index].lastname
            )
        }
    }

    return (
        <Paper >
            <TableContainer sx={{ height: "calc(100vh - 6vh)" }}>
                <Table stickyHeader aria-label="sticky table" size={'medium'}>
                    <EnhancedTableHead
                        tableCells={tableCells}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        changeInputFilter={changeInputFilter}
                        change={change}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(row => {
                                return (
                                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.ID} onClick={() => { handleClickOpen(row); setEditUser(true) }}>
                                        {tableCells.map(column => {
                                            const value = row[column.id];
                                            return (
                                                <React.Fragment key={column.id}>
                                                    {column.id === "pilote" || column.id === "administrateur" || column.id === "is_active" || column.id === "validation_bdd"? <Pilote columns={column} value={value} id={row.IDMastore} /> :
                                                        <StyledTableCell align={column.align} style={{ width: column.width }}>
                                                            {column.format && typeof value === 'number' ? column.format(value) : column.id === "attached_to" ? <LinkMember attached_to={row.attached_to} /> : value}
                                                        </StyledTableCell>
                                                    }
                                                </React.Fragment>
                                            );
                                        })}
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer style={{ display: "flex", bottom: "0px", paddingLeft: "10px" }}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mr: 'auto' }}>
                    <Button
                        style={administrateur !== true ? { display: "none" } : { display: "block" }}
                        variant="outlined"
                        onClick={() => { handleClickOpen({}); setEditUser(false) }}
                        size='small'
                    >
                        Ajouter un utilisateur
                    </Button>
                    <CSVextraction tableColumns={tableCells} data={rows} />
                </Stack>
                <TablePagination
                    sx={{ height: "5vh", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden",}}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    style={{ height: "5vh", display: "flex" }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="false"
            >
                {
                    editUser ? <EditUserForm handleClose={handleClose} setDataUpdate={setDataUpdate} user={userInfo} />
                        : <FormUser handleClose={handleClose} setDataUpdate={setDataUpdate} />
                }
            </Dialog>
        </Paper>
    );
}

export default Tables;