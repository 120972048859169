import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

export const SaveButton = styled(Button)(() => ({
    height: '100%',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",

}))

export const DivButton = styled(Stack)(() => ({
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
}))

export const IconCreate = styled(CreateIcon)(({theme}) => ({
    [theme.breakpoints.down('xl')]: {
        fontSize: "20px",
    },
    [theme.breakpoints.down(undefined)]: {
        fontSize: "18px",
    }
}))