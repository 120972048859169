import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

interface MultiSpecialite {
  parametre1: string;
}

interface MultiSpecialiteProps {
  disabled: boolean;
  value: string | string[];
  name: string;
  changeSpecialite: (event: { name: string; value: string[] }) => void;
}

const MultiSpecialite: React.FC<MultiSpecialiteProps> = ({
  disabled,
  value,
  name,
  changeSpecialite,
}) => {
  const [specialites, setSpecialite] = useState<MultiSpecialite[]>([]);
  const [selectedSpecialites, setSelectedSpecialites] = useState<string[]>([]);

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get('legacy/parametre?attribut=Spécialité').then((response) => {
      setSpecialite(response.data.parametres);
    });
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedSpecialites(Array.isArray(value) ? value : [value]);
    }
  }, [value]);

  const handleChange = (event: SelectChangeEvent<string[] | string>) => {
    const {
      target: { value },
    } = event;
    const valueToSet = typeof value === 'string' ? value.split(',') : value;
    setSelectedSpecialites(valueToSet);
    changeSpecialite({ name: 'specialite', value: valueToSet });
  };

  return (
    <FormControl fullWidth size="small" variant="outlined">
      <Select
        multiple
        fullWidth
        name={name}
        disabled={disabled}
        label="Spécialité"
        value={selectedSpecialites.length > 0 ? selectedSpecialites : []}
        onChange={handleChange}
        required
        renderValue={(selected: string[]) =>
          selected.length > 0
            ? `${selected[0]}${selected.length > 1 ? `, ${selected[1]}` : ''}${
                selected.length > 2 ? ', ...' : ''
              }`
            : 'Sélectionnez une ou plusieurs spécialités'
        }
        input={
          <OutlinedInput
            id="specialite-label"
            endAdornment={
              selectedSpecialites.length > 2 ? (
                <InputAdornment sx={{ mr: 2 }} position="end">
                  +{selectedSpecialites.length - 2}
                </InputAdornment>
              ) : null
            }
          />
        }
        displayEmpty
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 450,
            },
          },
        }}
      >
        <MenuItem disabled value="Sélectionner une spécialité">
          Sélectionnez une ou plusieurs spécialités
        </MenuItem>
        {specialites.map((specialite) => (
          <MenuItem key={specialite.parametre1} value={specialite.parametre1}>
            <Checkbox
              checked={selectedSpecialites.includes(specialite.parametre1)}
              sx={{ marginRight: 2 }}
            />
            <ListItemText primary={specialite.parametre1} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSpecialite;
