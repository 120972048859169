import React, { useEffect, useState } from "react";
import { Button, Box, Stack, Typography } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import Header from "components/UI/Header/Header";
import TableCommandeStock from "./TableCommandeStock";
import Alert from "@mui/material/Alert";
import CreateFicheCommandeStock from "./CreateFicheCommandeStock";
import axios from "axios";
import Modal from "components/UI/Modal";
import Mailbox from "views/Mail/Mail.tsx";
import ModalEditFicheCommande from "./ModalEditFicheCommande";
import FlexFooter from "components/UI/FlexFooter";
import ProgressButton from 'components/UI/LoadingButtonModal';
import StockService from "services/stocksService";

const stockService = new StockService();

export default function CommandeStocks({ infoIntervention, user, actions }) {
  const [openCreateFicheCommandeStock, setOpenCreateFicheCommandeStock] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ficheCommandes, setFicheCommandes] = useState([]);
  const [attachment] = useState([]);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  // Nouvel état pour les suppressions en attente
  const [pendingDeletions, setPendingDeletions] = useState([]);

  const [sendMailBool, setSendMailBool] = useState(false);
  const [filteredMail, setFilteredMail] = React.useState([]);
  const [alert, setAlert] = useState(null);
  const [filteredIntervenant, setFilteredIntervenant] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [filter, setFilter] = React.useState({
    societe: null,
    specialite: null,
    ville: null,
    distance: 50,
  });

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("/legacy/GetIntervenants").then((response) => {
      const emailArray = response.data.artisan.map((artisan) => artisan.mail).flat();
      setFilteredMail(emailArray);
      setFilteredIntervenant(response.data.artisan);
    });
  }, []);

  const handleCreateFicheCommandeStockModal = () => {
    setOpenCreateFicheCommandeStock(!openCreateFicheCommandeStock);
  };

  // Fonction qui ajoute l'ID de la fiche à supprimer dans pendingDeletions
  const addPendingDeletion = (idFiche) => {
    // Ajout à l'état des suppressions en attente
    setPendingDeletions((prev) => [...prev, idFiche]);
    // Retirer immédiatement la fiche de la liste pour l'affichage
    setFicheCommandes((prevFiches) =>
      prevFiches.filter((fiche) => fiche.id_fiche !== idFiche)
    );
    setUpdate(true);
  };
  
  // Fonction qui va enregistrer les suppressions en attente
  const handleSaveDeletions = async () => {
    setLoading(true);
    try {
      for (let idFiche of pendingDeletions) {
        await stockService.deleteFicheCommande(idFiche);
      }
      // Rafraîchir la liste après suppression
      const data = await stockService.getFicheCommande(infoIntervention.id_mastore);
      setFicheCommandes(data);
      setPendingDeletions([]);
      setUpdate(false);
      enqueueSnackbar("Suppression enregistrée avec succès", { variant: "success" });
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
      enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
    }
    setLoading(false);
  };

  const handleEditFicheCommande = (row) => {
    setRowToEdit(row);
    setOpenEditModal(true);
  };

  const handleUpdateFicheCommande = async (updatedFicheCommande) => {
    try {
      await stockService.putFicheCommande(updatedFicheCommande);
      setOpenEditModal(false);
      enqueueSnackbar("Fiche de commande mise à jour avec succès.", { variant: "success" });
      stockService
        .getFicheCommande(infoIntervention.id_mastore)
        .then((data) => setFicheCommandes(data))
        .catch((error) => {
          enqueueSnackbar("Erreur lors de la récupération des données.", { variant: "error" });
        });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la fiche de commande : ", error);
      enqueueSnackbar("Erreur lors de la mise à jour de la fiche de commande.", { variant: "error" });
    }
  };

  useEffect(() => {
    stockService
      .getFicheCommande(infoIntervention.id_mastore)
      .then((data) => {
        setFicheCommandes(data);
      })
      .catch((error) => {
        enqueueSnackbar("There was an error fetching the data!", { variant: "error" });
        console.error(error);
      });
  }, [enqueueSnackbar, infoIntervention.id_mastore]);

  const stateModalSendMail = (event, row) => {
    if (row.file) {
      setAlert(
        <Alert
          style={{
            margin: "auto",
            marginBottom: "20px",
            border: "1px solid",
            width: "50%",
          }}
          severity="warning"
        >
          Sauvegarder la demande de devis et l'intervention pour pouvoir envoyer le mail !
        </Alert>
      );
    } else {
      setSendMailBool(true);
      setAlert(null);
    }
  };

  const onChangeFilter = (value, key) => {
    setFilter({ ...filter, [key]: value });
  };

  const handleClose = () => {
    setSendMailBool(false);
  };

  const objectMail = `Fiche de commande - Enseigne : ${infoIntervention.enseigne} N°${infoIntervention.numero_magasin} - DI Mastore : ${infoIntervention.id_mastore} - DI Client : ${infoIntervention.id_client} - ${infoIntervention.ville} [${infoIntervention.code_postal}] - ${infoIntervention.specialite}`;

  return (
    <Box
      sx={{
        width: { xl: "65vw", md: "70vw", sm: "70vw", xs: "95vw" },
        maxHeight: "80vh",
        padding: "5px",
      }}
    >
      <Header>Commandes Stocks</Header>
      {alert}
      <Stack sx={{ px: 3, mb: 2, overflow: "auto" }} spacing={1}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCreateFicheCommandeStockModal}
            startIcon={<CreateIcon />}
            disabled={user.role === "Assistant(e)" || user.role === "Utilisateur"}
          >
            Créer une fiche commande
          </Button>
        </Stack>
        <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {ficheCommandes.length > 0 ? (
            <TableCommandeStock
              data={ficheCommandes}
              infoIntervention={infoIntervention}
              deleteCommandeStock={addPendingDeletion}
              mailInter={filteredMail}
              actions={actions}
              modalSendMail={stateModalSendMail}
              handleEditFicheCommande={handleEditFicheCommande}
              user={user}
            />
          ) : (
            <Typography
              variant="h1"
              align="center"
              textAlign={"center"}
              color="rgba(99, 115, 129, 0.3)"
            >
              Aucune fiches de commande
            </Typography>
          )}
        </Stack>
        <Modal
          open={openCreateFicheCommandeStock}
          close={handleCreateFicheCommandeStockModal}
          sx={{
            width: { xl: "45vw", md: "70vw", sm: "70vw", xs: "95vw" },
            maxHeight: "80vh",
          }}
        >
          <CreateFicheCommandeStock
            infoIntervention={infoIntervention}
            filteredIntervenant={filteredIntervenant}
            filter={filter}
            user={user}
          />
        </Modal>
        <Modal open={sendMailBool} close={handleClose}>
          <Mailbox
            objectMail={objectMail}
            Attachment={attachment}
            modalClosed={handleClose}
            destinataire={actions}
            infoIntervention={infoIntervention}
            attribut="Fiche Commande"
            mailuser={user}
            sendto="intervenant"
          />
        </Modal>
        <Modal
          sx={{
            width: { xl: "35vw", md: "45vw", sm: "50vw", xs: "55vw" },
            maxHeight: "70vh",
          }}
          open={openEditModal}
          close={() => setOpenEditModal(false)}
        >
          <ModalEditFicheCommande
            row={rowToEdit}
            handleUpdateFicheCommande={handleUpdateFicheCommande}
            close={() => setOpenEditModal(false)}
            infoIntervention={infoIntervention}
          />
        </Modal>
      </Stack>
      <FlexFooter custompadding={3}>
        <ProgressButton
          update={update}
          loading={loading}
          name="Sauvegarder"
          icon={<SaveIcon />}
          clickHandler={handleSaveDeletions}
        />
      </FlexFooter>
    </Box>
  );
}
