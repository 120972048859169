import React, { useState, useCallback } from 'react';
// material
import {
    Card,
    Stack,
    Grid,
    Typography,
    Tooltip
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
//components
import Contact from './components/contact';
import DemandeInitial from './components/DemandeInitial'
import Modal from "components/UI/Modal"
import ModalButton from 'components/UI/ModalButton';
import { useConfirmDialog } from 'hooks/DialogProvider'; // Move this to the top

const BonDeCommande = React.lazy(() => import('./modal/BonDeCommande'));
const DevisClient = React.lazy(() => import('./modal/DevisClient/DevisClient'));
const RapportClient = React.lazy(() => import('./modal/RapportClient/RapportClient'));
const FactureClient = React.lazy(() => import('./modal/FactureClient/FactureClient'));

export const ClientView = (
    { clientInfo, infoIntervention, setClientInfo, user, dataFacturation, actions, fileDemandeInitial, updateInfoIntervention }
) => {
    const [openFacture, setOpenFacture] = useState(false);
    const [openDevis, setOpenDevis] = useState(false);
    const [openBonDeCommande, setOpenBonDeCommande] = useState(false);
    const [openRapport, setOpenRapport] = useState(false);
    const [isDevisSaved, setIsDevisSaved] = useState(false); // Suivre si le devis est sauvegardé ou non
    const { openDialog } = useConfirmDialog(); // Hook pour la confirmation


    const handleDevisClose = useCallback(() => {
        if (!isDevisSaved) { // Ajoutez hasChanges à la condition
            openDialog(
                "Êtes-vous sûr de vouloir fermer sans sauvegarder ?",
                () => {
                    setOpenDevis(false);
                },
                () => {}  // Si non, ne rien faire
            );
        } else {
            setOpenDevis(false); // Fermez directement si tout est sauvegardé
        }
    }, [isDevisSaved, openDialog]);


    return (
        <Card variant="outlined" sx={{ p: 1, display: 'flex', flexDirection: 'column', flex: '1 1' }}>
            <Grid container>
                <Contact contact={clientInfo.contact} infoIntervention={infoIntervention} />
                <Grid item xs={5}>
                    <Typography variant="h6" style={{ color: "#316690" }}>Information(s) de la demande :</Typography>
                    <DemandeInitial fileDemandeInitial={fileDemandeInitial} />
                </Grid>
            </Grid>
            <Stack direction="row" justifyContent="space-around" sx={{ mt: 'auto' }}>
                <Tooltip
                    title={
                        infoIntervention.sav
                            ? "Le bouton est bloqué car l'intervention est en mode SAV."
                            : ""
                    }
                    arrow
                >
                    {/* L'élément span est nécessaire pour que le tooltip s'affiche sur un bouton désactivé */}
                    <span>
                        <ModalButton
                            name="Devis"
                            filled={clientInfo.devis.length > 0}
                            disabled={infoIntervention.sav}
                            clickHandler={() => {
                            if (!infoIntervention.sav) {
                                setOpenDevis(true);
                            }
                            }}
                            icon={<DescriptionIcon />}
                        />
                    </span>
                </Tooltip>
                <ModalButton name="Bon de commande" filled={clientInfo.bon_de_commande.length > 0} clickHandler={() => { setOpenBonDeCommande(true) }} icon={<DescriptionIcon />} />
                <ModalButton name="Facturation" filled={clientInfo.facture.length > 0} clickHandler={() => { setOpenFacture(true) }} icon={<DescriptionIcon />} />
                <ModalButton name="Rapport" filled={clientInfo.rapport.length > 0} clickHandler={() => { setOpenRapport(true) }} icon={<DescriptionIcon />} />
                <Modal open={openDevis} close={handleDevisClose} clickOut={false}>
                    <DevisClient
                        modalClosed={() => setOpenDevis(false)}
                        clientInfo={clientInfo}
                        user={user}
                        infoIntervention={infoIntervention}
                        infoFacturation={dataFacturation}
                        setClientInfo={setClientInfo}
                        actions={actions}
                        setIsDevisSaved={setIsDevisSaved}
                    />
                </Modal>
                <Modal open={openBonDeCommande} close={() => setOpenBonDeCommande(false)} clickOut={false}>
                    <BonDeCommande
                        clientInfo={clientInfo}
                        infoIntervention={infoIntervention}
                        setClientInfo={setClientInfo}
                    />
                </Modal>
                <Modal open={openFacture} close={() => setOpenFacture(false)} padding="0px">
                    <FactureClient
                        clientInfo={clientInfo}
                        updateInfoIntervention={updateInfoIntervention}
                        infoIntervention={infoIntervention}
                        user={user}
                        setClientInfo={setClientInfo}
                        infoFacturation={dataFacturation}
                    />
                </Modal>
                <Modal open={openRapport} close={() => setOpenRapport(false)} clickOut={false}>
                    <RapportClient
                        clientInfo={clientInfo}
                        user={user}
                        infoIntervention={infoIntervention}
                        setClientInfo={setClientInfo}
                    />
                </Modal>
            </Stack>
        </Card >
    )
}
