import React, { useEffect, useState, useContext } from 'react';
import { GetTaskByUser, PostTask, DeleteTask } from 'services';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import {
  Stack,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
  Card,
} from '@mui/material';
import { dateConfig } from 'config';
import { formatDate } from 'utils/date';
import Label from 'components/UI/Label';
import TodoListContext from 'contexts/TodoListContext';

interface Task {
  ID: number | string;
  task: string;
  time: string | Date;
}

interface TaskResponse {
  task: Task[];
}

interface TaskInput {
  task: string;
  time: Date;
}

interface TodoListContextType {
  isAddedTask: boolean;
  idDeletedTask: any;
  onAddTask: () => void;
  onTaskDelete: () => void;
}

interface TodoListProps {
  display: string;
}

const GridList = styled('div')(() => ({
  border: '0px solid rgb(196 196 196)',
  borderRadius: '10px',
  background: 'white',
  height: '25px',
  maxWidth: '470px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
}));

export const TodoList: React.FC<TodoListProps> = ({ display }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [TasksList, setTasksList] = useState<Task[]>([]);
  const [inputTask, setInputTask] = useState<string>('');
  const ctx = useContext<TodoListContextType>(TodoListContext);

  useEffect(() => {
    Promise.all([GetTaskByUser()]).then(
      ([responseGetTaskByUser]: [TaskResponse]) => {
        setTasksList(responseGetTaskByUser.task);
      }
    ).catch((err) => {
      enqueueSnackbar("Erreur lors de la récupération des commentaires", { variant: 'error' });
      console.error(err);
    });
  }, [ctx.isAddedTask, ctx.idDeletedTask, enqueueSnackbar]);

  const sendTask = (taskInput: TaskInput) => {
    if (taskInput.task.trim().length === 0) {
      return;
    }
    PostTask(taskInput)
      .then(() => {
        ctx.onAddTask();
      })
      .catch((err) => {
        enqueueSnackbar('Erreur lors de la création de votre tâche', { variant: 'error' });
        console.error(err);
      });
  };

  const DeleteTaskHandle = (e: React.ChangeEvent<HTMLInputElement>, id: number | string) => {
    DeleteTask(id)
      .then(() => {
        enqueueSnackbar('Tâche supprimée', { variant: 'success' });
        ctx.onTaskDelete();
      })
      .catch((err) => {
        enqueueSnackbar("Erreur lors de la suppression de la tâche", { variant: 'error' });
        console.error(err);
      });
  };

  const keyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 || e.key === 'Enter') {
      if (inputTask.trim().length !== 0) {
        sendTask({ task: inputTask, time: new Date() });
      }
      setInputTask('');
    }
  };

  const displayList = (display: string) => {
    if (display === "Home") {
      return (
        <Card variant="outlined" sx={{ p: 1 , width: '100%', height: '100%', overflow: 'auto', maxHeight: '112px' }}>
          <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
            {TasksList.map((item, index) => (
              <Stack key={index} direction="row" sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
                <Stack direction="row" sx={{ width: '100%' }} spacing={1}>
                  <Checkbox
                    checked={false}
                    value={item.ID}
                    onChange={(e) => DeleteTaskHandle(e, item.ID)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Tooltip title={item.task}>
                    <Typography>{item.task}</Typography>
                  </Tooltip>
                </Stack>
                <div>
                  <Label sx={{ mr: 0 }} color="info">
                    {formatDate(item.time, dateConfig.format6)}
                  </Label>
                </div>
              </Stack>
            ))}
          </Stack>
        </Card>
      );
    } else {
      return (
        <>
          {TasksList.map((item, index) => (
            <GridList key={index}>
              <div style={{ width: '10%' }}>
                <Checkbox
                  value={item.ID}
                  onChange={(e) => DeleteTaskHandle(e, item.ID)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              {item.task.length > 32 ? (
                <Tooltip title={item.task}>
                  <div style={{ width: '70%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    <Typography>{item.task.slice(0, 32) + ' ...'}</Typography>
                  </div>
                </Tooltip>
              ) : (
                <div style={{ width: '70%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  <Typography>{item.task}</Typography>
                </div>
              )}
              <div>
                <Label sx={{ mr: 0 }} color="info">
                  {formatDate(item.time, dateConfig.format6)}
                </Label>
              </div>
            </GridList>
          ))}
        </>
      );
    }
  };

  return display !== "Home" ? (
    <Stack direction="column" width="100%" spacing={1} minWidth="320px">
      <TextField
        value={inputTask}
        size="small"
        variant="outlined"
        placeholder="Nouvelle tâche"
        onKeyDown={keyPress}
        onChange={(event) => setInputTask(event.target.value)}
        sx={{ backgroundColor: 'white' }}
      />
      {displayList(display)}
    </Stack>
  ) : (
    displayList(display)
  );
};
