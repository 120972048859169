import React from 'react';
import moment from 'moment';

// mui
import Dialog from '@mui/material/Dialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, Box, Stack, Typography, useTheme, IconButton } from '@mui/material';
import Button from '@mui/material/Button';

interface ClientInfo {
    devis?: any[];
}
interface DialogValidProps {
    infoIntervention: {
        contact_client?: string;
        fin_de_travaux?: string;
        id_client?: string;
        adresse?: string;
        ville?: string;
        numero_magasin?: string;
        enseigne?: string;
        code_postal?: string;
        id_mastore?: string;
    };
    clientInfo: ClientInfo;
    isAllDevisValid: boolean;
    isAllActionsClosed: boolean;
    handleValidationWithComment: () => void;
    close: () => void;
}

const DialogValid: React.FC<DialogValidProps> = ({
                                                     clientInfo,
                                                     isAllDevisValid,
                                                     infoIntervention,
                                                     isAllActionsClosed,
                                                     handleValidationWithComment,
                                                     close
                                                 }) => {
    const theme = useTheme();

    // Calcul des conditions pour activer le bouton
    const canValidate =
        (infoIntervention.contact_client !== "" && infoIntervention.contact_client !== "select") &&
        (clientInfo.devis && clientInfo.devis.length > 0 && isAllDevisValid) &&
        isAllActionsClosed;

    const getDevisStatusMessage = () => {
        if (!clientInfo.devis || clientInfo.devis.length === 0) {
            return "Aucun devis disponible";
        }
        return isAllDevisValid ? "Devis valides" : "Passez les devis en VALIDÉ";
    };

    const getActionsMessage = () => {
        return isAllActionsClosed ? "Les actions sont clôturées et/ou archivées" : "Les actions doivent être clôturées et/ou archivées";
    };

    const getValidationIcon = () => {
        return isAllActionsClosed ? (
            <CheckCircleIcon style={{ color: 'rgb(76, 175, 80)' }} />
        ) : (
            <CancelIcon style={{ color: 'rgb(239, 83, 80)' }} />
        );
    };

    const getDevisValidationIcon = () => {
        return (!clientInfo.devis || clientInfo.devis.length === 0 || !isAllDevisValid) ? (
            <CancelIcon style={{ color: 'rgb(239, 83, 80)' }} />
        ) : (
            <CheckCircleIcon style={{ color: 'rgb(76, 175, 80)' }} />
        );
    };

    const getContactValidationIcon = () => {
        return infoIntervention.contact_client === "" || infoIntervention.contact_client === "select" ? (
            <CancelIcon style={{ color: 'rgb(239, 83, 80)' }} />
        ) : (
            <CheckCircleIcon style={{ color: 'rgb(76, 175, 80)' }} />
        );
    };

    return (
        <Dialog open={true} onClose={close} maxWidth="sm" fullWidth>
            <Box style={{ width: '100%', height: '100%', position: 'relative', padding: theme.spacing(2) }}>
                <IconButton
                    onClick={close}
                    style={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" marginBottom={2} color="#1a63b6" textAlign="center">
                    {`${infoIntervention.enseigne} - ${infoIntervention.ville} ${infoIntervention.code_postal} [N°${infoIntervention.numero_magasin}]`}
                </Typography>
                <Typography variant="h6" marginBottom={2} color={alpha(theme.palette.text.secondary, 0.8)}>
                    Informations à valider avant de terminer l'intervention :
                </Typography>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">
                            <strong>ID Mastore :</strong> {infoIntervention.id_mastore}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">
                            <strong>DI Client :</strong> {infoIntervention.id_client}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">
                            <strong>Date de fin de travaux :</strong> {moment(infoIntervention.fin_de_travaux).format('DD/MM/YYYY')}
                        </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1">
                            <strong>Contact :</strong> {(infoIntervention.contact_client === "select" || infoIntervention.contact_client === "") ? "Pas de contact" : infoIntervention.contact_client}
                        </Typography>
                        {getContactValidationIcon()}
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1"><strong>Devis :</strong> {getDevisStatusMessage()}</Typography>
                        {getDevisValidationIcon()}
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1"><strong>Actions :</strong> {getActionsMessage()}</Typography>
                        {getValidationIcon()}
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleValidationWithComment}
                            disabled={!canValidate}
                        >
                            Valider
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    );
};

export default DialogValid;
