import React, { Component, Suspense } from "react";

// lib
import axios from "axios";
import { uniq } from "lodash";
import { connect } from "react-redux";
import withMySnackbar from "hooks/withMySnackbar";

// mui
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";

// components
import templateEmail from "./mailTemplate";
import SendMail from "../../components/Mail/SendMail";
import Spinner from "../../components/UI/Spinner/Spinner";

// types
import { Attachment } from "views/Intervention/Client/modal/DevisClient/types";
import { MailState, MailProps, ReduxStateProps, WithSnackbarProps } from "./types";

const ModalAttachement = React.lazy(
  () => import("../../components/Mail/ModalAttachement")
);

type Props = MailProps & ReduxStateProps & WithSnackbarProps;

class Mail extends Component<Props, MailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      attribut: this.props.attribut,
      type: this.props.type || "",
      objectMail: this.props.objectMail,
      destinataire: [],
      Attachment: this.props.Attachment,
      changeState: false,
    };
  }

  componentDidMount(): void {
    if (this.props.sendto === "client") {
      axios.defaults.withCredentials = true;
      axios
        .get("/legacy/contact?client=" + this.props.infoIntervention.client)
        .then((response) => {
          // On récupère un tableau de mails (en s'assurant de bien aplatir le tableau)
          const mails: string[] = response.data.contact
            .map((item: any) => item.mails)
            .flat();
          this.setState({ destinataire: mails });
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des contacts :", error);
        });
    } else {
      let newDestinataire: string[] = [];
      this.props.destinataire.forEach((item: any) => {
        if (item.intervenant_id !== -1) {
          newDestinataire.push(item.intervenant.mail);
        }
      });
      newDestinataire = uniq(newDestinataire.flat());
      this.setState({ destinataire: newDestinataire });
    }
  }

  OpenModal = (): void => {
    this.setState({ changeState: true });
  };

  closeModal = (): void => {
    this.setState({ changeState: false });
  };

  selectedelem = (selected: Attachment[]): void => {
    const currentAttachments = Array.isArray(this.state.Attachment)
      ? this.state.Attachment
      : [];
    const newAttachments = [...currentAttachments, ...selected];
    this.setState({ Attachment: newAttachments }, this.closeModal);
  };

  sendMail = async (
    destinataire: string[],
    objectMail: string,
    bodyMail: string,
    idFile: any,
    nameFile: string[],
    cc: string[]
  ): Promise<void> => {
    const header: any = {
      IDMastore: this.props.infoIntervention.id_mastore,
      Destinataires: destinataire,
      ObjetMail: objectMail,
      Cc: cc,
      BodyMail: bodyMail,
      Attachment: idFile,
      NameAttachment: nameFile,
      Attribut: this.props.attribut,
      DateIntervention: this.props.dateIntervention,
      Duration: this.props.duration,
    };

    if (this.state.Attachment && this.state.Attachment.length > 0) {
      header.id_attachment = this.state.Attachment[0]?.id;
    }

    axios.defaults.withCredentials = true;
    try {
      if (this.props.sendto === "intervenant") {
        await axios.post("/legacy/sendMailIntervenant", header);
      } else if (this.props.sendto === "client") {
        await axios.post("/legacy/sendMailClient", header);
      }
      this.props.modalClosed();
      this.props.snackbar.enqueueSnackbar("Mail envoyé", {
        variant: "success",
      });
    } catch (error) {
      this.props.snackbar.enqueueSnackbar("Erreur lors de l'envoi", {
        variant: "error",
      });
    }
  };

  removeelem = (i: number): void => {
    if (i >= 0) {
      const newState = [...this.state.Attachment];
      newState.splice(i, 1);
      this.setState({ Attachment: newState });
    }
  };

  render(): JSX.Element {
    return (
      <>
        <SendMail
            user={this.props.user}
            sendMail={this.sendMail}
            OpenModal={this.OpenModal}
            RemoveElem={this.removeelem}
            attribut={this.props.attribut}
            client={this.state.destinataire}
            objetMail={this.state.objectMail}
            Attachment={this.state.Attachment}
            modalClosed={this.props.modalClosed}
            infoIntervention={this.props.infoIntervention}
            Body={
                templateEmail(
                    this.state.attribut,
                    this.props.infoIntervention,
                    this.state.objectMail,
                    this.props.link,
                    this.props.dateIntervention,
                    this.props.duration
                )
            }
            id_mastore={this.props.infoIntervention.id_mastore}
            dateIntervention={this.props.dateIntervention ? this.props.dateIntervention.toString() : ""}
            duration={this.props.duration}
            sendto={this.props.sendto}
        />
        <Modal onClose={this.closeModal} open={this.state.changeState}>
          <DialogContent>
            <Suspense fallback={<Spinner />}>
              <ModalAttachement
                closeModal={this.closeModal}
                attribut={this.state.attribut}
                selectedelem={this.selectedelem}
                IDMastore={this.props.infoIntervention.id_mastore}
              />
            </Suspense>
          </DialogContent>
        </Modal>
      </>
    );
  }
}

/* --- Redux --- */

const mapStateToProps = (state: any): ReduxStateProps => {
  return {
    user: {
      mail: state.mail,
      lastname: state.lastname,
      firstname: state.firstname,
      telephone: state.telephone,
    },
  };
};

export default connect(mapStateToProps)(withMySnackbar(Mail));
