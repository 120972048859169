import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { OutlinedInput, IconButton, Stack } from '@mui/material';

interface SearchProps {
  isCollapse: boolean;
}

const Search: React.FC<SearchProps> = ({ isCollapse }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (searchValue !== '') {
      window.open(`/intervention?IDMastore=${searchValue}`);
    }
  };

  return (
    <Stack
      sx={{
        px: 1,
        ...(isCollapse && { opacity: 0 }),
        marginBottom: '10px',
      }}
      direction="row"
    >
      <form onSubmit={onClick} style={{ width: '100%' }}>
        <OutlinedInput
          size="small"
          fullWidth
          placeholder="ID Mastore"
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="button" aria-label="search" onClick={onClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    </Stack>
  );
};

export default Search;
