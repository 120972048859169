import React, { useState, useEffect, SyntheticEvent, ChangeEvent, FocusEvent } from "react";
import axios from "axios";

// MUI
import Chip from "@mui/material/Chip";
import SendIcon from "@mui/icons-material/Send";
import Autocomplete from "@mui/material/Autocomplete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Stack, TextField, Button, ListSubheader } from "@mui/material";

// Components & Quill
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Header from "components/UI/Header/Header";
import ProgressButton from "components/UI/LoadingButtonModal";
import DisplayFile from "components/UI/DisplayAttachment/DisplayFile";
import RecapEmail from "../Intervention/Intervenant/Modal/RecapEmail/RecapEmail";

// Libs
import JSZip from "jszip";
import { useSnackbar } from "notistack";

// types
import {Attachment, SendMailProps} from './types'


const SendMail: React.FC<SendMailProps> = (props) => {
  const [objectMail, setObjectMail] = useState<string>(props.objetMail);
  const [openEmail, setOpenEmail] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [destinataire, setDestinataire] = useState<string[]>([]);
  const [cc, setCc] = useState<string[]>([props.user.mail]);
  const [pending, setPending] = useState<boolean>(false);
  const [mastoreMails, setMastoreMails] = useState<string[]>([]);
  const [bodyMail, setBodyMail] = useState<string>(props.Body);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputValueCc, setInputValueCc] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

    // -- ICI on fusionne props.client avec l'adresse du magasin (props.infoIntervention?.mail) s'il existe
    const combinedClient = React.useMemo(() => {
        // props.client peut être undefined, donc on s'assure que c'est un tableau
        const baseClientList = Array.isArray(props.client) ? [...props.client] : [];

        // On ajoute l'adresse du magasin si elle est définie (non vide)
        if (props.infoIntervention?.mail) {
            baseClientList.push(props.infoIntervention.mail);
        }

        return baseClientList;
    }, [props.client, props.infoIntervention]);

    const handleClickOpenEmail = () => {
        setOpenEmail(!openEmail);
    };

    // Vérification de validité des pièces jointes
    const attachmentsValid: boolean =
        !props.Attachment ||
        props.Attachment.length === 0 ||
        props.Attachment.every((att) => {
            const hasValidUrl = att.url && att.url.trim() !== "";
            const hasValidBase64 = att.base64_file && att.base64_file.trim() !== "";
            const hasValidFile =
                (typeof att.file === "string" && att.file.trim() !== "") ||
                (typeof att.file === "boolean" && att.file === true);
            return hasValidUrl || hasValidBase64 || hasValidFile;
        });

  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get("/legacy/getMails").then((response) => {
      setMastoreMails(response.data.mails);
    });
  }, []);

  const changeObjectMail = (event: ChangeEvent<HTMLInputElement>) => {
    setObjectMail(event.target.value);
  };

  const clearBody = () => {
    setBodyMail(
      `<div>Bonjour,</div><br>
       <div>Cordialement, ${props.user.firstname} ${props.user.lastname}</div><br>
       <div>${props.user.mail === undefined ? `<div>-- Votre adresse mail n'a pas été trouvée. Veuillez la renseigner à la place de ce message. --</div>` : props.user.mail}</div><br>
       <div>https://mastore.evictum.fr/intervention?IDMastore=${props.id_mastore}</div>`
    );
  };

  const handleQuillChange = (content: string) => {
    setBodyMail(content);
  };

  const handleMouseOut = (e: FocusEvent<HTMLInputElement>) => {
    if (inputValue.trim().length > 0) {
      setDestinataire([...destinataire, inputValue]);
      setInputValue("");
      setIsEmail(true);
    }
  };

  const onInputChange = (
    event: SyntheticEvent,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleMouseOutCc = (e: FocusEvent<HTMLInputElement>) => {
    if (inputValueCc.trim().length > 0) {
      setCc([...cc, inputValueCc]);
      setInputValueCc("");
    }
  };

  const onInputChangeCc = (
    event: SyntheticEvent,
    newInputValue: string
  ) => {
    setInputValueCc(newInputValue);
  };

  // const convertBase64ToPDF = (base64: string, fileName: string): File => {
  //   const pdf = new jsPDF();
  //   pdf.addImage(base64, "JPEG", 0, 0);
  //   const pdfData = pdf.output("blob");
  //   return new File([pdfData], `${fileName}.pdf`, { type: "application/pdf" });
  // };

  // On sépare ce qui vient de props.client (clients) et infoIntervention.mail (magasin)
    const groupedOptions = React.useMemo(() => {
        // Utiliser le label conditionnel pour les clients/intervenants
        const groupLabel = props.sendto === "intervenant" ? "Intervenant" : "Client";
        const clients = Array.isArray(props.client)
            ? props.client.map((c: string) => ({ group: groupLabel, label: c }))
            : [];

        // Construire la liste pour le magasin
        const store = props.infoIntervention?.mail
            ? [{ group: "Site", label: props.infoIntervention.mail }]
            : [];

        return [...clients, ...store];
    }, [props.client, props.infoIntervention, props.sendto]);




  const sendDataTosendMail = async (): Promise<void> => {
    setPending(true);

    try {
      let files: any[] = [];
      let names: string[] = [];

      if (props.Attachment && props.Attachment.length > 0) {
        files = await Promise.all(
          props.Attachment.map(async (item: Attachment) => {
            if (item.base64_file) {
              const lowerCaseName = item.name.toLowerCase();
              if (lowerCaseName.endsWith(".pdf")) {
                return item.base64_file;
              } else {
                return item.base64_file;
              }
            } else if (item.url) {
              return item.file;
            }
            return item.file;
          })
        );

        // Calcul de la taille totale des fichiers
        const totalSize = files.reduce((acc: number, file: File) => {
          return acc + (file.size || 0);
        }, 0);

        // Si le total dépasse 15 Mo, on zippe les fichiers
        if (totalSize > 15 * 1024 * 1024) {
          const zip = new JSZip();
          files.forEach((file: File, index: number) => {
            zip.file(props.Attachment[index].name, file);
          });

          const today = new Date();
          const dateStr = `${today.getFullYear()}-${String(
            today.getMonth() + 1
          ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

          const zipBlob = await zip.generateAsync({ type: "blob" });
          files = [
            new File(
              [zipBlob],
              `pieces_jointes_mastore_${dateStr}.zip`,
              { type: "application/zip" }
            ),
          ];
        }

        names = props.Attachment.map((item: Attachment) => item.name);
      }

      // Envoi de l'e-mail
      await props.sendMail(destinataire, objectMail, bodyMail, files, names, cc);

      if (props.attribut === "Devis Client") {
        enqueueSnackbar("Veuillez passer le statut du devis à 'ENVOYÉ'", {
          variant: "warning",
        });
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'e-mail :", error);
      enqueueSnackbar("Échec de l'envoi de l'e-mail. Veuillez réessayer.", {
        variant: "error",
      });
    } finally {
      setPending(false);
    }
  };

  return (
    <Box
      sx={{
        width: { xl: "65vw", md: "70vw", sm: "80vw", xs: "90vw" },
        maxHeight: "90vh",
      }}
    >
      <Header>Envoyer {props.attribut}</Header>
      {openEmail && (
        <RecapEmail close={handleClickOpenEmail} titre=" demandes " resume={props.resume} />
      )}
      <Stack sx={{ px: 3, maxHeight: "600px", overflow: "auto" }} spacing={1}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <span
              style={{
                  color: "#316690",
                  width: "50.5%",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "8px",
              }}
          >
            Destinataires :
          </span>
            <span style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
            Cc :
          </span>
            <Autocomplete
                multiple
                size="small"
                value={destinataire}
                options={groupedOptions}
                groupBy={(option) => (typeof option === "string" ? "Others" : option.group)}
                getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.label
                }
                renderGroup={(params) => (
                    <React.Fragment key={params.key}>
                        <ListSubheader
                            component="div"
                            sx={{
                                paddingY: "2px",
                                paddingX: "8px",
                                fontSize: "0.9rem",
                                lineHeight: "1.2rem",
                                margin: 0,
                                backgroundColor: "#f7f7f7",
                                color: "#316690",
                            }}
                        >
                            {params.group}
                        </ListSubheader>
                        <Box sx={{ pl: 2 }}>{params.children}</Box>
                    </React.Fragment>
                )}
                onChange={(event, newValue) => {
                    // Convert each item to a string: if it's an object, extract its label
                    const mapped = newValue.map((item) =>
                        typeof item === "string" ? item : item.label
                    );
                    setDestinataire(mapped);
                    setIsEmail(mapped.length > 0);
                }}
                freeSolo
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                        const { key, disabled: _disabled, ...otherTagProps } = getTagProps({ index });
                        const label = typeof option === "string" ? option : option.label;
                        return (
                            <Chip
                                key={label}
                                size="small"
                                label={label}
                                disabled={label === props.user.mail}
                                sx={{ height: "100%" }}
                                {...otherTagProps}
                            />
                        );
                    })
                }
                inputValue={inputValue}
                onInputChange={onInputChange}
                onBlur={handleMouseOut}
                style={{ width: "49.5%", marginRight: "1%", background: "white" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Destinataires"
                        size="small"
                        label="Destinataires"
                    />
                )}
            />
            {/* AUTOCOMPLETE DES CC */}
                    <Autocomplete
                        multiple
                        value={cc}
                        size="small"
                        onChange={(event, newValue) => {
                            setCc([
                                props.user.mail,
                                ...newValue.filter((option) => props.user.mail !== option),
                            ]);
                        }}
                        options={mastoreMails}
                        inputValue={inputValueCc}
                        onInputChange={onInputChangeCc}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                    <Chip
                                        key={option} // Directly pass key here
                                        size="small"
                                        label={option}
                                        {...tagProps}
                                        disabled={option === props.user.mail}
                                        sx={{ height: "100%" }}
                                    />
                                );
                            })
                        }
                        style={{ width: "49.5%", background: "white" }}
                        freeSolo
                        onBlur={handleMouseOutCc}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Cc"
                                size="small"
                            />
                        )}
                    />
                </div>

                <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
                    Objet :
                </div>
                <TextField
                    variant="outlined"
                    value={objectMail}
                    type="text"
                    onChange={changeObjectMail}
                    name="Objet"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                />

                <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
                    Mail :
                </div>
                <ReactQuill
                    theme="snow"
                    value={bodyMail}
                    onChange={handleQuillChange}
                    modules={{
                        toolbar: [["bold", "italic", "underline", "strike"], ["link"], ["clean"]],
                    }}
                    style={{
                        overflowY: "auto",
                        borderRadius: "4px",
                        background: "#fff",
                        flexGrow: 1,
                    }}
                />

          <Stack
              style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
              }}
          >
              <div style={{ color: "#316690", fontWeight: "bold", fontSize: "14px" }}>
                  Pièces jointes :
              </div>
              <Stack
                  direction="row"
                  style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                  }}
              >
                  {props.Attachment &&
                      props.Attachment.map((attachment, index) => {
                          return (
                              <DisplayFile
                                  key={index}
                                  index={index}
                                  attachment={attachment}
                                  RemoveElem={props.RemoveElem}
                              />
                          );
                      })}
              </Stack>
          </Stack>

        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{ pb: 2, pt: 1 }}
        >
          <Button
            size="small"
            variant="outlined"
            onClick={props.OpenModal}
            startIcon={<AttachFileIcon />}
          >
            Joindre
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={clearBody}
          >
            Effacer le corps du mail
          </Button>
        </Stack>
      </Stack>
      <Stack
        sx={{ pb: 3 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <ProgressButton
          name="Envoyer"
          update={isEmail && attachmentsValid}
          loading={pending}
          icon={<SendIcon />}
          clickHandler={sendDataTosendMail}
        />
      </Stack>
    </Box>
  );
};

export default SendMail;
