import React, { useReducer, useState, useContext } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Stack,
  IconButton,
  Popover,
  Typography,
  List,
  Collapse,
  Badge,
  Box,
  Divider,
  Avatar,
  Chip,
  Paper,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

// Date
import { dateConfig } from 'config';
import { formatDate } from 'utils/date';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ScheduleIcon from '@mui/icons-material/Schedule';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// Utils
import { findIndex } from "lodash";
import RelanceContext from 'contexts/RelanceContext';
import { GetRelanceByUser } from 'services';

// ----------------------------------------------------------------------

const NotificationItem = styled(Paper)(({ theme, read }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  boxShadow: theme.shadows[1],
  position: 'relative',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: read ? alpha(theme.palette.primary.light, 0.1) : theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[3],
    backgroundColor: read 
      ? alpha(theme.palette.primary.light, 0.2) 
      : alpha(theme.palette.background.default, 0.8),
  },
}));
  
const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  backgroundColor: theme.palette.background.neutral,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  cursor: 'pointer',
}));

const EmptyNotification = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontStyle: 'italic',
}));

const NotificationBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: 10,
    height: 20,
    minWidth: 20,
    padding: '0 6px',
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  padding: 4,
  backgroundColor: alpha(theme.palette.grey[500], 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.grey[500], 0.2),
  },
}));

const NotificationPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: 400,
    maxHeight: '80vh',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[24],
    overflow: 'hidden',
  },
}));

const NotificationHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const AvatarStyled = styled(Avatar)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || theme.palette.primary.main,
  width: 32,
  height: 32,
  fontSize: 14,
}));

// ----------------------------------------------------------------------

const openListReducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_LIST_DAY':
      return { ...state, isOpenDay: !state.isOpenDay };
    case 'OPEN_LIST_YESTERDAY':
      return { ...state, isOpenYesterday: !state.isOpenYesterday };
    case 'OPEN_LIST_BEFORE_YESTERDAY':
      return { ...state, isOpenBeforeYesterday: !state.isOpenBeforeYesterday };
    case 'OPEN_LIST_REST':
      return { ...state, isOpenRest: !state.isOpenRest };
    default:
      return state;
  }
};

export default function NavbarRelance({ relanceInfo, onLoading, sortTabRelance }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openList, dispatchOpenList] = useReducer(openListReducer, {
    isOpenDay: true,
    isOpenYesterday: false,
    isOpenBeforeYesterday: false,
    isOpenRest: false,
  });
  const ctx = useContext(RelanceContext);

  const handleClickDayList = (triggerList) => {
    dispatchOpenList({ type: triggerList });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickTreatRelance = (relance, key) => {
    axios.defaults.withCredentials = true;
    axios.put('/legacy/putMention', { ID: relance.ID, relance_treat: true })
      .then(() => {
        GetRelanceByUser().then(response => {
          sortTabRelance(response.comments);
          ctx.onTreatRelance();
        }).catch(error => {
          console.log(error);
        });
      }).catch(error => {
        console.log(error);
      });
  };

  const onClickChangeReadStatus = (relance, key) => {
    axios.defaults.withCredentials = true;
    axios.put('/legacy/putMention', { ID: relance.ID, read: true })
      .then(() => {
        const indexToChange = findIndex(relanceInfo[key], { ID: relance.ID });
        relanceInfo[key][indexToChange] = {
          ...relance,
          read: true
        };
      }).catch(error => {
        console.log(error);
      });
  };

  const getNotificationColor = (key) => {
    switch (key) {
      case 'TabToday':
        return 'info';
      default:
        return 'error';
    }
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase()
      .substring(0, 2);
  };

  // Calcul du total des relances non traitées (basé sur relance_treat)
  const totalUntreated = [
    ...relanceInfo.TabToday,
    ...relanceInfo.TabYesterday,
    ...relanceInfo.TabBeforeYesterday,
    ...relanceInfo.TabRest
  ].filter(item => !item.relance_treat).length;

  const displayList = (tabRelance, key) => {
    if (tabRelance.length > 0) {
      return (
        <AnimatePresence>
          {tabRelance.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2, delay: index * 0.05 }}
            >
              <NotificationItem read={item.read} elevation={item.read ? 0 : 1}>
                <CloseButton
                  size="small"
                  onClick={() => onClickTreatRelance(item, key)}
                  aria-label="mark as treated"
                >
                  <CloseIcon fontSize="small" />
                </CloseButton>
                
                <Box 
                  component="a" 
                  href={"/intervention?IDMastore=" + item.IDMastore}
                  onClick={() => onClickChangeReadStatus(item, key)}
                  target="_blank"
                  sx={{ 
                    display: 'flex', 
                    textDecoration: 'none', 
                    color: 'inherit',
                    gap: 1.5
                  }}
                >
                  <AvatarStyled >
                    {getInitials(item.author)}
                  </AvatarStyled>
                  
                  <Box sx={{ width: '100%', pr: 6 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography variant="h6">{item.author}</Typography>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <ScheduleIcon color="action" sx={{ fontSize: 14 }} />
                        <Typography variant="h6" color="text.secondary">
                          {formatDate(item.time, dateConfig.format4).toLowerCase()}
                        </Typography>
                      </Stack>
                    </Stack>
                    
                    <Typography 
                      variant="h6v2" 
                      sx={{ 
                        mt: 0.5, 
                        color: 'text.primary',
                        lineHeight: 1.5,
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {item.commentaire}
                    </Typography>
                    
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
                      <Chip 
                        size="small" 
                        label={!item.read ? 'Non lu' : 'Lu'}
                        color="primary" 
                        variant="outlined" 
                        sx={{ height: 20, fontSize: 10 }} 
                      />
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          color: 'text.secondary',
                          ml: 'auto',
                          cursor: 'pointer',
                          p: 0.5,
                          borderRadius: 1,
                          transition: 'background-color 0.2s',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          },
                        }}
                      >
                        <OpenInNewIcon sx={{ fontSize: 12, mr: 0.5 }} />
                        Ouvrir
                      </Typography>

                    </Stack>
                  </Box>
                </Box>
              </NotificationItem>
            </motion.div>
          ))}
        </AnimatePresence>
      );
    } else {
      return (
        <EmptyNotification>
          <Typography variant="h6v2">Aucune relance</Typography>
        </EmptyNotification>
      );
    }
  };

  const getSectionLabel = (key) => {
    switch (key) {
      case 'OPEN_LIST_DAY':
        return "Aujourd'hui";
      case 'OPEN_LIST_YESTERDAY':
        return "Hier";
      case 'OPEN_LIST_BEFORE_YESTERDAY':
        return "Avant-hier";
      case 'OPEN_LIST_REST':
        return "Plus de 3 jours";
      default:
        return "";
    }
  };

  const getSectionCount = (key) => {
    switch (key) {
      case 'OPEN_LIST_DAY':
        return relanceInfo.TabToday.length;
      case 'OPEN_LIST_YESTERDAY':
        return relanceInfo.TabYesterday.length;
      case 'OPEN_LIST_BEFORE_YESTERDAY':
        return relanceInfo.TabBeforeYesterday.length;
      case 'OPEN_LIST_REST':
        return relanceInfo.TabRest.length;
      default:
        return 0;
    }
  };

  const getOpenState = (key) => {
    switch (key) {
      case 'OPEN_LIST_DAY':
        return openList.isOpenDay;
      case 'OPEN_LIST_YESTERDAY':
        return openList.isOpenYesterday;
      case 'OPEN_LIST_BEFORE_YESTERDAY':
        return openList.isOpenBeforeYesterday;
      case 'OPEN_LIST_REST':
        return openList.isOpenRest;
      default:
        return false;
    }
  };

  const getRelanceArray = (key) => {
    switch (key) {
      case 'OPEN_LIST_DAY':
        return relanceInfo.TabToday;
      case 'OPEN_LIST_YESTERDAY':
        return relanceInfo.TabYesterday;
      case 'OPEN_LIST_BEFORE_YESTERDAY':
        return relanceInfo.TabBeforeYesterday;
      case 'OPEN_LIST_REST':
        return relanceInfo.TabRest;
      default:
        return [];
    }
  };

  const getRelanceKey = (key) => {
    switch (key) {
      case 'OPEN_LIST_DAY':
        return "TabToday";
      case 'OPEN_LIST_YESTERDAY':
        return "TabYesterday";
      case 'OPEN_LIST_BEFORE_YESTERDAY':
        return "TabBeforeYesterday";
      case 'OPEN_LIST_REST':
        return "TabRest";
      default:
        return "";
    }
  };

  const sections = [
    'OPEN_LIST_DAY',
    'OPEN_LIST_YESTERDAY',
    'OPEN_LIST_BEFORE_YESTERDAY',
    'OPEN_LIST_REST'
  ];

  const open = Boolean(anchorEl);
  const id = open ? 'notifications-popover' : undefined;

  return (
    <div>
      <IconButton 
        aria-label="notifications" 
        onClick={handleClick}
        sx={{ 
          transition: 'transform 0.2s',
          '&:hover': { transform: 'scale(1.1)' }
        }}
      >
        <NotificationsIcon />
      </IconButton>
      
      {onLoading && (
          <NotificationPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              disableScrollLock={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
          >
            <NotificationHeader>
              <Typography variant="subtitle1" fontWeight="bold">
                Relances
              </Typography>
              <Stack direction={"row"} alignItems="center" gap={2}>
                {totalUntreated > 0 && (
                    <Chip
                        label={`${totalUntreated} non traitée${totalUntreated > 1 ? 's' : ''}`}
                        size="small"
                        color="error"
                    />
                )}
              </Stack>
            </NotificationHeader>

            {/* Conteneur défilable */}
            <Box sx={{ maxHeight: 'calc(80vh - 80px)', overflowY: 'auto' }}>
              <List sx={{ padding: 0 }}>
                {sections.map((section, idx) => (
                    <React.Fragment key={section}>
                      <SectionHeader onClick={() => handleClickDayList(section)}>
                        <Typography variant="h6">
                          {getSectionLabel(section)}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Chip
                              label={getSectionCount(section)}
                              size="small"
                              color={section === 'OPEN_LIST_DAY' ? "info" : "error"}
                              variant={getOpenState(section) ? "filled" : "outlined"}
                          />
                          {getOpenState(section) ? <ExpandLess /> : <ExpandMore />}
                        </Stack>
                      </SectionHeader>

                      <Collapse in={getOpenState(section)} timeout="auto" unmountOnExit>
                        <Box sx={{ p: 1.5 }}>
                          {displayList(getRelanceArray(section), getRelanceKey(section))}
                        </Box>
                      </Collapse>

                      {idx < sections.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
              </List>

              {totalUntreated === 0 && (
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <CheckCircleOutlineIcon color="success" />
                    <Typography variant="h6" color="text.secondary">
                      Toutes les relances ont été traitées
                    </Typography>
                  </Box>
              )}
            </Box>
          </NotificationPopover>
      )}
    </div>
    
  );
}
