import moment from "moment";

export interface InfoIntervention {
  enseigne: string;
  numero_magasin: string | number;
  ville: string;
  objet: string;
  id_client: string | number;
  id_mastore: string | number;
  specificite_technique: string;
  specialite: string;
  complement_objet: string;
}

const templateEmail = (
  attribut: string,
  infoIntervention: InfoIntervention,
  objectMail: string,
  link: string | undefined,
  dateIntervention?: string | Date,
  duration?: number
): string => {
  const {
    enseigne,
    numero_magasin,
    ville,
    objet,
    id_client,
    id_mastore,
    specificite_technique,
    specialite,
    complement_objet,
  } = infoIntervention;

  const formattedDate: string = dateIntervention
    ? moment(dateIntervention).format("DD MMMM YYYY [à] HH:mm")
    : "Date non définie";

  const formattedDuration: string = duration
    ? `${moment.duration(duration).hours()} ${
        moment.duration(duration).hours() === 1 ? "heure" : "heures"
      }${
        moment.duration(duration).minutes() > 0
          ? ` ${moment.duration(duration).minutes()} ${
              moment.duration(duration).minutes() === 1 ? "minute" : "minutes"
            }`
          : ""
      }`
    : "non spécifiée";

  const header = `<div>Bonjour,</div><br>`;
  const footer = `<div>Cordialement,</div>
                    <div>Le Service Maintenance</div> 
                    <div>maintenance@mastore.fr</div>
                    <div>04 78 41 61 49</div>`;
  let body = "";

  switch (attribut) {
    case "Mail prévention":
      body = `<div>Vous trouverez ci-dessous la programmation de l'intervention pour :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>DI Client : ${id_client}</div>
<div>DI Mastore : ${id_mastore}</div>
<div>Date d'intervention prévue le ${formattedDate} (pour une durée de ${formattedDuration}).</div><br>
<div>Complément d'objet :</div><br>
<div>${complement_objet}</div><br>
<div>Notre Service Maintenance reste à votre disposition en cas de problèmes.</div><br>
<div>Merci pour votre confiance.</div><br>`;
      break;
    case "Devis Client":
      body = `<div>Vous trouverez ci-joint le ${objectMail} concernant la demande :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>DI Client : ${id_client} , DI Mastore : ${id_mastore}</div><br>
<div>Notre Service Maintenance reste à votre disposition en cas de problèmes.</div><br>
<div>Merci pour votre confiance.</div><br>`;
      break;
    case "Fiche d'Intervention":
      body = `<div>Vous trouverez ci-joint une ${attribut} émise par le Service Maintenance Mastore :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>Spécificité Technique : ${specificite_technique}</div><br>
<div>Voici lien pour remplir votre rapport d'intervention : <a href="${link}">${link}</a></div><br>
<div>Informations importantes :</div><br>
<div>- Se présenter comme intervenant de la société Mastore et ne pas communiquer les prix/devis à l'Enseigne.</div>
<div>- Merci de faire apparaître impérativement sur vos factures :</div>
<ul>
  <li>Pour un Auto-entrepreneur : 'TVA non applicable, article 293B du CGI.'</li>
  <li>Pour une Société : 'Autoliquidation.'</li>
</ul><br>
<div>Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.</div><br>`;
      break;
    case "Fiche Maintenance":
      body = `<div>Vous trouverez ci-joint les informations concernant votre prochaine maintenance :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>Spécificité Technique : ${specificite_technique}</div>
<div>Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.</div>`;
      break;
    case "Rapport":
    case "Pré Rapport":
      body = `<div>Vous trouverez ci-joint le ${attribut} concernant votre demande d'intervention :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>DI Client : ${id_client}</div><br>
<div>L'intervention s'est déroulée dans les meilleures conditions.</div>
${
  attribut === "Pré Rapport"
    ? "<div>Vous recevrez dans les plus brefs délais le rapport de fin d'intervention.</div><br>"
    : ""
}
<div>Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.</div><br>`;
      break;
    case "Rapport préventive":
      body = `<div>Veuillez trouver ci-joint le rapport de la maintenance ${specialite} du mois de ....</div><br>
<div>Notre service reste à votre disposition pour toutes autres questions.</div><br>`;
      break;
    case "Bon de Commande":
    case "Demande de Devis":
      body = `<div>Vous trouverez ci-joint une ${attribut} émise par le Service Maintenance Mastore :</div><br>
<div>Enseigne : ${enseigne} N°${numero_magasin}</div>
<div>Ville : ${ville}</div>
<div>Objet : ${objet}</div>
<div>Spécificité Technique : ${specificite_technique}</div><br>
<div>Informations importantes :</div><br>
<div>- Se présenter comme intervenant de la société Mastore et ne pas communiquer les prix/devis à l'Enseigne.</div>
<div>- Merci de faire apparaître impérativement sur vos factures :</div>
<ul>
  <li>Pour un Auto-entrepreneur : 'TVA non applicable, article 293B du CGI.'</li>
  <li>Pour une Société : 'Autoliquidation.'</li>
</ul><br>
<div>Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.</div><br>`;
      break;
    default:
      body = "<div>Nous restons à votre disposition pour toutes informations complémentaires.</div><br>";
      break;
  }

  return `${header}${body}${footer}`;
};

export default templateEmail;
