import React, { useRef } from 'react';

// MUI
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Tooltip } from '@mui/material';

// Styles
import { IconAttachment } from './DisplayAttachmentStyle';

// Components
import OpenFileDevis from 'components/UI/OpenFileDevis';

export interface Attachment {
  name: string;
  file?: boolean | string;
  url?: string;
  base64_file?: string;
}

export interface DisplayFileProps {
  index: number;
  RemoveElem: (index: number) => void;
  attachment: Attachment;
}

const DisplayFile: React.FC<DisplayFileProps> = ({ index, RemoveElem, attachment }) => {
  const openFileRef = useRef<HTMLButtonElement>(null);

  if (!attachment) return null;

  const hasValidUrl = attachment.url && attachment.url.trim() !== "";
  const hasValidBase64 = attachment.base64_file && attachment.base64_file.trim() !== "";
  const fileContent = hasValidBase64
    ? attachment.base64_file
    : (typeof attachment.file === 'string' && attachment.file.trim() !== "" ? attachment.file : undefined);

  let hasValidFileBool = false;
  if (typeof attachment.file === 'boolean') {
    hasValidFileBool = attachment.file;
  }

  const isNonEmpty = hasValidUrl || (fileContent !== undefined) || hasValidFileBool;
  const isEmpty = !isNonEmpty;

  return (
    <Tooltip title={attachment.name} placement="top" arrow>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <Button
          size="small"
          key={`${attachment.name}-${index}`}
          variant="outlined"
          color={isEmpty ? 'error' : 'primary'}
          onClick={
            !isEmpty
              ? () => {
                  if (openFileRef.current) {
                    openFileRef.current.click();
                  }
                }
              : undefined
          }
          sx={{
            maxWidth: '200px',
            '& .MuiButton-label': {
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            },
            '& .buttonText': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <span className="buttonText">
            {isEmpty ? "Fichier vide ou corrompu" : attachment.name}
          </span>
          {!isEmpty && (
            <OpenFileDevis
              ref={openFileRef}
              url={attachment.url}
              icon={<IconAttachment />}
              base64_file={fileContent}
              name={attachment.name}
            />
          )}
        </Button>
        <IconButton onClick={() => RemoveElem(index)}>
          <CloseIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default DisplayFile;
