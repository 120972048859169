import React, { forwardRef } from 'react';

// LIB
import { useSnackbar } from 'notistack';

// MUI
import { IconButton } from '@mui/material';

// styles
import { TruncatedText, ButtonOpen } from '../UI/DisplayAttachment/DisplayAttachmentStyle';

interface OpenFileProps {
    icon: React.ReactNode;
    url?: string;
    base64_file?: string;
    attachment?: boolean;
    disabled?: boolean;
    name?: string;
}

const OpenFileDevis = forwardRef<HTMLButtonElement, OpenFileProps>(
    ({ icon, url, base64_file, attachment, disabled, name }, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    
    const onClick = () => {
        if (base64_file || url) {
            let fileName = name || null;

            if (base64_file) {
                try {
                    // Extract file extension from `attachment.name`
                    let fileExtension = '';
                    if (fileName) {
                        const parts = fileName.split('.');
                        fileExtension = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : '';
                    }

                    // Default MIME type and extension
                    let mimeType = 'application/pdf';

                    // Determine MIME type based on file extension
                    if (['png', 'jpeg', 'jpg', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
                        mimeType = `image/${fileExtension === 'jpg' ? 'jpeg' : fileExtension}`;
                    }

                    const base64String = base64_file.includes(',') ? base64_file.split(',')[1] : base64_file;
                    const binaryString = window.atob(base64String);
                    const bytes = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }

                    const blob = new Blob([bytes], { type: mimeType });
                    const fileURL = URL.createObjectURL(blob);

                    // Open the file in a new tab
                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.target = '_blank'; // Open in a new tab
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } catch (error) {
                    console.error('Error decoding base64 string:', error);
                    enqueueSnackbar('Erreur lors de la décodage du fichier base64', { variant: 'error' });
                }
            } else if (url) {
                // Handle URL opening in a new tab
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank'; // Open in a new tab
                link.rel = 'noopener noreferrer'; // Recommended for security
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                enqueueSnackbar('Erreur lors de la récupération du fichier', { variant: 'error' });
            }
        } else {
            enqueueSnackbar('Le fichier est introuvable', { variant: 'error' });
        }
    };

return attachment ? (
      <ButtonOpen
        ref={ref}
        size="small"
        onClick={onClick}
        variant="outlined"
        disabled={disabled}
      >
        <TruncatedText>{attachment}</TruncatedText>
      </ButtonOpen>
    ) : (
      <IconButton
        ref={ref}
        aria-label="file"
        size="small"
        onClick={onClick}
        color="primary"
        disabled={disabled}
      >
        {icon}
      </IconButton>
    );
  }
);

export default OpenFileDevis;
