import React from 'react';
import { Helmet } from 'react-helmet-async';
// material
import {
    Typography,
    Popper,
    IconButton,
    Paper,
    Box,
    Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled, alpha } from '@mui/material/styles';
import { STATUTS_COLOR } from 'config'

// ----------------------------------------------------------------------

const Status = styled(Typography)(({ theme, status }) => {
    return {
        background: alpha(STATUTS_COLOR[status], 0.2),
        color: STATUTS_COLOR[status],
        fontWeight: "bold",
        padding: theme.spacing(0.5),
        borderRadius: '3px',
        marginLeft: "auto !important",
        textTransform: "uppercase",
    };
});

// ----------------------------------------------------------------------

export default function InterventionTitle(props) {
    const { infoIntervention, horairesOuverture, infosMagasin } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${infoIntervention.enseigne} - ${infoIntervention.ville} ${infoIntervention.code_postal} [N°${infoIntervention.numero_magasin}]`}
                </title>
            </Helmet>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h2" color={"primary"}>{infoIntervention.enseigne}</Typography>
                <Typography variant="h3" color={"primary"}> {infoIntervention.ville} {infoIntervention.code_postal} [N°{infoIntervention.numero_magasin}]</Typography>
                <IconButton color="primary" aria-label="info magasin" size="small" onClick={handleClick}>
                    <InfoIcon fontSize="inherit" />
                </IconButton>
                <Status variant='h3' status={infoIntervention.status}>{infoIntervention.status}</Status>
            </Stack>
            <Popper id={id} open={open} anchorEl={anchorEl} sx={{ width: "300px" }}>
                <Paper elevation={2} sx={{ p: 2 }}>
                    <Stack spacing={1}>
                        <Box>
                            <Typography variant="h6">Horaires :</Typography>
                            <Typography sx={{ fontStyle: "italic" }}>{horairesOuverture ? horairesOuverture : "Aucun horaire répertorié"}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="h6">Informations concernant le magasin :</Typography>
                            <Typography sx={{fontStyle: "italic"}}>{infosMagasin ? infosMagasin : "Pas d'informations pour le moment"}</Typography>
                        </Box>
                    </Stack>
                </Paper>
            </Popper>
        </React.Fragment >
    )
}