import React, { useState } from 'react';
// material
import {
    Card,
    Stack,
    TextField,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import DescriptionIcon from '@mui/icons-material/Description';
// components
import TableAction from './components/TableAction'
import AlerteDateMax from './components/AlerteDateMax'
import Modal from "components/UI/Modal"
import FicheIntervention from 'components/Intervention/Intervenant/Demande/FicheIntervention/FicheIntervention';
import DemandeDevis from 'components/Intervention/Intervenant/Demande/DemandeDevis/DemandeDevis';
import ModalButton from 'components/UI/ModalButton';
import FacturableButton from './components/FacturableButton';
import moment from 'moment';

const DevisIntervenant = React.lazy(() => import('./modal/DevisIntervenant/DevisIntervenant'));
const FactureIntervenant = React.lazy(() => import('./modal/FactureIntervenant/FactureIntervenant'));

const SelectIntervenant = React.lazy(() => {
    return import('views/Intervention/Intervenant/modal/SelectIntervenant');
});

export const IntervenantView = (props) => {
    const [openSelectIntervenant, setOpenSelectIntervenant] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null)
    const [openFicheIntervention, setOpenFicheIntervention] = useState(false)
    const [openDemandeDevis, setOpenDemandeDevis] = useState(false)
    const [openDevis, setOpenDevis] = useState(false)
    const [openFacture, setOpenFacture] = useState(false)
    // Open datePicker
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const handleSelectIntervenant = (action) => {
        if (openSelectIntervenant) {
            setSelectedAction(null)
            setOpenSelectIntervenant(false)
        } else {
            setSelectedAction(action)
            setOpenSelectIntervenant(true)
        }
    }

    const addIntervenant = (key, value, action_id, setActionsBool) => {
        props.modifyAction(key, value, action_id, setActionsBool)
        handleSelectIntervenant()
    }

    const openActionFacture = (action) => {
        setSelectedAction(action)
        setOpenFacture(true)
    }

    const openActionDevis = (action) => {
        setSelectedAction(action)
        setOpenDevis(true)
    }

    const triggerDateMax = (key, value, old_value, action_id, setActionsBool) => {
        if (old_value === "ATTENTE VALIDATION CLIENT") {
            setOpenDatePicker(true)
        }
        props.modifyAction(key, value, action_id, setActionsBool)
    }

    const isFacture = props.actions.some((item) => item.facture.length > 0)
    const isDevis = props.actions.some((item) => item.bon_de_commande.length > 0)
    return (
        <Card variant="outlined" sx={{ p: 1, flex: '1 1 auto' }}>
            <Stack spacing={1} sx={{ height: "100%" }}>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    {props.infoIntervention.type === "corrective" && (
                        <Stack direction="row" spacing={1} justifyContent="flex-start">
                            <FacturableButton
                                infoIntervention={props.infoIntervention}
                                updateInfoIntervention={props.updateInfoIntervention}
                            />
                        </Stack>
                    )}

                    <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ flex: 1 }}>
                        <AlerteDateMax date_max={props.infoIntervention.date_max} />
                        <MobileDatePicker
                            sx={{
                                '.MuiInputBase-input': {
                                    padding: "8px 10px !important",
                                },
                            }}
                            open={openDatePicker}
                            onOpen={() => setOpenDatePicker(true)}
                            onClose={() => setOpenDatePicker(false)}
                            label="Date au plus tard"
                            format="D MMM YYYY"
                            value={props.infoIntervention.date_max ? moment(props.infoIntervention.date_max) : null}
                            showDaysOutsideCurrentMonth={true}
                            closeOnSelect
                            onChange={(newDate) => {
                                props.updateInfoIntervention(newDate.toDate(), 'date_max');
                            }}
                        />
                    </Stack>
                </Stack>
         
                <TableAction
                    user={props.user}
                    actions={props.actions}
                    infoIntervention={props.infoIntervention}
                    openActionFacture={openActionFacture}
                    openActionDevis={openActionDevis}
                    //Date Intervention Handlers
                    newDateIntervention={props.newDateIntervention}
                    modifyDateIntervention={props.modifyDateIntervention}
                    deleteDateIntervention={props.deleteDateIntervention}
                    //Actions Handlers
                    newAction={props.newAction}
                    modifyAction={props.modifyAction}
                    deleteAction={props.deleteAction}
                    handleSelectIntervenant={handleSelectIntervenant}
                    triggerDateMax={triggerDateMax}
                    hasSentDevis={props.hasSentDevis}
                />
             
                <Stack spacing={1}>
                    <TextField
                        multiline
                        label="Spécificité(s) technique(s)"
                        defaultValue={props.infoIntervention.specificite_technique}
                        placeholder="Spécificité(s) technique(s)"
                        variant="outlined"
                        size="small"
                        rows={4}
                        rowsmax={4}
                        onBlur={(event) => props.updateInfoIntervention(event.target.value, 'specificite_technique')}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Stack direction="row" justifyContent="space-around">
                        <ModalButton name="Demande de devis" filled={props.demandeDevisLength > 0} clickHandler={() => { setOpenDemandeDevis(true) }} icon={<DescriptionIcon />} />
                        <ModalButton name="Demande d'intervention" filled={props.demandeInterLength > 0} clickHandler={() => { setOpenFicheIntervention(true) }} icon={<DescriptionIcon />} />
                        <ModalButton name="Devis / BDC" filled={isDevis} clickHandler={() => { setOpenDevis(true) }} icon={<DescriptionIcon />} />
                        <ModalButton name="Facture intervenant" filled={isFacture} clickHandler={() => { setOpenFacture(true) }} icon={<DescriptionIcon />} />
                        <Modal open={openDemandeDevis} close={() => setOpenDemandeDevis(false)} sx={{ width: { md: "60vw", sm: "70vw", xs: "85vw" }, maxHeight: "80vh" }}>
                            <DemandeDevis
                                infoIntervention={props.infoIntervention}
                                modalClosed={() => setOpenDemandeDevis(false)}
                                actions={props.actions}
                                createDemandeDevis={props.createDemandeDevis}
                                saveDemandeDevis={props.saveDemandeDevis}
                                resume={props.resumeDemandeDeDevis}
                                fileDemandeDevisIntervenant={props.fileDemandeDevisIntervenant}
                                user={props.user}
                            />
                        </Modal>
                        <Modal open={openFicheIntervention} close={() => setOpenFicheIntervention(false)} sx={{ width: { md: "60vw", sm: "70vw", xs: "85vw" }, maxHeight: "80vh" }}>
                            <FicheIntervention
                                infoIntervention={props.infoIntervention}
                                modalClosed={() => setOpenFicheIntervention(false)}
                                actions={props.actions}
                                createFicheIntervention={props.createFicheIntervention}
                                saveFicheInter={props.saveFicheInter}
                                resume={props.resumeFicheIntervention}
                                fileFicheInterventionIntervenant={props.fileFicheInterventionIntervenant}
                                user={props.user}
                            />
                        </Modal>
                        <Modal open={openDevis} close={() => setOpenDevis(false)}>
                            <DevisIntervenant
                                infoIntervention={props.infoIntervention}
                                actions={props.actions}
                                setActions={props.setActions}
                                pilote_mail={props.user.mail}
                                pilote_name={`${props.user.firstname} ${props.user.lastname}`}
                                selectedAction={selectedAction}
                                user={props.user}
                            />
                        </Modal>
                        <Modal open={openFacture} close={() => setOpenFacture(false)}>
                            <FactureIntervenant
                                infoIntervention={props.infoIntervention}
                                actions={props.actions}
                                selectedAction={selectedAction}
                                user={props.user}
                                setActions={props.setActions}
                            />
                        </Modal>
                    </Stack>
                </Stack>
            </Stack>
            <Modal open={openSelectIntervenant} close={handleSelectIntervenant} sx={{ width: { md: "95vw", sm: "95vw", xs: "95vw" }, height: "95vh" }}>
                <SelectIntervenant
                    infoIntervention={props.infoIntervention}
                    addIntervenant={addIntervenant}
                    selectedAction={selectedAction}
                    open={false}
                />
            </Modal>
        </Card >
    );
}