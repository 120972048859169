import React, { useEffect, useState } from "react";
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import FormHelperText from '@mui/material/FormHelperText';
import { MuiTelInput } from 'mui-tel-input'
import { EditUser } from 'services';
import { GetPilote } from "services";

import {
    Typography,
    DialogContent,
    Stack,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    InputLabel,
    Select,
    FormControl,
    MenuItem,
    FormGroup,
} from '@mui/material';

const EditUserSchema = yup.object({
    firstname: yup.string().typeError("Votre saisie est incorrecte merci de vérifier "),
    lastname: yup.string().typeError("Votre saisie est incorrecte merci de vérifier "),
    username: yup.string().typeError("Votre saisie est incorrecte merci de vérifier "),
    mail: yup.string().email().typeError('email incorrect'),
}).required();

const defaultUser = {
    id: "",
    first_name: "",
    last_name: "",
    user_id: "",
    username: "",
    mail: "",
    telephone: "",
    pilote: false,
    attached_to: "",
    role: "",
    administrateur: false,
    entreprise: "",
    is_active: true, // Ajout
}

export default function EditUserForm({ handleClose, user = defaultUser, setDataUpdate }) {
    const [pilotes, setPilotes] = useState([])
    const [phoneNumber, setPhoneNumber] = useState(user.telephone)
    const { enqueueSnackbar } = useSnackbar();

    const { register, handleSubmit, formState: { errors }, control } = useForm({
        resolver: yupResolver(EditUserSchema), defaultValues: user
    });

    useEffect(() => {
        Promise.all([
            GetPilote(),
        ]).then(([
            responseGetPilote,
        ]) => {
            setPilotes(responseGetPilote.pilotes)
        }).catch(err => {
            console.log(err);
        });
    }, [])

    const phoneChangeHandler = (value) => {
        setPhoneNumber(value);
    }

    const onSubmitForm = (inputUser) => {
        inputUser.attached_to = inputUser.attached_to === 0 ? 0 : parseInt(inputUser.attached_to);
        inputUser.telephone = phoneNumber;
    
        EditUser(inputUser)
            .then(() => {
                handleClose();
                setDataUpdate(true);
                enqueueSnackbar('Utilisateur modifié !', {
                    variant: 'success',
                });
            })
            .catch(err => {
                enqueueSnackbar('Error lors de la modification de l\'utilisateur', {
                    variant: 'error',
                });
                console.log(err);
            });
    };

    return (
        <div style={{ width: 600, height: "auto" }}>
            <DialogContent>
                <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
                    Modifier l'utilisateur
                </Typography>
                <form onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center' }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={user.entreprise}
                            >
                                <FormControlLabel {...register("entreprise")} value="Mastore" control={<Radio size="small" required={true} />} label="Mastore" />
                                <FormControlLabel {...register("entreprise")} value="Mastore Ouest" control={<Radio size="small" required={true} />} label="Mastore Ouest" />
                                <FormControlLabel {...register("entreprise")} value="Mastore Développeur" control={<Radio size="small" required={true} />} label="Mastore Développeur" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth size="small" {...register("firstname")} name="firstname" label="Prénom" error={errors.firstname?.message} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth size="small" {...register("lastname")} name="lastname" label="Nom" error={errors.lastname?.message} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth size="small" {...register("username")} label="Nom d'utilisateur" error={errors.username?.message} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField InputLabelProps={{ shrink: true }} fullWidth size="small" {...register("mail")} label="Email" error={errors.mail?.message} />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="role"
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel size="small" variant="outlined">Rôle</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            label="Rôle"
                                            size="small"
                                            value={value}
                                        >
                                            <MenuItem value={"Utilisateur"}>Utilisateur</MenuItem>
                                            <MenuItem value={"Comptable"}>Comptable</MenuItem>
                                            <MenuItem value={"Assistant(e)"}>Assistant(e)</MenuItem>
                                            <MenuItem value={"Développeur"}>Développeur</MenuItem>
                                            <MenuItem value={"Chargé(e) d'affaires adjoint(e)"}>Chargé(e) d'affaires adjoint(e)</MenuItem>
                                            <MenuItem value={"Chargé(e) d'affaires"}>Chargé(e) d'affaires</MenuItem>
                                            <MenuItem value={"Responsable maintenance"}>Responsable maintenance</MenuItem>


                                        </Select>
                                        {errors.role && <FormHelperText error >Veuillez renseigner le role</FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="attached_to"
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel size="small" variant="outlined">Assigné à</InputLabel>
                                        <Select
                                            onChange={onChange}
                                            label="Assigné à"
                                            size="small"
                                            value={value}
                                        >
                                            <MenuItem value={0}>Aucun</MenuItem>
                                            {pilotes.map((item, index) => (
                                                <MenuItem key={index} value={JSON.stringify(item.ID)}> {item.firstname + ' ' + item.lastname} </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.pilote && <FormHelperText error >Veuillez renseigner le pilote</FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MuiTelInput
                                defaultCountry={'FR'}
                                value={phoneNumber}
                                onChange={phoneChangeHandler}
                                onlyCountries={['FR']}
                                size="small"
                                label="Téléphone portable"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center' }}>

                            <Stack spacing={2}>

                                <FormGroup row>
                                    <FormControlLabel {...register("pilote")} control={<Controller
                                        control={control}
                                        name='pilote'
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                            <Checkbox onChange={onChange} checked={value}>
                                            </Checkbox>
                                        )}
                                    />} label="Pilote" />
                                    <FormControlLabel {...register("administrateur")} control={<Controller
                                        control={control}
                                        name='administrateur'
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                            <Checkbox onChange={onChange} checked={value}>
                                            </Checkbox>
                                        )}
                                    />} label="Administrateur" />
                                    {/* Nouvelle checkbox */}
                                    <FormControlLabel
                                        {...register("is_active")}
                                        control={<Controller
                                            control={control}
                                            name='is_active'
                                            defaultValue={user.is_active}
                                            render={({ field: { onChange, value } }) => (
                                                <Checkbox onChange={onChange} checked={value}>
                                                </Checkbox>
                                            )}
                                        />}
                                        label="Utilisateur actif"
                                    />

                                </FormGroup>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', mt: 1 }}>
                            <LoadingButton type="submit" variant="outlined" size="small">
                                Modifier
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </div >
    );
}


