import React from 'react';
import * as mapboxgl from 'mapbox-gl';
import { Component } from 'react';
import { MAPBOX_ACCESS_TOKEN } from 'config/index';

import './map.css';

// Contournement de l'erreur de lecture seule en castant mapboxgl en any
((mapboxgl as any).accessToken as string) = MAPBOX_ACCESS_TOKEN;
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface Intervenant {
  properties: {
    description: string;
  };
  geometry: {
    coordinates: [number, number];
  };
}

interface MapboxProps {
  intervenantLocation?: Intervenant[];
  selectedVille?: [number, number];
  siteCoordinates?: [number, number];
}

interface MapboxState {
  lng: number;
  lat: number;
  zoom: number;
  zoneMarker: mapboxgl.Marker | null;
  markers: mapboxgl.Marker[];
  maps: mapboxgl.Map | null;
}

class Mapbox extends Component<MapboxProps, MapboxState> {
  private mapContainer: HTMLDivElement | null = null;

  constructor(props: MapboxProps) {
    super(props);
    this.state = {
      lng: 2,
      lat: 46,
      zoom: 5,
      zoneMarker: null,
      markers: [],
      maps: null,
    };
  }

  componentDidUpdate(prevProps: MapboxProps) {
    if (
      this.props.intervenantLocation !== prevProps.intervenantLocation &&
      this.state.maps !== null
    ) {
      let newMarkers: mapboxgl.Marker[] = [];
      let newZoneMarker = this.state.zoneMarker;

      // modifie la position des intervenants
      this.state.markers.forEach(item => {
        item.remove();
      });
      if (this.props.intervenantLocation) {
        this.props.intervenantLocation.forEach(marker => {
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            marker.properties.description
          );
          newMarkers.push(
            new mapboxgl.Marker({ color: "red" })
              .setLngLat(marker.geometry.coordinates)
              .setPopup(popup)
              .addTo(this.state.maps!)
          );
        });
      }

      // modifie la position de la ville sélectionnée
      if (this.props.selectedVille) {
        newZoneMarker?.remove();
        newZoneMarker = new mapboxgl.Marker({ color: '#314cce' })
          .setLngLat(this.props.selectedVille)
          .addTo(this.state.maps!);
        this.state.maps.flyTo({ center: this.props.selectedVille, zoom: 7 });
      } else if (!this.props.selectedVille) {
        newZoneMarker?.remove();
        newZoneMarker = null;

        this.state.maps.flyTo({ center: [this.state.lng, this.state.lat], zoom: this.state.zoom });
        this.state.markers.forEach(item => { item.remove(); });
      }
      this.setState({ markers: newMarkers, zoneMarker: newZoneMarker });
    }
  }

  componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer as HTMLElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: this.props.selectedVille ? this.props.selectedVille : [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
    });
    let newMarkers: mapboxgl.Marker[] = [];
    let newZoneMarker: mapboxgl.Marker | null = null;
    map.on('load', () => {
      // Affiche les intervenants
      if (this.props.intervenantLocation) {
        this.props.intervenantLocation.forEach(marker => {
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(marker.properties.description);
          newMarkers.push(
            new mapboxgl.Marker({ color: "red" })
              .setLngLat(marker.geometry.coordinates)
              .setPopup(popup)
              .addTo(map)
          );
        });
      }
      // Affiche la ville sélectionnée
      if (this.props.selectedVille) {
        newZoneMarker = new mapboxgl.Marker({ color: '#314cce' })
          .setLngLat(this.props.selectedVille)
          .addTo(map);
        map.flyTo({ center: this.props.selectedVille, zoom: 5 });
      }

      // Affiche la ville du magasin de l'intervention
      if (this.props.siteCoordinates) {
        new mapboxgl.Marker({ color: 'green' })
          .setLngLat(this.props.siteCoordinates)
          .addTo(map);
      }

      this.setState(prevState => ({
        ...prevState,
        markers: newMarkers,
        maps: map,
        zoneMarker: newZoneMarker
      }));
    });
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%", overflow: "hidden", position: "absolute" }}>
        <div ref={el => this.mapContainer = el} className="mapContainer" />
      </div>
    );
  }
}

export default Mapbox;
