import 'moment/locale/fr';
import moment from 'moment';

export function isToday(someDate) {
    const today = new Date()
    someDate = new Date(someDate)
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}


export function dateMaxAlert(date_max) {
    const dateMax = new Date(date_max)
    const dateToDay = moment().toDate()

    const lastWeek = new Date(date_max) // comparaison 7 jours avant la date max
    lastWeek.setDate(lastWeek.getDate() - 7);

    const threeDay = new Date(date_max) // comparaison 3 jours avant la date max
    threeDay.setDate(threeDay.getDate() - 3);

    if (dateToDay.getDate() === dateMax.getDate() && dateToDay.getMonth() === dateMax.getMonth() && dateToDay.getFullYear() === dateMax.getFullYear())
        return 'urgent'
    if (dateToDay.getTime() > dateMax.getTime())
        return 'error'
    if (dateToDay > threeDay)
        return 'warning'
    if (dateToDay > lastWeek)
        return 'info'
    return 'success'
}


export function formatDate(date, format) {
    return moment(date).format(format)
}

export function getYear(date, format) {
    return moment(date).year()
}

export function GetLatestDate(dates) {
    if (dates.length === 0) {
        return null
    }
    return (
        new Date(Math.max.apply(null, dates.map(function (e) {
            return new Date(e.date);
        })))
    )
}
export function closestDateForSort(DateIntervention, dateAction, etat) {
    const now = new Date();
    if ((etat === "A SUIVRE" || etat === "A CLORE") && Array.isArray(DateIntervention) && DateIntervention.length > 0) {
        const todayIntervention = DateIntervention.find(item => {
            const d = new Date(item.date);
            return d.getFullYear() === now.getFullYear() &&
                d.getMonth() === now.getMonth() &&
                d.getDate() === now.getDate();
        });
        if (todayIntervention) {
            return { date: todayIntervention.date, highlight: true };
        }
        let ClosestDate = DateIntervention[0].date;
        DateIntervention.forEach(item => {
            const currentDate = new Date(item.date);
            const chosenDate = new Date(ClosestDate);
            if (chosenDate < now) {
                if (currentDate < now && currentDate > chosenDate)
                    ClosestDate = item.date;
                if (currentDate >= now)
                    ClosestDate = item.date;
            } else {
                if (currentDate >= now && currentDate < chosenDate)
                    ClosestDate = item.date;
            }
        });
        return { date: ClosestDate, highlight: false };
    }
    const newDateAction = new Date(dateAction);
    return {
        date: (newDateAction.getMonth() + 1) + '/' + newDateAction.getDate() + '/' + newDateAction.getFullYear(),
        highlight: false
    };
}
