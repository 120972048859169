const typography = {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    htmlFontSize: 15,
    fontSize: 12,
    body1: {
        fontSize: "12px",
        '@media (max-width: 1600px)': {
            fontSize: "10px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "8px !important",
        },
        '@media (max-width: 900px)': {
            fontSize: "10px !important",
        }
    },
    body2: {
        fontSize: "12px !important",
        '@media (max-width: 1600px)': {
            fontSize: "10px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "8px !important",
        },
        '@media (max-width: 900px)': {
            fontSize: "10px !important",
        }
    },
    h1: {
        fontSize: "24px !important",
        fontWeight: "bold !important",
        '@media (max-width: 1600px)': {
            fontSize: "22px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "20px !important",
        }
    },
    h2: {
        fontSize: "22px !important",
        fontWeight: "bold !important",
        '@media (max-width: 1600px)': {
            fontSize: "20px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "18px !important",
        }
    },
    h3: {
        fontSize: "20px !important",
        fontWeight: "700",
        '@media (max-width: 1600px)': {
            fontSize: "18px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "16px !important",
        }
    },
    h4: {
        fontSize: "18px !important",
        fontWeight: "700",
        '@media (max-width: 1600px)': {
            fontSize: "16px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "14px !important",
        }
    },
    h5: {
        fontSize: "16px !important",
        fontWeight: "bold",
        '@media (max-width: 1600px)': {
            fontSize: "14px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "12px !important",
        }
    },
    h6: {
        fontSize: "13px !important",
        lineHeight: "1.4",
        fontWeight: "bold",
        '@media (max-width: 1600px)': {
            fontSize: "11px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "9px !important",
        }
    },
    h6v2: {
        fontSize: "13px !important",
        lineHeight: "1.4",
        '@media (max-width: 1600px)': {
            fontSize: "11px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "9px !important",
        }
    },
    button: {
        fontSize: "12px !important",
        '@media (max-width: 1600px)': {
            fontSize: "10px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "8px !important",
        },
    },
    subtitle2: {
        fontSize: "8px !important",
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        textTransform: 'uppercase',
    },
    h7: {
        fontSize: "10px !important",
        fontWeight: "bold",
        '@media (max-width: 1600px)': {
            fontSize: "8px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "8px !important",
        }
    },
    h7v2: {
        fontSize: "10px !important",
        '@media (max-width: 1600px)': {
            fontSize: "8px !important",
        },
        '@media (max-width: 1300px)': {
            fontSize: "8px !important",
        }
    }
};

export default typography;