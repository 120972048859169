import { Select, MenuItem } from "@mui/material";
import { STATUTS_COMMANDE } from "config";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const SelectStyled = styled(Select)(({ value }) => {
  return {
    backgroundColor: STATUTS_COMMANDE[value],
    color: "white",
    fontWeight: "bold",
    ".MuiSvgIcon-root": {
      color: "rgb(246, 246, 246)",
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: `rgb(196, 196, 196) !important`,
      borderWidth: "1px !important",
    },
    ".MuiOutlinedInput-input": {
      padding: "8px 10px",
    },
  };
});

export default function SelectStatusFiche({ value, clickHandler, ...other }) {
  return (
    <SelectStyled
      value={value}
      size="small"
      name="etat"
      onChange={(event) => clickHandler(event.target.value)}
      fullWidth
      label="Etat"
    >
      <MenuItem value={"À ENVOYER"}>À ENVOYER</MenuItem>
      <MenuItem value={"ENVOYÉE"}>ENVOYÉE</MenuItem>
      <MenuItem value={"COMMANDE LIVRÉE"}>COMMANDE LIVRÉE</MenuItem>
    </SelectStyled>
  );
}

SelectStatusFiche.propTypes = {
  value: PropTypes.string,
  clickHandler: PropTypes.func,
  color: PropTypes.oneOf(["À ENVOYER", "ENVOYÉE", "COMMANDE LIVRÉE"]),
};
