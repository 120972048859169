import React from 'react';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TableRow, TableCell, Tooltip, TableCellProps } from '@mui/material';

interface TableCellType {
    id: string;
    type: string;
    label: string;
    width: string;
}

interface ModuleDevisTableBodyProps {
    modules: any[];
    tableCells: TableCellType[];
    onRowClick: (module: any) => void;
}

interface CustomStyledTableCellProps extends TableCellProps {
    maxwidth?: string;
}

const StyledTableCell = styled(TableCell)<CustomStyledTableCellProps>(({ maxwidth }) => ({
    height: "35px",
    maxWidth: maxwidth,
    padding: '0px 10px',
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f6f9fd",
    },
    cursor: "pointer",
}));

const VerticalEllipsis = styled('div')(() => ({
    overflow: "hidden",
    WebkitLineClamp: 1,
    display: "-webkit-box",
    textOverflow: "ellipsis",
    WebkitBoxOrient: "vertical",
}));

const ModuleDevisTableBody: React.FC<ModuleDevisTableBodyProps> = ({ modules, tableCells, onRowClick }) => {
    return (
        <>
            {modules.map((module) => (
                <StyledTableRow key={module.ID} hover onClick={() => onRowClick(module)} tabIndex={-1}>
                    {tableCells.map((tableCell, cellIndex) => {
                        let cellWidth = tableCell.width;
                        let textAlign: 'left' | 'center' | 'right' = 'left';

                        if (
                            tableCell.id === 'unite' ||
                            tableCell.id === 'quantite' ||
                            tableCell.id === 'prixCorrectiveDefaut' ||
                            tableCell.id === 'prixPreventiveDefaut'
                        ) {
                            cellWidth = '5%';
                        }

                        if (tableCell.type === "number") {
                            const numberValue = module[tableCell.id];
                            return (
                                <StyledTableCell key={cellIndex} align={textAlign} sx={{ minWidth: cellWidth, maxWidth: cellWidth }}>
                                    {typeof numberValue === 'number' ? numberValue.toFixed(2) : 'N/A'} €
                                </StyledTableCell>
                            );
                        } else if (tableCell.type === "boolean") {
                            return (
                                <StyledTableCell key={cellIndex} align={textAlign} sx={{ minWidth: cellWidth, maxWidth: cellWidth }}>
                                    {module[tableCell.id] ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
                                </StyledTableCell>
                            );
                        } else {
                            const cellContent = module[tableCell.id];
                            const tooltip = <div dangerouslySetInnerHTML={{ __html: cellContent || "Pas d'information" }}/>;
                            return (
                                <Tooltip key={cellIndex} title={tooltip} placement='bottom-start' arrow disableInteractive>
                                    <StyledTableCell key={cellIndex} align={textAlign} sx={{ minWidth: cellWidth, maxWidth: cellWidth }}>
                                        <VerticalEllipsis>
                                            <span dangerouslySetInnerHTML={{ __html: cellContent }}/>
                                        </VerticalEllipsis>
                                    </StyledTableCell>
                                </Tooltip>
                            );
                        }
                    })}
                </StyledTableRow>
            ))}
        </>
    );
};

export default ModuleDevisTableBody;
