import React, { useState } from 'react';

// Components
import FlexFooter from "components/UI/FlexFooter";

// types
import { Module } from '../types';

// components
import CustomEditor from 'components/UI/CustomEditor';

// MUI
import Grid from '@mui/material/Unstable_Grid2';
import MultiSpecialite from 'components/UI/Specialite/MultiSpecialite';
import { Button, Divider, Stack, TextField, Box } from '@mui/material';

const ModalAddModule: React.FC<{ addModule: (module: Module) => void }> = ({ addModule }) => {

    const [module, setModule] = useState<Module>({
        ID: 0,
        name: '',
        unite: '',
        quantite: 10,
        specialite: [],
        description: '',
        commentaire: '',
        prixPreventiveDefaut: 100,
        prixCorrectiveDefaut: 200,
    });

    type CustomEvent = { name: string; value: any };

    const handleChange = (event: CustomEvent) => {
        if (event.name === 'prixCorrectiveDefaut' || event.name === 'prixPreventiveDefaut' || event.name === 'quantite') {
            event.value = Number(event.value);
        }

        setModule(prevModule => ({
            ...prevModule,
            [event.name]: event.value,
        }));
    };

    const isValid = module.description && module.commentaire && (module.specialite !== undefined && module.specialite.length > 0);

    return (
        <React.Fragment>
            <Stack spacing={1} flexGrow={1} padding={2}>
                <Grid container alignItems="center" paddingLeft={2} paddingRight={2} paddingTop={2} spacing={1}>
                    <Grid xs={6}>
                        <TextField
                            fullWidth
                            size='small'
                            name='name'
                            autoComplete='off'
                            value={module.name}
                            label="Nom du module"
                            onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <MultiSpecialite
                            disabled={false}
                            name="specialite"
                            value={module.specialite}
                            changeSpecialite={handleChange}
                        />
                    </Grid>
                </Grid>
                <Divider variant='middle' />
                <Grid container xs={12} paddingLeft={2} paddingRight={2} paddingTop={2} sx={{ height: 'auto', flexGrow: 1 }}>
                    <Grid xs={12} sm={12}>
                        <Box sx={{ height: { xs: "150px", sm: "160px", md: "200px" }, maxHeight: "40vh"}}>
                            <CustomEditor
                                initialData={module?.description || ""}
                                onSave={(data) => handleChange({ name: "description", value: data })}
                                placeholder="Entrez la description ici..."
                                customStyles={{ height: "100%" }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12}>
                        <Box sx={{ height: { xs: "80px", sm: "60px", md: "60px" }, maxHeight: "20vh" }}>
                            <CustomEditor
                                initialData={module?.commentaire || ""}
                                onSave={(data) => handleChange({ name: "commentaire", value: data })}
                                placeholder="Entrez le commentaire ici..."
                                customStyles={{ height: "100%" }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container textAlign={"center"}>
                    <Grid xs={3}>
                        <TextField autoComplete='off' size='small' name='unite' label="Unité" value={module.unite} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' size='small' name='quantite' type='number' label="Quantité" value={module.quantite} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' type='number' size='small' name='prixCorrectiveDefaut' label="Prix Corrective" value={module.prixCorrectiveDefaut} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' type='number' size='small' name='prixPreventiveDefaut' label="Prix Préventive" value={module.prixPreventiveDefaut} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                </Grid>
            </Stack>
            <FlexFooter custompadding={2}>
                <Button variant="outlined" size="small" onClick={() => addModule(module)} disabled={!isValid}>Créer</Button>
            </FlexFooter>
        </React.Fragment>
    );
};

export default ModalAddModule;
