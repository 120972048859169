import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ImageIcon from '@mui/icons-material/Image';

export const Attachment = styled('span')(() => ({
    color: 'rgb(49, 102, 144)',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    width: "220px",
}))

export const CustomIcon = styled(ClearIcon)(() => ({
    color: "grey",
    fontSize: "15px",
}))

export const TruncatedText = styled('span')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '8px',
    flex: '1 1 auto',
});

export const CustomAttachment = styled('div')({
    display: 'flex',
    alignItems: 'center',
    maxWidth: '200px',
    overflow: 'hidden'
});


export const ButtonOpen = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

export const IconAttachment = styled(ImageIcon)(() => ({
    color: '#316690',
    display: "flex",
    margin: "auto",
}))
