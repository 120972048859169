import React, { ReactNode } from 'react';
import DashboardLayout from 'navigation/index';
import { Box } from '@mui/material';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <Box>
    <DashboardLayout>
      {children}
    </DashboardLayout>
  </Box>
);

export default Layout;
