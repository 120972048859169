import imageCompression from 'browser-image-compression';

export async function FileToB64(file: File): Promise<string> {
    const base64 = await imageCompression.getDataUrlFromFile(file);
    return base64.replace('data:', '').replace(/^.+,/, '');
}

export async function ResizeImage(image: File): Promise<string | undefined> {
    const imageFile = image;

    const options = {
        maxSizeMB: 0.2,           // Taille maximale de 0.2MB
        maxWidthOrHeight: 1280,   // Résolution réduite à 1280 pixels
        useWebWorker: true
    };

    try {
        const compressedFile = await imageCompression(imageFile, options);
        const base64 = await FileToB64(compressedFile);
        return base64;
    } catch (error) {
        console.log(error);
    }
}
