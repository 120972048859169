import React from 'react';

// mui
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers';

// lib
import moment, { Moment } from 'moment';

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  '.MuiInputBase-input': {
    padding: '5px 10px !important',
  },
}));

interface TableMobileDatePickerProps
  extends Omit<React.ComponentProps<typeof MobileDatePicker>, 'value' | 'format' | 'slotProps'> {
  inputFormat: string;
  value?: Moment | string | Date | null;
}

const TableMobileDatePicker: React.FC<TableMobileDatePickerProps> = (props) => {
  const { value, inputFormat, ...other } = props;

  const computedValue: Moment | null =
    value && !moment.isMoment(value) ? moment(value) : (value as Moment) || null;

  return (
    <MobileDatePicker
      {...other}
      format={inputFormat}
      value={computedValue}
      closeOnSelect
      slots={{
        textField: StyledTextField,
      }}
      slotProps={{
        textField: {
          value: computedValue,
        },
      }}
    />
  );
};

export default React.memo(TableMobileDatePicker);
