import React, { useState, useEffect, Suspense } from 'react';

// lib
import axios from 'axios';
import queryString from 'query-string';

// hooks
import withMySnackbar from 'hooks/withMySnackbar';
import ConfirmDialog from 'hooks/ConfirmDialog';

// mui
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
    Button, DialogActions, DialogTitle, DialogContentText, Dialog,
    DialogContent, Stack, Grid, TextField
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

// components
import ClientView from './Client';
import Modal from 'components/UI/Modal';
import IntervenantView from './Intervenant';
import ButtonBar from './ButtonBar/ButtonBar';
import InformationView from './InfoIntervention';
import Spinner from 'components/UI/Spinner/Spinner';
import Plan from 'components/Intervention/UploadFiles/Plan/Plan';
import Document from 'components/Intervention/UploadFiles/Document/Document';
import Inventaire from 'components/Intervention/UploadFiles/Inventaire/InventaireContainer';
import DialogValid from "./DialogValid";

const Photo = React.lazy(() => import('./InfoIntervention/modal/Photo/Photo'));
const Historique = React.lazy(() => import('./InfoIntervention/modal/Historique/Historique'));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InterventionView = (props) => {
  // États initialisés (issus de la classe)
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [getValue, setGetValue] = useState(false);
  const [filesPlan, setFilesPlan] = useState([]);
  const [filesDocument, setFilesDocument] = useState([]);
  const [filesInventaire, setFilesInventaire] = useState([]);
  const [fileDevisClient, setFileDevisClient] = useState([]);
  const [fileDemandeInitial, setFileDemandeInitial] = useState([]);
  const [resumeDemandeDeDevis, setResumeDemandeDeDevis] = useState([]);
  const [resumeFicheIntervention, setResumeFicheIntervention] = useState([]);
  const [dataFacturation, setDataFacturation] = useState(null);
  const [openAlertRequest, setOpenAlertRequest] = useState(false);
  const [alertRequest, setAlertRequest] = useState(null);
  const [horairesOuverture, setHorairesOuverture] = useState("");
  const [infosMagasin, setInfosMagasin] = useState("");
  const [intervenant, setIntervenant] = useState([]);
  const [fileFicheInterventionIntervenant, setFileFicheInterventionIntervenant] = useState([]);
  const [fileRemoveFicheInterventionIntervenant, setFileRemoveFicheInterventionIntervenant] = useState([]);
  const [fileDemandeDevisIntervenant, setFileDemandeDevisIntervenant] = useState([]);
  const [fileRemoveDemandeDevisIntervenant, setFileRemoveDemandeDevisIntervenant] = useState([]);
  const [update, setUpdate] = useState(false);
  const [dialogArchive, setDialogArchive] = useState(false);
  const [dialogCorbeille, setDialogCorbeille] = useState(false);
  const [dialogRestaure, setDialogRestaure] = useState(false);
  const [dialogValid, setDialogValid] = useState(false);
  const [uploadInventaire, setUploadInventaire] = useState(false);
  const [uploadDocument, setUploadDocument] = useState(false);
  const [uploadPlan, setUploadPlan] = useState(false);
  const [modalPhoto, setModalPhoto] = useState(false);
  const [modalHistorique, setModalHistorique] = useState(false);
  const [boolPhotos, setBoolPhotos] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isAllActionsClosed, setIsAllActionsClosed] = useState([]);
  const [isAllDevisValid, setIsAllDevisValid] = useState([]);
  const [dialogFinish, setDialogFinish] = useState(false);
  const [currentUserControl, setCurrentUserControl] = useState("");
  const [modalFicheInter, setModalFicheInter] = useState(false);
  const [modalDemandeDevisIntervenant, setModalDemandeDevisIntervenant] = useState(false);
  const [filesDevisIntervenant, setFilesDevisIntervenant] = useState([]);

  // États additionnels
  const [commentaire, setCommentaire] = useState("");

  // Fermeture de l'alerte
  const handleCloseAlertRequest = (event, reason) => {
    setOpenAlertRequest(false);
    setAlertRequest(null);
  };

  // Traitement avant déchargement de la page
  const doSomethingBeforeUnload = () => {
    if (props.infoIntervention.on_change !== "") {
      const header = {
        IDMastore: props.infoIntervention.id_mastore,
        Onchange: "",
      };
      axios.defaults.withCredentials = true;
      axios.post('/legacy/ChangeOnModifIntervention', header)
        .then(() => { })
        .catch(error => {
          console.error("Erreur lors de la mise à jour du statut on_change", error);
        });
    }
  };

  // Mise en place des écouteurs d'évènements pour beforeunload, locationchange et unload
  useEffect(() => {
    const handleBeforeUnload = (ev) => {
      ev.preventDefault();
      doSomethingBeforeUnload();
    };

    const handleLocationChange = (ev) => {
      ev.preventDefault();
      doSomethingBeforeUnload();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("locationchange", handleLocationChange);
    window.addEventListener("unload", doSomethingBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("locationchange", handleLocationChange);
      window.removeEventListener("unload", doSomethingBeforeUnload);
      doSomethingBeforeUnload();
    };
  }, [props.infoIntervention.on_change]);

  // Prendre le contrôle de l'intervention
  const takeTheControlOfTheIntervention = () => {
    const header = {
      IDMastore: props.infoIntervention.id_mastore,
      Onchange: props.user.userID, 
    };

    axios.defaults.withCredentials = true;
    axios.post('/legacy/ChangeOnModifIntervention', header)
      .then(() => {
        setCurrentUserControl(props.user.userID);
      })
      .catch(error => {
        props.enqueueSnackbar('Erreur lors de la prise de contrôle.', { variant: 'error' });
      });
  };

  // Sauvegarde de la fiche d'intervention
  const saveFicheInter = (fileFicheInterventionIntervenantParam, fileRemoveFicheInterventionIntervenantParam) => {
    setFileFicheInterventionIntervenant(fileFicheInterventionIntervenantParam);
    setFileRemoveFicheInterventionIntervenant(fileRemoveFicheInterventionIntervenantParam);
    setUpdate(true);
  };

  // Récupération des données
  useEffect(() => {
    const params = getParamUrl();
    const header = { id_mastore: params["IDMastore"] };

    axios.defaults.withCredentials = true;
    axios.post('/legacy/GetInterventionByID', header)
      .then(response => {
        setIntervenant(props.actions);
        setGetValue(true);
        setPageLoading(false);

        if (response.data.filesPlans)
          setFilesPlan(response.data.filesPlans);
        if (response.data.filesDocuments)
          setFilesDocument(response.data.filesDocuments);
        if (response.data.filesInventaires)
          setFilesInventaire(response.data.filesInventaires);
        if (response.data.demandeInitial)
          setFileDemandeInitial(response.data.demandeInitial);
        if (response.data.filesDevisClient)
          setFileDevisClient(response.data.filesDevisClient);
        if (response.data.boolPhotos)
          setBoolPhotos(response.data.boolPhotos);
        if (response.data.dataFacturation)
          setDataFacturation(response.data.dataFacturation);
        if (response.data.horairesOuverture)
          setHorairesOuverture(response.data.horairesOuverture);
        if (response.data.infosMagasin)
          setInfosMagasin(response.data.infosMagasin);
        if (response.data.fileFicheInterventionIntervenant)
          setFileFicheInterventionIntervenant(response.data.fileFicheInterventionIntervenant);
        if (response.data.fileDemandeDevisIntervenant)
          setFileDemandeDevisIntervenant(response.data.fileDemandeDevisIntervenant);

        const headerControl = {
          IDMastore: params.IDMastore,
          Onchange: props.user.userID,
        };
        axios.defaults.withCredentials = true;
        if (props.infoIntervention.on_change === "") {
          axios.post('/legacy/ChangeOnModifIntervention', headerControl);
          setCurrentUserControl(props.user.userID);
        } else {
          setCurrentUserControl(props.infoIntervention.on_change);
        }
      })
      .catch(error => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert onClose={handleCloseAlertRequest} severity="error">
            Erreur lors de la récupération des données de l'intervention! Peut-être que l'id indiqué dans l'url n'existe pas!
          </Alert>
        );
      });
  }, [props.infoIntervention, props.user, props.actions]);

  // Fonction utilitaire pour récupérer les paramètres de l'URL
  const getParamUrl = () => {
    const values = queryString.parse(window.location.search);
    return values.IDMastore ? values : { IDMastore: -1 };
  };

  // Fermeture des modaux
  const closeModal = () => {
    setModalPhoto(false);
    setUploadPlan(false);
    setUploadInventaire(false);
    setUploadDocument(false);
    setModalHistorique(false);
  };

  // Ouverture du modal Photo
  const openModalPhoto = () => {
    setModalPhoto(true);
  };

  // Ouverture du modal Historique
  const openModalHistorique = () => {
    setModalHistorique(true);
  };

  // Changement d'état pour l'upload du plan
  const changeBoolUploadPlan = () => {
    setUploadPlan(true);
  };

  // Changement d'état pour l'upload du document
  const changeBoolUploadDocument = () => {
    setUploadDocument(true);
  };

  // Changement d'état pour l'upload de l'inventaire
  const changeBoolUploadInventaire = () => {
    setUploadInventaire(true);
  };

  // Gestion de la sélection d'un plan
  const planSelectedHandler = (files) => {
    setFilesPlan(files);
    setUpdate(true);
    stockPlans();
  };

  // Gestion de la sélection d'un document
  const documentSelectedHandler = (files) => {
    setFilesDocument(files);
    setUpdate(true);
    
  };

  useEffect(() => {
    if (!uploadDocument) {
      stockDocuments();
      
    }
  }, [filesDocument]);

  useEffect(() => {
    if (!uploadPlan) {
      stockPlans();
    }
  }, [filesPlan]);


  // Fonction utilitaire de création de FormData à partir d'un tableau de fichiers
  const createDataFile = (tabfiles) => {
    const formData = new FormData();
    tabfiles.forEach(file => {
      if (!file.file.preview) {
        formData.append('nameFiles', file.name);
        formData.append('zone', file.zone);
        formData.append('specialite', file.specialite);
        formData.append('objet', file.objet);
        formData.append('periode', file.periode);
        formData.append('imgBase64', file.file);
      }
    });
    tabfiles.forEach(file => {
      if (file.file.preview) {
        formData.append('nameFiles', file.name);
        formData.append('zone', file.zone);
        formData.append('specialite', file.specialite);
        formData.append('objet', file.objet);
        formData.append('periode', file.periode);
        formData.append('files', file.file);
      }
    });
    return formData;
  };

  // Stocke les documents
const stockDocuments = () => {
    const formData = createDataFile(filesDocument);
    formData.append('IDMastore', props.infoIntervention.id_mastore);
    formData.append('Attribut', "Documents");
  
    axios.defaults.withCredentials = true;
    return axios.post('/legacy/stockDocuments', formData);
  };
  
  // Stocke les plans
  const stockPlans = () => {
    const formData = createDataFile(filesPlan);
    formData.append('NumeroMagasin', props.infoIntervention.numero_magasin);
    formData.append('Enseigne', props.infoIntervention.enseigne);
    formData.append('Attribut', "Plans");
  
    axios.defaults.withCredentials = true;
    return axios.post('/legacy/stockPlans', formData);
  };
  
  // Crée le FormData pour les fichiers clients
  const createDataFileClient = (tabfiles) => {
    let formData = new FormData();
    tabfiles.forEach(file => {
      if (!file.file) {
        formData.append('cout', file.Cout);
        formData.append('name', file.Name);
        formData.append('imgBase64', file.File);
      }
    });
    tabfiles.forEach(file => {
      if (file.file) {
        formData.append('cout', file.Cout);
        formData.append('name', file.Name);
        formData.append('files', file.file);
      }
    });
    return formData;
  };
  
  // Active le spinner en mettant loading à true
  const setSpinner = () => {
    setLoading(true);
  };
  
  // Supprime les demandes de devis intervenant
  const removeDemandeDevisIntervenant = () => {
    let formData = new FormData();
    fileRemoveDemandeDevisIntervenant.forEach((item) => {
      formData.append("ID", item.ID);
    });
    axios.defaults.withCredentials = true;
    return axios.post('/legacy/removeDemandeDevisIntervenant', formData);
  };
  
  // Supprime la fiche intervention intervenant
  const removeFicheInterventionIntervenant = () => {
    let formData = new FormData();
    fileRemoveFicheInterventionIntervenant.forEach((item) => {
      formData.append("ID", item.ID);
    });
    axios.defaults.withCredentials = true;
    return axios.post('/legacy/removeFicheInterventionIntervenant', formData);
  };
  
  // Stocke les fichiers client
  const stockFilesClient = () => {
    let formData = createDataFileClient(fileDevisClient);
    formData.append('IDMastore', props.infoIntervention.id_mastore);
    formData.append('Attribut', "DevisClient");
    axios.defaults.withCredentials = true;
    return axios.post('/legacy/stockFilesClient', formData);
  };
  
  // Sauvegarde de l'intervention
const saveIntervention = () => {
    Promise.all([
      setSpinner(),
      stockFilesClient(),
      removeDemandeDevisIntervenant(),
      removeFicheInterventionIntervenant(),
      props.saveIntervention(),
      props.saveAutomatiqueCommentaire(),
    ])
      .then(() => {
        window.location.reload(true);
      })
      .catch(() => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert onClose={handleCloseAlertRequest} severity="error">
            Erreur lors de sauvegarde de la demande d'intervention !
          </Alert>
        );
        setPageLoading(false);
      });
  };
  
  // Archivage de l'intervention
  const archiveIntervention = () => {
    setSpinner();
    const options = { month: 'long', weekday: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const header = {
      IDMastore: props.infoIntervention.id_mastore,
      ArchivesBy:
        "Archivée le " +
        new Date().toLocaleDateString("fr-FR", options).replace(":", "h") +
        " par " +
        props.user.firstname +
        " " +
        props.user.lastname,
    };
    axios.defaults.withCredentials = true;
    axios
      .post('/legacy/ArchivesIntervention', header)
      .then(() => {
        window.location.reload(true);
      })
      .catch(() => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert onClose={handleCloseAlertRequest} severity="error">
            Erreur lors de l'archive de l'intervention!
          </Alert>
        );
        setPageLoading(false);
      });
  };

   const moveToCorbeilleIntervention = () => {
    setSpinner();
    const payload = {
      idmastores: [props.infoIntervention.id_mastore],
      update: {
        status: "CORBEILLE",
        type: "corbeille",
        corbeille: true,
      }
    };
    axios.put('/legacy/multipleInterventionBulk', payload)
      .then(() => {
        window.location.reload(true);
      })
      .catch(error => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert onClose={handleCloseAlertRequest} severity="error">
            Erreur lors de la mise en corbeille de l'intervention!
          </Alert>
        );
        setPageLoading(false); });
  }; 

  const restaureIntervention = () => {
    setSpinner();
    const payload = {
      idmastores: [props.infoIntervention.id_mastore],
      update: {
        status: "EN COURS",
        type: "corrective",
        corbeille: false,
      }
    };
    axios.put('/legacy/multipleInterventionBulk', payload)
      .then(() => {
        window.location.reload(true);
      })
      .catch(error => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert onClose={handleCloseAlertRequest} severity="error">
            Erreur lors de la mise en corbeille de l'intervention!
          </Alert>
        );
        setPageLoading(false); });
  };


  const ValidationIntervention = () => {
    setSpinner();
    const payload = {
      idmastores: [props.infoIntervention.id_mastore],
      update: {
        status: "Validée",
      }
    };
    axios.put('/legacy/multipleInterventionBulk', payload)
        .then(() => {
          window.location.reload(true);
        })
        .catch(error => {
          setOpenAlertRequest(true);
          setAlertRequest(
              <Alert onClose={handleCloseAlertRequest} severity="error">
                Erreur lors de la mise en corbeille de l'intervention!
              </Alert>
          );
          setPageLoading(false); });
  };

  const openDialogArchive = () => {
    setDialogArchive(true);
  };
  
  const closeDialogArchive = () => {
    setDialogArchive(false);
  };

  const openDialogCorbeille = () => {
    setDialogCorbeille(true);
  };
  
  const closeDialogCorbeille = () => {
    setDialogCorbeille(false);
  };

  const openDialogRestaurer = () => {
    setDialogRestaure(true);
  };
  
  const closeDialogRestaurer = () => {
    setDialogRestaure(false);
  };

  const openDialogValider = () => {
    setDialogValid(true);
  }

  const closeDialogValider = () => {
    setDialogValid(false);
  }
  
  const areIconsRed = () => {
    return (
      !props.isAllActionsClosed ||
      !props.isAllDevisValid ||
      (props.clientInfo.devis && props.clientInfo.devis.length === 0)
    );
  };
  
  const openDialogFinish = () => {
    setConfirmDialogOpen(true);
  };
  
  const closeDialogFinish = () => {
    setConfirmDialogOpen(false);
  };
  
  const confirmTermination = () => {
    if (props.isAllActionsClosed && props.isAllDevisValid) {
      closeDialogFinish();
    }
  };
  
  const createDemandeDevis = (response) => {
    setFileDemandeDevisIntervenant((prev) =>
      prev.concat(response.data.fileDemandeDevisIntervenant[0])
    );
    setModalDemandeDevisIntervenant(true);
    setUpdate(true);
  };
  
  const createFicheIntervention = (response) => {
    setFileFicheInterventionIntervenant((prev) =>
      prev.concat(response.data.fileFicheInterventionIntervenant[0])
    );
    setModalFicheInter(true);
    setUpdate(true);
  };
  
  const saveDemandeDevis = (fileDemandeDevisIntervenantParam, fileRemoveDemandeDevisIntervenantParam) => {
    setFileDemandeDevisIntervenant(fileDemandeDevisIntervenantParam);
    setFileRemoveDemandeDevisIntervenant(fileRemoveDemandeDevisIntervenantParam);
    setUpdate(true);
  };
  
  const handleArchiveWithComment = () => {
    const finalComment = `DI Archivée : ${commentaire.trim()}`;
    const payload = {
      IDMastore: props.infoIntervention.id_mastore,
      author: `${props.user.firstname} ${props.user.lastname}`,
      commentaire: finalComment,
      color: "1",
      time: new Date().toISOString(),
    };
  
    axios.defaults.withCredentials = true;
    axios
      .post('/legacy/postComment', payload)
      .then(() => {
        archiveIntervention();
      })
      .catch(() => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert severity="error" onClose={handleCloseAlertRequest}>
            Erreur lors de l'ajout du commentaire !
          </Alert>
        );
      });
  };

  const handleCorbeilleWithComment = () => {
    const finalComment = `DI mise en corbeille : ${commentaire.trim()}`;
    const payload = {
      IDMastore: props.infoIntervention.id_mastore,
      author: `${props.user.firstname} ${props.user.lastname}`,
      commentaire: finalComment,
      color: "1",
      time: new Date().toISOString(),
    };
  
    axios.defaults.withCredentials = true;
    axios
      .post('/legacy/postComment', payload)
      .then(() => {
        moveToCorbeilleIntervention();
      })
      .catch(() => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert severity="error" onClose={handleCloseAlertRequest}>
            Erreur lors de l'ajout du commentaire !
          </Alert>
        );
      });
  };

  const handleRestaureWithComment = () => {
    const finalComment = `DI restaurer : ${commentaire.trim()}`;
    const payload = {
      IDMastore: props.infoIntervention.id_mastore,
      author: `${props.user.firstname} ${props.user.lastname}`,
      commentaire: finalComment,
      color: "1",
      time: new Date().toISOString(),
    };
  
    axios.defaults.withCredentials = true;
    axios
      .post('/legacy/postComment', payload)
      .then(() => {
        restaureIntervention();
      })
      .catch(() => {
        setOpenAlertRequest(true);
        setAlertRequest(
          <Alert severity="error" onClose={handleCloseAlertRequest}>
            Erreur lors de l'ajout du commentaire !
          </Alert>
        );
      });
  };


    const handleValidationWithComment = () => {
    const finalComment = `DI Validée : ${commentaire.trim()}`;
    const payload = {
      IDMastore: props.infoIntervention.id_mastore,
      author: `${props.user.firstname} ${props.user.lastname}`,
      commentaire: finalComment,
      color: "1",
      time: new Date().toISOString(),
    };

    axios.defaults.withCredentials = true;
    axios
        .post('/legacy/postComment', payload)
        .then(() => {
          ValidationIntervention();
        })
        .catch(() => {
          setOpenAlertRequest(true);
          setAlertRequest(
              <Alert severity="error" onClose={handleCloseAlertRequest}>
                Erreur lors de l'ajout du commentaire !
              </Alert>
          );
        });
  };





  const devisClientSelectedHandler = (files) => {
    // Implémentation ou fonction vide pour l'instant
    console.log("devisClientSelectedHandler", files);
  };
  
  
  // Partie 1 : Modaux et dialogue d'archive
const modalPageIntervention = (
    <>
      <Modal open={modalPhoto} close={closeModal}>
        <Suspense fallback={<Spinner />}>
          <Photo actions={props.actions} modalClosed={closeModal} infoIntervention={props.infoIntervention} />
        </Suspense>
      </Modal>
      <Modal open={uploadPlan} close={closeModal}>
        <Plan open={uploadPlan} modalClosed={closeModal} filesPlan={filesPlan} addPlan={planSelectedHandler} data={props.infoIntervention} />
      </Modal>
      <Modal open={uploadDocument} close={closeModal}>
        <Document modalClosed={closeModal} filesDocument={filesDocument} addDocument={documentSelectedHandler} data={props.infoIntervention} />
      </Modal>
      <Modal open={uploadInventaire} close={closeModal}>
        <Inventaire modalClosed={closeModal} filesInventaire={filesInventaire} data={props.infoIntervention} />
      </Modal>
      <Modal open={modalHistorique} close={closeModal}>
        <Suspense fallback={<Spinner />}>
          <Historique modalClosed={closeModal} infoIntervention={props.infoIntervention} />
        </Suspense>
      </Modal>
    </>
  );
  
  const isCommentInvalid = !commentaire || commentaire.trim() === "";
  
  // Styles en ligne pour le dialogue
  const dialogTitleStyle = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold",
  };
  
  const dialogContentStyle = {
    padding: "20px",
  };
  
  const dialogTextStyle = {
    marginBottom: "16px",
    color: "rgba(0, 0, 0, 0.7)",
  };
  
  const buttonStyle = {
    textTransform: "none",
  };
  
  const dialogModalArchive = (
    <>
      <Dialog
        open={dialogArchive}
        keepMounted
        onClose={closeDialogArchive}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={dialogTitleStyle}>
          <WarningIcon color="error" />
          Voulez-vous vraiment archiver cette intervention ?
        </DialogTitle>
        <DialogContent style={dialogContentStyle}>
          <DialogContentText style={dialogTextStyle}>
            Archiver l'intervention déplacera cette dernière dans la base de données archivée.{" "}
            <strong>Plus aucune modification</strong> sur cette intervention ne sera possible.
          </DialogContentText>
          <TextField
            required
            label="Commentaire"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            error={isCommentInvalid}
            helperText={isCommentInvalid ? "Ce champ est obligatoire" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialogArchive}
            variant="outlined"
            color="error"
            size="medium"
            style={buttonStyle}
          >
            Annuler
          </Button>
          <Button
            onClick={handleArchiveWithComment}
            variant="outlined"
            color="primary"
            size="medium"
            disabled={isCommentInvalid}
            style={buttonStyle}
          >
            Archiver
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const dialogModalCorbeille = (
    <>
      <Dialog
        open={dialogCorbeille}
        keepMounted
        onClose={closeDialogCorbeille}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={dialogTitleStyle}>
          <WarningIcon color="error" />
          Voulez-vous vraiment mettre en corbeille cette intervention ?
        </DialogTitle>
        <DialogContent style={dialogContentStyle}>
          <DialogContentText style={dialogTextStyle}>
            La mise en corbeille de l'intervention déplacera cette dernière dans la base de données Corbeille.{" "}
            <strong>Plus aucune modification</strong> sur cette intervention ne sera possible.
          </DialogContentText>
          <TextField
            required
            label="Commentaire"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            error={isCommentInvalid}
            helperText={isCommentInvalid ? "Ce champ est obligatoire" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialogCorbeille}
            variant="outlined"
            color="error"
            size="medium"
            style={buttonStyle}
          >
            Annuler
          </Button>
          <Button
            onClick={handleCorbeilleWithComment}
            variant="outlined"
            color="primary"
            size="medium"
            disabled={isCommentInvalid}
            style={buttonStyle}
          >
            Mise en corbeille
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  const dialogModalRestaure = (
    <>
      <Dialog
        open={dialogRestaure}
        keepMounted
        onClose={closeDialogRestaurer}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={dialogTitleStyle}>
          <WarningIcon color="error" />
          Voulez-vous vraiment restaurez cette intervention ?
        </DialogTitle>
        <DialogContent style={dialogContentStyle}>
          <DialogContentText style={dialogTextStyle}>
            La restauration de l'intervention replacera la DI dans le tableau Interventions.{" "}
            
          </DialogContentText>
          <TextField
            required
            label="Commentaire"
            variant="outlined"
            fullWidth
            multiline
            minRows={3}
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            error={isCommentInvalid}
            helperText={isCommentInvalid ? "Ce champ est obligatoire" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialogRestaurer}
            variant="outlined"
            color="error"
            size="medium"
            style={buttonStyle}
          >
            Annuler
          </Button>
          <Button
            onClick={handleRestaureWithComment}
            variant="outlined"
            color="primary"
            size="medium"
            disabled={isCommentInvalid}
            style={buttonStyle}
          >
            Restaurer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );



  // Partie 2 : Construction de la page principale d'intervention
let pageIntervention = <Spinner />;

if (getValue) {
  pageIntervention = (
    <>
      {modalPageIntervention}
      {dialogModalArchive}
      {dialogModalCorbeille}
      {dialogModalRestaure}
      {dialogValid && (
          <DialogValid
              handleValidationWithComment={handleValidationWithComment}
              clientInfo={props.clientInfo}
              infoIntervention={props.infoIntervention}
              isAllActionsClosed={props.isAllActionsClosed}
              isAllDevisValid={props.isAllDevisValid}
              close={closeDialogValider}
          />
      )}

      <Grid container sx={{ height: { xs: "100%", md: "95vh" }, padding: "5px", overflow: "auto" }} spacing={{ md: 0.5, xs: 1 }}>
        <InformationView
          infoIntervention={props.infoIntervention}
          actions={props.actions}
          clientInfo={props.clientInfo}
          fileDevisClient={fileDevisClient}
          updateInfoIntervention={props.updateInfoIntervention}
          horairesOuverture={horairesOuverture}
          infosMagasin={infosMagasin}
          user={props.user}
          openInventaireModal={changeBoolUploadInventaire}
          fileInventaireLength={filesInventaire.length}
          openModalHistorique={openModalHistorique}
          openModalPhoto={openModalPhoto}
          openPlanModal={changeBoolUploadPlan}
          openDocumentModal={changeBoolUploadDocument}
          boolPhotos={boolPhotos}
          filePlanLength={filesPlan.length}
          fileDocumentLength={filesDocument.length}
        />
        <Grid item md={6} xs={12}>
          <Stack spacing={{ md: 0.5, xs: 1 }} sx={{ height: "100%" }}>
            <ClientView
              infoIntervention={props.infoIntervention}
              updateInfoIntervention={props.updateInfoIntervention}
              clientInfo={props.clientInfo}
              setClientInfo={props.setClientInfo}
              user={props.user}
              actions={props.actions}
              fileDemandeInitial={fileDemandeInitial}
              fileDevis={fileDevisClient}
              addDevis={devisClientSelectedHandler}  // Assurez-vous que cette fonction est définie
              dataFacturation={dataFacturation}
            />
            <IntervenantView
              infoIntervention={props.infoIntervention}
              actions={props.actions}
              updateInfoIntervention={props.updateInfoIntervention}
              user={props.user}
              setActions={props.setActions}
              newDateIntervention={props.newDateIntervention}
              modifyDateIntervention={props.modifyDateIntervention}
              deleteDateIntervention={props.deleteDateIntervention}
              newAction={props.newAction}
              modifyAction={props.modifyAction}
              deleteAction={props.deleteAction}
              demandeDevisLength={fileDemandeDevisIntervenant.length}
              demandeInterLength={fileFicheInterventionIntervenant.length}
              createFicheIntervention={createFicheIntervention}
              saveFicheInter={saveFicheInter}
              resumeFicheIntervention={resumeFicheIntervention}
              fileFicheInterventionIntervenant={fileFicheInterventionIntervenant}
              createDemandeDevis={createDemandeDevis}
              saveDemandeDevis={saveDemandeDevis}
              resumeDemandeDeDevis={resumeDemandeDeDevis}
              fileDemandeDevisIntervenant={fileDemandeDevisIntervenant}
              devis={filesDevisIntervenant}  // ou fileDevisIntervenant selon votre état
              hasSentDevis={props.hasSentDevis}
            />
          </Stack>
        </Grid>
      </Grid>
      <ButtonBar
          infoIntervention={props.infoIntervention}
          saveIntervention={saveIntervention}
          archiveIntervention={openDialogArchive}
          moveToCorbeilleIntervention={openDialogCorbeille}
          restaureIntervention={openDialogRestaurer}
          OnchangeIntervention={props.infoIntervention.on_change}
          update={update || props.update}
          openDialogFinish={openDialogFinish}
          openDialogValider={openDialogValider}  // Ajout de cette ligne
          user={props.user}
          isSaveDisabled={props.isSaveDisabled}
          isArchiveDisabled={props.isArchiveDisabled}
          archiveErrorMessage={props.archiveErrorMessage}
          isCorbeilleDisabled={props.isCorbeilleDisabled}
      />
    </>
  );
}

if (pageLoading) {
  pageIntervention = <Spinner />;
}

  

 // Partie 3 : Retour du composant
return (
    <Grid container spacing={0}>
      {currentUserControl !== props.user.userID ? (
        <div>
          <div
            disabled={true}
            style={{ filter: "alpha(opacity=50)", opacity: 0.6, pointerEvents: "none" }}
          >
            {pageIntervention}
          </div>
          {currentUserControl !== props.user.userID && (
            <Button
              onClick={takeTheControlOfTheIntervention}
              style={{
                left: "47%",
                position: "absolute",
                background: "black",
                color: "white",
                top: "50%",
                zIndex: "100000",
              }}
            >
              Prendre le contrôle {currentUserControl}
            </Button>
          )}
        </div>
      ) : (
        pageIntervention
      )}
      <Snackbar open={openAlertRequest} autoHideDuration={6000} onClose={handleCloseAlertRequest}>
        {alertRequest}
      </Snackbar>
      <ConfirmDialog
        onClose={closeDialogFinish}
        disableButton={areIconsRed()}
        clientInfo={props.clientInfo}
        onConfirm={confirmTermination}
        open={confirmDialogOpen}
        saveIntervention={saveIntervention}
        isAllDevisValid={props.isAllDevisValid}
        infoIntervention={props.infoIntervention}
        isAllActionsClosed={props.isAllActionsClosed}
        updateInfoIntervention={props.updateInfoIntervention}
      />
    </Grid>
  );
  
};

export default withMySnackbar(InterventionView);
