import React from 'react';

// MUI
import Grid from '@mui/material/Unstable_Grid2';
import { Divider, Tooltip } from '@mui/material';

// Types
import { ModuleDevisCardProps } from './types';

// Styles
import { NameModule, Subtitle, Subtitle2, Subtitle3, Commentaire } from '../../../views/ModuleDevis/ModuleDevisStyle';

const ModuleCardContent: React.FC<ModuleDevisCardProps> = ({
    moduleDevis,
}) => {

    const getCorrectivePrice = () => {
        const site = moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixCorrective !== 0 && site.prixCorrective !== undefined) && !site.defaut
        ) || moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixCorrective !== 0 && site.prixCorrective !== undefined) && site.defaut
        );

        return site?.prixCorrective ?? (moduleDevis.prixCorrectiveDefaut !== 0 ? moduleDevis.prixCorrectiveDefaut : 0);
    };

    const getPreventivePrice = () => {
        const site = moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixPreventive !== 0 && site.prixPreventive !== undefined) && !site.defaut
        ) || moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixPreventive !== 0 && site.prixPreventive !== undefined) && site.defaut
        );

        return site?.prixPreventive ?? (moduleDevis.prixPreventiveDefaut !== 0 ? moduleDevis.prixPreventiveDefaut : 0);
    };

    const correctivePrice = getCorrectivePrice();
    const preventivePrice = getPreventivePrice();

    const getCorrectiveTooltip = () => {
        const site = moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixCorrective !== 0) && !site.defaut
        ) || moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixCorrective !== 0) && site.defaut
        );

        return site ? (site.defaut ? "Prix corrective client" : "Prix corrective magasin") : "Prix corrective Mastore";
    };

    const getPreventiveTooltip = () => {
        const site = moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixPreventive !== 0) && !site.defaut
        ) || moduleDevis?.moduleDevisSites?.find(site =>
            (site.prixPreventive !== 0) && site.defaut
        );

        return site ? (site.defaut ? "Prix préventive client" : "Prix préventive magasin") : "Prix préventive Mastore";
    };

    return (
        <>
            <Grid container padding={1} marginTop={1} direction={"column"} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                <NameModule variant='h6'>
                    {moduleDevis.name || <span style={{ color: "grey" }}>Aucun nom</span>}
                </NameModule>
                <Subtitle sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 2, textOverflow: 'ellipsis' }} gutterBottom>
                {Array.isArray(moduleDevis.specialite) && moduleDevis.specialite.length > 0
                    ? moduleDevis.specialite.join(', ')
                    : <span style={{ color: "grey" }}>Aucunes spécialité(s) spécifiée(s)</span>}
                </Subtitle>
            </Grid>
            <Divider variant="middle" />
            <Grid container xs={12} padding={1.5} sx={{ height: '75px', flexGrow: 1 }}>
                <Grid xs={12} sm={12} >
                    <Tooltip placement='top-start' title={<span dangerouslySetInnerHTML={{ __html: moduleDevis.description || "Pas de description" }}/>} arrow>
                        <Subtitle2 sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 2, textOverflow: 'ellipsis', lineBreak: 'anywhere' }} gutterBottom
                        dangerouslySetInnerHTML={{ __html: moduleDevis.description || "<span style='color:grey;'>Pas de description</span>" }}/>
                    </Tooltip>
                    <Tooltip placement='top-start' title={<span dangerouslySetInnerHTML={{ __html: moduleDevis.commentaire || "Pas de commentaire" }}/>} arrow>
                        <Commentaire sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 1, textOverflow: 'ellipsis', lineBreak: 'anywhere' }} gutterBottom
                        dangerouslySetInnerHTML={{ __html: moduleDevis.commentaire || "<span style='color:grey;font-style:italic;'>Pas de commentaire</span>" }}
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid container padding={1} spacing={0} justifyContent={"space-between"} textAlign={"center"}>
                <Grid>
                    <Subtitle3 gutterBottom>
                        <strong>Unité</strong> : {moduleDevis.unite || "N/A"}
                    </Subtitle3>
                </Grid>
                <Grid >
                    <Subtitle3 gutterBottom>
                        <strong>Quantité</strong> : {moduleDevis.quantite || "0"}
                    </Subtitle3>

                </Grid>
                <Grid>
                    <Tooltip placement='top' title={getCorrectiveTooltip()} arrow>
                        <Subtitle3 gutterBottom>
                            <strong>Corrective</strong> : {correctivePrice}€
                        </Subtitle3>
                    </Tooltip>
                </Grid>
                <Grid>
                    <Tooltip placement='top' title={getPreventiveTooltip()} arrow>
                        <Subtitle3 gutterBottom>
                            <strong>Préventive</strong> : {preventivePrice}€
                        </Subtitle3>
                    </Tooltip>
                </Grid>
            </Grid>

        </>
    );
};

export default ModuleCardContent;
