import React from 'react';
// material
import LoadingButton from '@mui/lab/LoadingButton';
import { alpha, styled } from '@mui/material/styles';


// ----------------------------------------------------------------------

const LoadingButtonStyled = styled(LoadingButton)(({ theme, disabled }) => {
    return {
        width: '20%',
        ...(!disabled && {
            color: "white !important",
            borderColor: 'white',
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                borderColor: "rgb(255, 159, 77)",
                backgroundColor: alpha("rgb(255, 159, 77)", 0.8),
            },
        }),
    };
});

// ----------------------------------------------------------------------

export default function LoadingButtonModal({ update, loading, clickHandler, icon, name }) {
    return (
        <LoadingButtonStyled
            variant="outlined"
            size='small'
            disabled={!update}
            loading={loading}
            onClick={clickHandler}
            startIcon={icon}
            fullWidth={false}
        >
            {name}
        </LoadingButtonStyled>
    );
}