import * as React from 'react';
import {
    IconButton,
    Popover,
    Stack,
} from '@mui/material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { TodoList } from 'components/UI/TodoList';


export default function NavbarTodoList() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton onClick={handleClick} sx={{ transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.1)' }}}>
                <NoteAltIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableScrollLock={true}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack sx={{ width: "450px", padding: 2 }}>
                    <TodoList />
                </Stack>
            </Popover>
        </div>
    );
}
