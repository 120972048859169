import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface CustomEditorProps {
  initialData?: string;
  onSave: (data: string) => void;
  placeholder?: string;
  customStyles?: React.CSSProperties;
}

const CustomEditor: React.FC<CustomEditorProps> = ({
  initialData = "",
  onSave,
  placeholder = "Entrez votre texte ici...",
  customStyles = {},
}) => {
  const [value, setValue] = useState<string>(initialData);
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    setValue(initialData); // Initialise l'éditeur avec les données passées via `initialData`.
  }, [initialData]);

  const handleChange = (content: string, _delta: any, _source: any, editor: any) => {
    setValue(content);
    saveEditorData(editor);
  };

  const saveEditorData = (editor: any) => {
    if (onSave) {
      const textData = editor.getHTML(); // Récupère le contenu sous forme HTML.
      onSave(textData); // Transmet les données sauvegardées via le callback.
    }
  };

  return (
    <>
      <style>
        {`
          .ql-toolbar.ql-snow {
            border-top-right-radius: 3px;
            border-top-left-radius: 3px;
          }
          .ql-container.ql-snow {
            border-bottom-right-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        `}
      </style>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        theme="snow"
        style={{
          ...customStyles,
        }}
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["link"],
            ["clean"], // Supprime la mise en forme.
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "list",
          "bullet",
          "link",
        ]}
      />
    </>
  );
};

export default CustomEditor;
