// Chat.tsx
import React, {
    useState,
    useEffect,
    useContext,
    useRef,
    KeyboardEvent,
    ChangeEvent,
    MouseEvent,
  } from "react";
  import { connect } from "react-redux";
  import {
    IconButton,
    Button,
    Popover,
    Card,
    Grid,
    Typography,
    Avatar,
    InputBase,
    Stack,
    Badge,
    Modal,
    Box,
  } from "@mui/material";
  import { blue, grey } from "@mui/material/colors";
  import ChatIcon from "@mui/icons-material/Chat";
  import ClearIcon from "@mui/icons-material/Clear";
  import SmsIcon from "@mui/icons-material/Sms";
  import LockOpenIcon from "@mui/icons-material/LockOpen";
  import {
    sendMessage,
    getMessageByIdMastore,
    putMessageRead,
    putMessageAnswered,
    getLastMessage,
    putClosedDiscussion,
    reOpenDiscussion,
  } from "services";
  import { DisplayMessage } from "./components/DisplayMessage";
  import ChatContext from "contexts/ChatContext";
  import { filter } from "lodash";
  import { useSnackbar } from "notistack";
  import Iconify from "../Iconify";
  // Importation des types depuis types.ts
  import { Message, ChatProps, ChatContextType } from "./types";
  
  const Chat: React.FC<ChatProps> = (props) => {
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const ctx = useContext<ChatContextType>(ChatContext);
    const [sendMsg, setSendMsg] = useState<string>("");
    const [message, setMessage] = useState<Message[]>([]);
    const [nbNoneReadMessage, setNbNoneReadMessage] = useState<number>(0);
    const [readMessageInChat, setReadMessageInChat] = useState<boolean>(false);
    const [messageNonRepondu, setMessageNonRepondu] = useState<number>(0);
    const [disableInput, setDisableInput] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openReopenModal, setOpenReopenModal] = useState<boolean>(false);
  
    const { enqueueSnackbar } = useSnackbar();
    const attached_to = "" + props.attached_to;
  
    useEffect(() => {
      Promise.all([getMessageByIdMastore(props.intervention.id_mastore)])
        .then(([responseMessageByIdMastore]) => {
          setMessage(responseMessageByIdMastore.message);
          setNbNoneReadMessage(
            filter(responseMessageByIdMastore.message, {
              send_by: "client",
              read: false,
            }).length
          );
          setMessageNonRepondu(
            filter(responseMessageByIdMastore.message, {
              send_by: "client",
              answered: false,
            }).length
          );
        })
        .catch((err) => {
          console.log(err);
        });
      getLastMessage(props.intervention.id_mastore)
        .then((response) => {
          if (response.message.closed) {
            setDisableInput(true);
          } else {
            setDisableInput(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, [props.loading, props.intervention.id_mastore]);
  
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      putMessageRead({ id_mastore: props.intervention.id_mastore, read: true }).then(
        () => {
          setReadMessageInChat(true);
        }
      );
    };
  
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSendMsg(event.target.value);
    };
  
    const handleCloseDiscussion = () => {
      if (props.intervention.status === "EN COURS") {
        enqueueSnackbar(
          "Impossible de clore la discussion : l'intervention est en cours.",
          { variant: "warning" }
        );
        return;
      }
  
      setDisableInput(true);
      putClosedDiscussion(props.intervention.id_mastore, attached_to)
        .then(() => {
          enqueueSnackbar("Discussion fermée", { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
        });
      setOpenModal(false);
    };
  
    const handleReOpenDiscussion = () => {
      if (props.intervention.status !== "EN COURS") {
        enqueueSnackbar(
          "Impossible de réouvrir la discussion : l'intervention n'est pas en cours.",
          { variant: "warning" }
        );
        return;
      }
  
      setDisableInput(false);
      reOpenDiscussion(props.intervention.id_mastore, attached_to)
        .then(() => {
          enqueueSnackbar("Discussion réouverte", { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
        });
      setOpenReopenModal(false);
    };
  
    const handleCloseModal = () => setOpenModal(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseReOpenModal = () => setOpenReopenModal(false);
    const handleOpenReOpenModal = () => setOpenReopenModal(true);
  
    useEffect(() => {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: "auto" });
        }
      }, 0);
    }, [message, ctx.isOpenChat]);

    const keyPress = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.keyCode === 13) {
        if ((e.target as HTMLInputElement).value.trim().length !== 0) {
          saveMessage();
        }
        setSendMsg("");
      }
    };
  
    const saveMessage = () => {
      sendMessage({
        id_mastore: props.intervention.id_mastore,
        message: sendMsg,
        recipient_by: props.intervention.client,
        emited_by: attached_to,
        sender: props.user.firstname + " " + props.user.lastname,
        send_by: "mastore",
        status: props.intervention.status,
        numero_magasin: props.intervention.numero_magasin,
        ville: props.intervention.ville,
        enseigne: props.intervention.enseigne,
        read: false,
      })
        .then(() => {
          getMessageByIdMastore(props.intervention.id_mastore)
            .then((response) => {
              const messages = response.message;
              setMessage(messages);
              ctx.onNewMessage();
  
              if (
                messages.length > 1 &&
                messages[messages.length - 1].send_by === "mastore"
              ) {
                putMessageAnswered({
                  id_mastore: messages[messages.length - 2].id_mastore,
                  answered: true,
                })
                  .then(() => {
                    setMessage((prevMessages) => {
                      const updatedMessages = [...prevMessages];
                      updatedMessages[updatedMessages.length - 2].answered = true;
                      return updatedMessages;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      setSendMsg("");
    };
  
    return (
      <React.Fragment>
        <Badge badgeContent={nbNoneReadMessage} color="error">
          <Badge badgeContent={messageNonRepondu} color="error">
            <Button
              variant="outlined"
              onClick={(e) => {
                handleClick(e);
                ctx.onOpenChat();
              }}
              startIcon={<SmsIcon />}
            >
              Messagerie
            </Button>
          </Badge>
        </Badge>
        {!props.loading ? (
          <Popover
            open={ctx.isOpenChat}
            onClose={ctx.onCloseChat}
            anchorPosition={{ top: 350, left: 210 }}
            anchorReference="anchorPosition"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            disableScrollLock={true}
          >
            <Card sx={{ width: 370, borderRadius: 0 }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ height: 48, boxShadow: "0px 0px 4px -2px #000000" }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    p={0.5}
                    justifyContent="space-between"
                    alignItems="center"
                    textAlign="center"
                  >
                    <Avatar sx={{ bgcolor: blue[100] }}>
                      <ChatIcon />
                    </Avatar>
                    <Stack alignItems="center">
                      <Typography variant="h6">
                        {props.intervention.enseigne +
                          " - " +
                          props.intervention.ville}
                      </Typography>
                      <Typography variant="caption">
                        {props.intervention.id_mastore}
                      </Typography>
                    </Stack>
                    <IconButton onClick={ctx.onCloseChat}>
                      <ClearIcon />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ height: 355, overflowY: "scroll" }}
                  p={1}
                  pt={2}
                >
                  <DisplayMessage
                    message={message}
                    endRef={messagesEndRef}
                    user={props.user}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {disableInput ? (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: "100%", padding: 2 }}
                    >
                      <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.5)" }}>
                        Discussion clôturée
                      </Typography>
                      <Button
                        variant="outlined"
                        size="small"
                        color="success"
                        onClick={handleOpenReOpenModal}
                      >
                        Réouvrir la discussion
                        <IconButton onClick={handleOpenReOpenModal}>
                          <LockOpenIcon color="success" />
                        </IconButton>
                      </Button>
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      p={1}
                      mb={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <IconButton onClick={handleOpenModal}>
                        <Iconify
                          icon={"uil:envelope-block"}
                          sx={{ color: "rgba(250,57,57,0.8)" }}
                        />
                      </IconButton>
                      <InputBase
                        onKeyDown={keyPress}
                        value={sendMsg}
                        onChange={handleChange}
                        sx={{
                          ml: 1,
                          mr: 1,
                          flex: 1,
                          p: 0.5,
                          paddingLeft: 2,
                          backgroundColor: grey[200],
                          borderRadius: "30px",
                        }}
                        placeholder="Aa"
                      />
                      <IconButton onClick={saveMessage}>
                        <Iconify
                          icon={"mingcute:send-fill"}
                          sx={{ color: "rgb(0, 132, 255)" }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Popover>
        ) : null}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "background.paper",
              borderRadius: "0.6rem",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmer la clôture de la discussion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Êtes-vous sûr de vouloir clore cette discussion ?
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              <Button onClick={handleCloseModal} variant="outlined" size="small">
                Annuler
              </Button>
              <Button onClick={handleCloseDiscussion} color="error" variant="outlined" size="small">
                Confirmer
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={openReopenModal}
          onClose={handleCloseReOpenModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 450,
              bgcolor: "background.paper",
              borderRadius: "0.6rem",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirmer la réouverture de la discussion
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Êtes-vous sûr de vouloir réouvrir cette discussion ?
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
              <Button onClick={handleCloseReOpenModal} variant="outlined" size="small">
                Annuler
              </Button>
              <Button onClick={handleReOpenDiscussion} color="error" variant="outlined" size="small">
                Confirmer
              </Button>
            </Stack>
          </Box>
        </Modal>
      </React.Fragment>
    );
  };
  
  const mapStateToProps = (state: any) => ({
    firstname: state.firstname,
    attached_to: state.attached_to,
  });
  
  export default connect(mapStateToProps)(Chat);
  