import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Card, TextField } from '@mui/material';

// ------------------------------------------------------------------------->// ModuleDevis.tsx
export const TopCard = styled(Card)(() => ({
    padding: "15px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 5px 15px 0px",
    width: "100%",
    height: "7vh",
    position: 'sticky',
    top: 0,
    zIndex: 100,
}));

export const CardPagination = styled(Card)(() => ({
    padding: "20px",
    boxShadow: "rgba(100, 100, 111, 0.4) 0px 5px 15px 0px",
    width: "100%",
    height: "6%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "hidden",
}));

export const SearchIcon = styled(EditIcon)(() => ({
    fontSize: '17px !important',
}));

export const NameModule = styled(Typography)(() => ({
    fontWeight: "bold"
}));

export const Subtitle = styled(Typography)(() => ({
    fontSize: "12px !important",
    color: "grey",
}));

export const Subtitle2 = styled(Typography)(() => ({
    fontSize: "12px !important",
}));

export const Commentaire = styled(Typography)(() => ({
    fontSize: "11px !important",
    fontStyle: "italic",
}));

export const Subtitle3 = styled(Typography)(() => ({
    fontSize: "11px !important",
    fontWeight: "400",
    paddingRight: "5px",
    paddingLeft: "5px"
}));

export const Multiline = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
        height: '100%',
    },
    '& .MuiInputBase-inputMultiline': {
        height: '100%',
    },
    '& .MuiInputBase-input': {
        height: '100% !important',
        padding: "0px !important",
        paddingTop: "10px !important",
        overflow: 'auto',
    },
}));

export const CardHover = styled(Card)(() => ({
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 5px 15px 0px",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    border: "0.5px solid rgba(26, 99, 182, 0.2)",
    '&:hover': {
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        transform: "scale(1.03)",
        backgroundColor: "rgba(26, 99, 182, 0.08)",
    },
}));

export const DetailedCard = styled(Card)(() => ({
    display: "flex",
    flexDirection: "column",
    border: "0.5px solid rgba(26, 99, 182, 0.2)",
    boxShadow: "rgba(100, 100, 111, 0.00) 0px 5px 15px 0px",
}));

export const MagasinCard = styled(Card)(() => ({
    boxShadow: "rgba(100, 100, 111, 0.00) 0px 5px 15px 0px",
    border: "0.5px solid rgba(26, 99, 182, 0.2)",
    display: "flex",
    flexDirection: "column"
}));

export const TextFieldMultiline = styled(TextField)(() => ({
    '& .MuiInputBase-inputMultiline': {
        resize: "vertical",
    }
}));