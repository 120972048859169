// components
import Iconify from "components/UI/Iconify";
import { ICON } from "config";

export const navConfigAdmin = [
    {
        subheader: "Intervention",
        items: [
            { title: "Interventions", path: "/", icon: (<Iconify icon={"la:tools"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Préventives", path: "/tableau-preventive", icon: (<Iconify icon={"solar:calendar-line-duotone"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Demandes", path: "/demande-intervention-client", icon: (<Iconify icon={"ic:outline-assignment"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Base de données",
        items: [
            { title: "Clients", path: "/bdd-clients", icon: (<Iconify icon={"material-symbols:home-work-outline-rounded"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Sites", path: "/bdd-sites", icon: (<Iconify icon={"carbon:store"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Intervenants", path: "/bdd-artisans", icon: (<Iconify icon={"mdi:account-hard-hat-outline"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />), },
            { title: "Mastore", path: "/bdd-utilisateurs", icon: (<Iconify icon={"heroicons-outline:user-group"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Evictum", path: "/evictum-users", icon: (<Iconify icon={"material-symbols:manage-accounts"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Stocks", path: "/stocks", icon: (<Iconify icon={"bx:bxs-box"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Modules",
        items: [
            { title: "Factures", path: "/facture", icon: (<Iconify icon={"fa6-solid:file-invoice-dollar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) }, 
            { title: "Devis", path: "/module-devis", icon: (<Iconify icon={"iconoir:page-edit"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Statistiques", path: "/statistique", icon: (<Iconify icon={"uim:analytics"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Performances", path: "/protected-admin-performance", icon: (<Iconify icon={"eos-icons:performance"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
         ],
    },
    {
        subheader: "",
        items: [
            { title: "Archives", path: "/archives", icon: (<Iconify icon={"bytesize:archive"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Paramêtres", path: "/parametres", icon: (<Iconify icon={"ant-design:setting-outlined"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Corbeille", path: "/corbeille", icon: (<Iconify icon={"wpf:full-trash"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
];

export const navConfigResponsable = [
    {
        subheader: "Intervention",
        items: [
            { title: "Interventions", path: "/", icon: (<Iconify icon={"la:tools"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },                   
            { title: "Préventives", path: "/tableau-preventive", icon: (<Iconify icon={"lucide:calendar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Demandes", path: "/demande-intervention-client", icon: (<Iconify icon={"ic:outline-assignment"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Base de données",
        items: [
            { title: "Clients", path: "/bdd-clients", icon: (<Iconify icon={"material-symbols:home-work-outline-rounded"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Sites", path: "/bdd-sites", icon: (<Iconify icon={"carbon:store"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Intervenants", path: "/bdd-artisans", icon: (<Iconify icon={"mdi:account-hard-hat-outline"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />), },
            { title: "Mastore", path: "/bdd-utilisateurs", icon: (<Iconify icon={"heroicons-outline:user-group"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Evictum", path: "/evictum-users", icon: (<Iconify icon={"material-symbols:manage-accounts"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Stocks", path: "/stocks", icon: (<Iconify icon={"bx:bxs-box"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Modules",
        items: [
            { title: "Factures", path: "/facture", icon: (<Iconify icon={"fa6-solid:file-invoice-dollar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },     
            { title: "Devis", path: "/module-devis", icon: (<Iconify icon={"iconoir:page-edit"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Statistiques", path: "/statistique", icon: (<Iconify icon={"uim:analytics"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            //{ title: "Performances", path: "/protected-admin-performance", icon: (<Iconify icon={"eos-icons:performance"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
         ],
    },
    {
        subheader: "",
        items: [
            { title: "Archives", path: "/archives", icon: (<Iconify icon={"bytesize:archive"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Paramêtres", path: "/parametres", icon: (<Iconify icon={"ant-design:setting-outlined"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Corbeille", path: "/corbeille", icon: (<Iconify icon={"wpf:full-trash"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
];


export const navConfigChargeAffaire = [
    {
        subheader: "Intervention",
        items: [
            { title: "Interventions", path: "/", icon: (<Iconify icon={"la:tools"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Préventives", path: "/tableau-preventive", icon: (<Iconify icon={"lucide:calendar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Demandes", path: "/demande-intervention-client", icon: (<Iconify icon={"ic:outline-assignment"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Base de données",
        items: [
            { title: "Clients", path: "/bdd-clients", icon: (<Iconify icon={"material-symbols:home-work-outline-rounded"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Sites", path: "/bdd-sites", icon: (<Iconify icon={"carbon:store"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Intervenants", path: "/bdd-artisans", icon: (<Iconify icon={"mdi:account-hard-hat-outline"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />), },
            { title: "Mastore", path: "/bdd-utilisateurs", icon: (<Iconify icon={"heroicons-outline:user-group"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Evictum", path: "/evictum-users", icon: (<Iconify icon={"material-symbols:manage-accounts"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Stocks", path: "/stocks", icon: (<Iconify icon={"bx:bxs-box"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Modules",
        items: [
            { title: "Factures", path: "/facture", icon: (<Iconify icon={"fa6-solid:file-invoice-dollar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },     
            // { title: "Devis", path: "/module-devis", icon: (<Iconify icon={"iconoir:page-edit"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Statistiques", path: "/statistique", icon: (<Iconify icon={"uim:analytics"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "",
        items: [
            { title: "Archives", path: "/archives", icon: (<Iconify icon={"bytesize:archive"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Corbeille", path: "/corbeille", icon: (<Iconify icon={"wpf:full-trash"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
];

export const navConfigChargeAffaireAdjoint = [
    {
        subheader: "Intervention",
        items: [
            { title: "Interventions", path: "/", icon: (<Iconify icon={"la:tools"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Préventives", path: "/tableau-preventive", icon: (<Iconify icon={"lucide:calendar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Demandes", path: "/demande-intervention-client", icon: (<Iconify icon={"ic:outline-assignment"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Base de données",
        items: [
            { title: "Clients", path: "/bdd-clients", icon: (<Iconify icon={"material-symbols:home-work-outline-rounded"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Sites", path: "/bdd-sites", icon: (<Iconify icon={"carbon:store"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Intervenants", path: "/bdd-artisans", icon: (<Iconify icon={"mdi:account-hard-hat-outline"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />), },
            { title: "Mastore", path: "/bdd-utilisateurs", icon: (<Iconify icon={"heroicons-outline:user-group"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Evictum", path: "/evictum-users", icon: (<Iconify icon={"material-symbols:manage-accounts"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Stocks", path: "/stocks", icon: (<Iconify icon={"bx:bxs-box"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Modules",
        items: [
            { title: "Factures", path: "/facture", icon: (<Iconify icon={"fa6-solid:file-invoice-dollar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) }, 
        ],
    },
    {
        subheader: "",
        items: [
            { title: "Archives", path: "/archives", icon: (<Iconify icon={"bytesize:archive"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Corbeille", path: "/corbeille", icon: (<Iconify icon={"wpf:full-trash"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
];

export const navConfigAssistant = [
    {
        subheader: "Intervention",
        items: [
            { title: "Interventions", path: "/", icon: (<Iconify icon={"la:tools"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Préventives", path: "/tableau-preventive", icon: (<Iconify icon={"lucide:calendar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Demandes", path: "/demande-intervention-client", icon: (<Iconify icon={"ic:outline-assignment"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Base de données",
        items: [
            { title: "Clients", path: "/bdd-clients", icon: (<Iconify icon={"material-symbols:home-work-outline-rounded"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Sites", path: "/bdd-sites", icon: (<Iconify icon={"carbon:store"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Intervenants", path: "/bdd-artisans", icon: (<Iconify icon={"mdi:account-hard-hat-outline"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />), },
            { title: "Stocks", path: "/stocks", icon: (<Iconify icon={"bx:bxs-box"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "Modules",
        items: [
            { title: "Factures", path: "/facture", icon: (<Iconify icon={"fa6-solid:file-invoice-dollar"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            // { title: "Devis", path: "/module-devis", icon: (<Iconify icon={"iconoir:page-edit"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
    {
        subheader: "",
        items: [
            { title: "Archives", path: "/archives", icon: (<Iconify icon={"bytesize:archive"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
            { title: "Corbeille", path: "/corbeille", icon: (<Iconify icon={"wpf:full-trash"} width={ICON.NAVBAR_ITEM} height={ICON.NAVBAR_ITEM} />) },
        ],
    },
];