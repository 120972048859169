import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Zoom,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ImageIcon from "@mui/icons-material/Image";
import SendIcon from "@mui/icons-material/Send";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import Status from "components/UI/Status";

const useStyles = makeStyles({
  Icon: {
    color: "#FF0000",
    cursor: "pointer",
  },
  IconEdit: {
    cursor: "pointer",
  },
  IconSend: {
    color: "#349034ed",
    cursor: "pointer",
  },
   IconDisabled: {
    color: "#B0B0B0", // Couleur grise pour les icônes désactivées
    cursor: "not-allowed",
  },
  PreviewStyle: {
    display: "inline",
    width: "200px",
    height: "200px",
    paddingBottom: 10,
    transition: "transform 2.2s",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  padding: "5px 10px",
}));

const StyledTableCell = styled(TableCell)(() => ({
  padding: "5px 10px",
  textAlign: "center",
}));

const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: "330px",
  minHeight: "70px",
  border: "1px solid rgba(26, 99, 182, 0.08)",
  borderRadius: "3px",
}));

const TruncateName = styled(Box)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical",
  textAlign: "left",
}));

export default function TableCommandeStock({
  data,
  modalSendMail,
  deleteCommandeStock,
  handleEditFicheCommande,
  user
}) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const classes = useStyles();
      let role = 4
    if (user.administrateur) {
        role = 0
    } else if (user.role === 'Comptable') {
        role = 1
    } else if (user.role === 'Responsable maintenance') {
        role = 2
    } else if (user.role === 'Chargé(e) d\'affaires') {
        if (user.entreprise === 'Mastore Ouest')
            role = 3
        else
            role = 2
    } else if (user.role === 'Assistant(e)') {
        role = 4
    } else {
        role = 4
    }

  const handleOpenConfirm = (row) => {
    setRowToDelete(row);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setRowToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (rowToDelete) {
      deleteCommandeStock(rowToDelete.id_fiche);
    }
    handleCloseConfirm();
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const showFile = (event, row) => {
    try {
      if (row.ficheCommandeStock.startsWith("http")) {
        window.open(row.ficheCommandeStock, "_blank");
      } else {
        const blob = b64toBlob(row.ficheCommandeStock, "application/pdf");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Erreur lors de l'ouverture du fichier:", error);
    }
  };

  const formatDate = (dateString) => {
  const defaultDate = "0001-01-01T00:09:21+00:09";
  
  if (dateString === defaultDate) {
    return "Date non définie";
  }
  
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
};


  return (
    <Box sx={{ width: "100%" }}>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="center" sx={{ width: "20px" }}>
              </StyledTableCellHeader>
              <StyledTableCellHeader align="left" sx={{ minWidth: "120px" }}>
                Nom du fichier
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" sx={{ width: "105px" }}>
                Intervenant
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" sx={{ width: "105px" }}>
                Date de récéption
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" sx={{ width: "105px" }}>
                Etat
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" sx={{ width: "75px" }}>
                Fiche
              </StyledTableCellHeader>
              <StyledTableCellHeader align="center" sx={{ width: "75px" }}>
                Envoyer
              </StyledTableCellHeader>
             <StyledTableCellHeader align="center" sx={{ width: "75px" }}>
                Infos
              </StyledTableCellHeader>
              
              <StyledTableCellHeader align="center" sx={{ width: "20px" }}>
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
                let SendTo = "Aucun envoi enregistré.";
                if (row.SendTo) {
                    SendTo = row.SendTo.map((item, i) => {
                        if (row.SendTo[i + 1])
                            return (<div key={item + i} style={{ marginBottom: "10px" }}>{item}</div>)
                        else
                            return (item);
                    });
                }
                return (
                    <TableRow key={row.id}>
                        <StyledTableCell>
                            {handleEditFicheCommande ? (
                                <Tooltip title="Modifier la fiche">
                                    <IconButton disabled={Boolean(role > 2 )} onClick={() => handleEditFicheCommande(row)}>
                                        <EditIcon color="primary" className={
                              role > 2
                                ? `${classes.IconEdit} ${classes.IconDisabled}`
                                : classes.IconEdit
                            } />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                        </StyledTableCell>
                        <StyledTableCell>
                            <TruncateName sx={{ width: "100%" }}>{row.name}</TruncateName>
                        </StyledTableCell>
                        <StyledTableCell>{row.contact}</StyledTableCell>
                        <StyledTableCell>{formatDate(row.date_reception)}</StyledTableCell>
                        <StyledTableCell>
                            <Status ownerState={row.etat} />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IconButton onClick={(event) => showFile(event, row)}>
                              <ImageIcon color="primary" />
                            </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>
                            {modalSendMail ? (
                                    <IconButton
                                        disabled={Boolean(role > 2 )}
                                        onClick={(event) => modalSendMail(event, row)}
                                    >
                                        <SendIcon className={
                              role > 2
                                ? `${classes.IconSend} ${classes.IconDisabled}`
                                : classes.IconSend
                            } />
                                    </IconButton>
                            ) : null}
                        </StyledTableCell>
                        <StyledTableCell>
                            <Tooltip TransitionComponent={Zoom} title={SendTo}>
                                <IconButton>
                                    <InfoOutlinedIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>
                                <IconButton disabled={Boolean(role > 2 )} onClick={() => handleOpenConfirm(row)}>
                                    <DeleteIcon className={
                              role > 2
                                ? `${classes.Icon} ${classes.IconDisabled}`
                                : classes.Icon
                            } />
                                </IconButton>
                        </StyledTableCell>
                    </TableRow>
                );
            })}          
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir supprimer cet élément?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirm} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
